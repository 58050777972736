import { all, fork, takeLatest, put, call } from 'redux-saga/effects';
import axios from 'axios';
import { GET_PROPERTY_MLS } from '../actions/types';
import { getMlsDataSuccess } from '../actions/Property';

// Loggers
import { log } from '../../utils/Loggers';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Get Property Data //////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const getPropertyDataRequest = async ({ mlsNumber }) => {
    const id = await axios({
        method: 'GET',
        url: 'https://realtor-com-real-estate.p.rapidapi.com/property-by-mls-id',
        params: { mls_id: mlsNumber },
        headers: {
            'x-rapidapi-key': '36da2b948amsh27bf9fea4838f83p109e9ejsn2f447bfbda31',
            'x-rapidapi-host': 'realtor-com-real-estate.p.rapidapi.com'
        }
    })
        .then(response => {
            const { count } = response.data.data;
            const property_id =
                count && count > 0 ? response.data.data.results[0].property_id : null;
            return property_id;
        })
        .catch(error => {
            return { error };
        });

    const propertyData = id
        ? await axios({
              method: 'GET',
              url: 'https://realtor-com-real-estate.p.rapidapi.com/property-detail',
              params: { property_id: id },
              headers: {
                  'x-rapidapi-key': '36da2b948amsh27bf9fea4838f83p109e9ejsn2f447bfbda31',
                  'x-rapidapi-host': 'realtor-com-real-estate.p.rapidapi.com'
              }
          }).then(property => {
              const { data } = property.data;
              return { propertyData: data };
          })
        : {};

    return { data: propertyData };
};

export function* getPropertyData({ payload }) {
    const { mlsNumber } = payload;
    const { data, error } = yield call(() => getPropertyDataRequest({ mlsNumber }));
    if (data) {
        const propertyData = Object.keys(data).length ? data.propertyData : {};
        yield put(getMlsDataSuccess(propertyData));
    } else {
        // Error Handling for sentry with put and maybe UI message
        log('Property Error: getting property data (API)', {
            error,
            mlsNumber
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* gettingPropertyData() {
    yield takeLatest(GET_PROPERTY_MLS, getPropertyData);
}

export default function* rootSaga() {
    yield all([fork(gettingPropertyData)]);
}
