import React from 'react';
import PropTypes from 'prop-types';

import { components } from 'react-select';
import { default as ReactSelect } from 'react-select';

export const Option = props => {
    return (
        <div>
            <components.Option {...props}>
                <div className="d-flex align-items-center">
                    <input
                        className="pointer ml-2"
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />
                    {''}
                    <label style={{ margin: '0', marginLeft: '10px', cursor: 'pointer' }}>
                        {props.label}
                    </label>
                </div>
            </components.Option>
        </div>
    );
};

export const MultiValue = props => (
    <components.MultiValue {...props}>
        <span>{props.data.label}</span>
    </components.MultiValue>
);

/**
 * Custom Value Container is created because we need separate values by commas and
 * also trim if content overflowed
 */
export const ValueContainer = ({ selectProps, children, ...props }) => {
    let [values, input] = children;

    if (Array.isArray(values)) {
        values = selectProps.value.map(value => value.label).join(', ');
    }

    return (
        <components.ValueContainer {...props}>
            <div
                style={{
                    maxWidth: '90%',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                }}
            >
                {values}
            </div>
            {input}
        </components.ValueContainer>
    );
};

const MultiSelect = props => {
    const customStyles = {
        container: () => ({
            width: '100%'
        }),
        placeholder: base => ({
            ...base,
            margin: '0',
            color: '#8c98a4'
        }),
        input: base => ({
            ...base,
            transform: 'translateX(-10px)'
        }),
        valueContainer: base => ({
            ...base,
            width: '100px',
            paddingLeft: '0',
            gap: '10px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            flexWrap: 'nowrap',
            div: {
                paddingLeft: '0'
            },
            span: {
                paddingLeft: '0',
                fontSize: '16px',
                color: '#505050'
            }
        }),
        dropdownIndicator: () => ({
            display: props.outline ? 'block' : 'none'
        }),
        clearIndicator: () => ({
            display: 'none'
        }),
        indicatorSeparator: () => ({
            display: 'none'
        }),
        menu: () => ({
            width: '400px',
            height: 'fit-content',
            maxHeight: '250px',
            backgroundColor: '#fff',
            color: '#8c98a4',
            marginTop: '5px',
            position: 'absolute',
            boxShadow: '0px 5px 9px 2px rgba(0, 0, 0, 0.11)',
            borderRadius: '5px',
            zIndex: '10'
        }),
        option: () => ({
            backgroundColor: '#fff',
            cursor: 'pointer',
            padding: '5px',
            '&:hover': {
                backgroundColor: '#eee'
            }
        }),
        multiValue: () => ({}),
        multiValueRemove: () => ({
            display: 'none'
        }),
        control: () => ({
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            border: props.outline ? '1px solid #eee' : 'none',
            padding: props.outline ? '5px 10px' : '0',
            borderRadius: '5px',
            outline: 'none'
        })
    };

    if (props.allowSelectAll) {
        return (
            <ReactSelect
                {...props}
                styles={customStyles}
                options={props.options}
                onChange={selected => {
                    if (
                        selected !== null &&
                        selected.length > 0 &&
                        selected[selected.length - 1].value === props.allOption.value
                    ) {
                        return props.onChange(props.options);
                    }
                    return props.onChange(selected);
                }}
            />
        );
    }

    return <ReactSelect {...props} />;
};

MultiSelect.propTypes = {
    outline: PropTypes.bool,
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
    allowSelectAll: PropTypes.bool,
    allOption: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    })
};

/**
 * In Future we may need to add option to Select All
 **/
MultiSelect.defaultProps = {
    allOption: {
        label: 'Select all',
        value: '*'
    }
};

export default MultiSelect;
