import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import Office from './Office';
import StatusDropdown from './StatusDropdown';

const OfficesList = ({ lang, orgs, activeOrgId }) => {
    const [organization, setOrganization] = useState(null);
    const [location, setLocation] = useState(null);

    const { office, officeStatuses, officeTypes, name_label, status_label, type_label } =
        lang;

    const statusesItems = [
        {
            name: officeStatuses?.active_label
        },
        {
            name: officeStatuses?.inactive_label
        }
    ];

    const typesItems = [
        {
            name: officeTypes?.main_label
        },
        {
            name: officeTypes?.branch_label
        },
        {
            name: officeTypes?.satellite_label
        }
    ];

    const handleOfficeChange = (organization, office) => {
        setOrganization(organization);
        setLocation(office);
    };

    return (
        <>
            {organization && location ? (
                <Office
                    handleOfficeChange={handleOfficeChange}
                    organization={organization}
                    location={location}
                    officeLang={office}
                />
            ) : (
                <div className="container">
                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-5">
                            <h3 className="h5 font-weight-medium">{name_label}</h3>
                        </div>
                        <div className="col-3 pl-0">
                            <h3 className="h5 font-weight-medium">{status_label}</h3>
                        </div>
                        <div className="col-4 pl-0">
                            <h3 className="h5 font-weight-medium">{type_label}</h3>
                        </div>
                    </div>
                    {orgs?.map(org => (
                        <React.Fragment key={org.id}>
                            {Object.values(org?.locations)?.map(location => (
                                <div
                                    key={location.id}
                                    className="row d-flex align-items-center w-100 mb-4"
                                >
                                    <div className="col-5">
                                        <h6
                                            className="h6 font-weight-medium mb-0 pointer"
                                            onClick={() =>
                                                handleOfficeChange(org, location)
                                            }
                                        >
                                            {location?.office_name}
                                        </h6>
                                        <p className="small mb-0">
                                            {location?.address_1}
                                        </p>
                                    </div>
                                    <div className="col-3">
                                        <Formik
                                            initialValues={{
                                                officeStatus:
                                                    org?.id === activeOrgId
                                                        ? statusesItems[0].name
                                                        : statusesItems[1].name
                                            }}
                                        >
                                            {({ setFieldValue }) => (
                                                <Form>
                                                    <Field
                                                        name="officeStatus"
                                                        component={StatusDropdown}
                                                        options={statusesItems}
                                                        onChange={status =>
                                                            setFieldValue(
                                                                'officeStatus',
                                                                status
                                                            )
                                                        }
                                                        statuses
                                                    />
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                    <div className="col-4 d-flex align-items-center">
                                        <Formik
                                            initialValues={{
                                                officeType: location?.office_type
                                            }}
                                        >
                                            {({ setFieldValue }) => (
                                                <Form className="w-75">
                                                    <Field
                                                        name="officeType"
                                                        component={StatusDropdown}
                                                        options={typesItems}
                                                        onChange={type =>
                                                            setFieldValue(
                                                                'officeType',
                                                                type
                                                            )
                                                        }
                                                    />
                                                </Form>
                                            )}
                                        </Formik>
                                        <FontAwesomeIcon
                                            className="pointer mb-0 ml-5"
                                            icon={faTrash}
                                            color="#8c98a4"
                                        />
                                    </div>
                                </div>
                            ))}
                        </React.Fragment>
                    ))}
                </div>
            )}
        </>
    );
};

export default OfficesList;
