// React
import React, { useEffect, useCallback, useState } from 'react';

// Components
import { DetailsHeader, Loader } from '../components';
import ConfirmationDialog from '../components/common/ConfirmationDialog';
import ActionButtons from '../components/Settings/shared/ActionButtons';

// Packages
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';

// Redux - Actions, Reducers, Sagas
import { gettingTransactionDetails } from '../store/actions/Transactions';
import { setConfirmModalType } from '../store/actions/Modal';

// Constants
import { confirmationDialogTypes, headerTypes } from '../utils/Constants';
import * as routes from '../router/config/routes';

// Context
import { useLang } from '../context/LangContext';

const EditTransaction = ({
    location,
    transactionDetails,
    gettingTransactionDetails,
    userData,
    setConfirmModalType
}) => {
    const history = useHistory();
    const { save_label, cancel_label } = useLang()['Common']['ActionButtons'];
    const { title_cancel, content_cancel, stay_button_cancel, confirm_button_cancel } =
        useLang()['Common']['ConfirmationDialog'];
    const transaction = transactionDetails;
    const [shouldConfirmDisplayed, setShouldConfirmDisplayed] = useState(true);

    const getTrxId = useCallback(() => {
        const pathname = window.location.pathname;
        if (location.state?.id) {
            if (!transactionDetails) {
                gettingTransactionDetails({
                    trxId: location.state.id,
                    detailsFlag: false
                });
            }
            return location.state.id;
        } else {
            const id = pathname.substr(pathname.lastIndexOf('/') + 1);
            gettingTransactionDetails({ trxId: id, detailsFlag: false });
            return id;
        }
    }, [transactionDetails, gettingTransactionDetails, location]);

    useEffect(() => {
        getTrxId();
    }, [getTrxId]);

    const cancelButtonClickHandler = () => {
        shouldConfirmDisplayed
            ? setConfirmModalType(confirmationDialogTypes.cancel)
            : history.push({
                  pathname: `${routes.AUTHENTICATED}${routes.TRANSACTION_DETAIL}/${transaction.id}`,
                  state: { id: transaction.id }
              });
    };

    if (!transaction) return <Loader />;
    return (
        <>
            <ConfirmationDialog
                confirmHandler={() =>
                    history.push({
                        pathname: `${routes.AUTHENTICATED}${routes.TRANSACTION_DETAIL}/${transaction.id}`,
                        state: { id: transaction.id }
                    })
                }
                titleText={title_cancel}
                contentText={content_cancel}
                confirmButtonText={confirm_button_cancel}
                cancelButtonText={stay_button_cancel}
            />
            <DetailsHeader
                transaction={transaction}
                userData={userData}
                headerType={headerTypes.editTransaction}
                cancelButtonClickHandler={cancelButtonClickHandler}
            />
            <main id="content" role="main" className="push-main">
                <div className="bg-light">
                    <div id="main" className="size container pt-3 pt-xl-4">
                        <div className="row no-gutters">
                            <div
                                className="card position-relative"
                                style={{
                                    minHeight: '70vh',
                                    height: '100%',
                                    marginBottom: '50px',
                                    width: '100%'
                                }}
                            >
                                <ActionButtons
                                    handleSubmit={() => setShouldConfirmDisplayed(false)}
                                    handleClose={cancelButtonClickHandler}
                                    cancelLabel={cancel_label}
                                    saveLabel={save_label}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

const mapStateToProps = ({ transactions, user }) => {
    const { userData } = user;
    const { transactionDetails } = transactions;
    return {
        transactionDetails,
        userData
    };
};

export default withRouter(
    connect(mapStateToProps, {
        gettingTransactionDetails,
        setConfirmModalType
    })(EditTransaction)
);
