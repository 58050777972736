// React
import React, { useRef, useEffect } from 'react';

// Packages
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Redux - Actions, Reducers, Sagas
import { togglingSideNav } from '../../store/actions/App';

// Router
import { withRouter } from 'react-router-dom';

const useClickEffect = ({ wrapperRef, sideNav, togglingSideNav }) => {
    useEffect(() => {
        const handleClickOutside = event => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                if (sideNav) {
                    togglingSideNav({ disableButton: true });
                }
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef, sideNav, togglingSideNav]);
};

/**
 * Component Wrapper that wraps the Side Nav and detects clicks outside of the Nav component and will toggle the Nav to a closed position if user happens to click outside of the open Nav
 */

const ClickWrapper = props => {
    const { sideNav, togglingSideNav, children } = props;
    const wrapperRef = useRef(null);
    useClickEffect({ wrapperRef, sideNav, togglingSideNav });

    return <div ref={wrapperRef}>{children}</div>;
};

ClickWrapper.propTypes = {
    children: PropTypes.element.isRequired
};

const mapStateToProps = ({ app }) => {
    const { sideNav } = app;
    return { sideNav };
};

export default withRouter(connect(mapStateToProps, { togglingSideNav })(ClickWrapper));
