// User
export const _userData = state => state.user.userData;
export const _activeTrxList = state => state.user.userData.transactions;

// Org
export const _activeOrg = state => state.org.activeOrg;

// Transactions
export const _activeTransactions = state => state.transactions.userTransactions;
export const _closedTransactions = state => state.transactions.closedTransactions;
export const _archivedTransactions = state => state.transactions.archivedTransactions;
