import { all, fork, takeLatest, take, cancelled, race, put } from 'redux-saga/effects';

import { GET_USER_COLLECTION, LOGOUT_USER } from '../actions/types';

import { eventChannel } from 'redux-saga';

import { db } from '../../config/Firebase';

import { transactionCollectionWatch } from './Transactions';
import { orgCollectionWatch } from './Org';
import { docCollectionWatch } from './Documents';
import { contactsCollectionWatch } from './Contacts';

import { storeUserCollection } from '../actions/User';

// Loggers
import { log } from '../../utils/Loggers';

const users = db.collection('users');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Get User Profile /////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* userCollectionWatch(user) {
    let unsubcscribeUserCollectionData;
    const userCollectionChannel = eventChannel(emit => {
        unsubcscribeUserCollectionData = users
            .doc(`${user.uid}`)
            .onSnapshot(function (doc) {
                if (doc) {
                    emit(doc.data());
                } else {
                    doc = { exists: false };
                    emit({ doc });
                }
            });
        return unsubcscribeUserCollectionData;
    });
    try {
        while (true) {
            const { userSignOut, userCollectionData } = yield race({
                userSignOut: take(LOGOUT_USER),
                userCollectionData: take(userCollectionChannel)
            });

            if (userSignOut) {
                userCollectionChannel.close(); // Detach saga event emitter
            } else {
                yield put(storeUserCollection(userCollectionData));
                yield fork(transactionCollectionWatch, userCollectionData);
                if (userCollectionData.type !== 'client') {
                    yield fork(orgCollectionWatch, userCollectionData);
                    yield fork(docCollectionWatch, userCollectionData);
                    yield fork(contactsCollectionWatch, userCollectionData);
                }
            }
        }
    } catch (error) {
        log('User Error: getting user collection data (FS)', {
            error,
            user
        });
    } finally {
        unsubcscribeUserCollectionData(); // Detach firebase listener
        if (yield cancelled()) {
            userCollectionChannel.close(); // Detach saga event emitter
            unsubcscribeUserCollectionData(); // Detach firebase listener
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* getUserCollection() {
    yield takeLatest(GET_USER_COLLECTION, userCollectionWatch);
}

export default function* rootSaga() {
    yield all([fork(getUserCollection)]);
}
