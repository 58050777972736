import {
    CHECK_USER_AUTHENTICATED,
    USER_AUTHENTICATED_SUCCESS,
    USER_AUTHENTICATED_FAILURE,
    LOGIN_USER_EMAIL_PASSWORD,
    LOGIN_USER_FAILURE,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    CLEAR_AUTH_ERROR,
    NON_AUTH_RESET_USER_PASSWORD,
    NON_AUTH_RESET_USER_PASSWORD_SUCCESS,
    NON_AUTH_RESET_USER_PASSWORD_FAILURE,
    RESET_PASSWORD_REQUEST_STATE,
    EXECUTE_RESET_PASSWORD_FLIGHT,
    VERIFY_ACTION_CODE,
    ACTION_CODE_VALID,
    ACTION_CODE_INVALID,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE
} from './types';

export const checkUserAuth = () => {
    return {
        type: CHECK_USER_AUTHENTICATED
    };
};

export const userIsAuthenticated = authUser => {
    return {
        type: USER_AUTHENTICATED_SUCCESS,
        payload: authUser
    };
};

export const userNotAuthenticated = () => {
    return {
        type: USER_AUTHENTICATED_FAILURE
    };
};

export const loginUserEmailPassword = userCreds => {
    return {
        type: LOGIN_USER_EMAIL_PASSWORD,
        payload: userCreds
    };
};

export const loginUserFailure = payload => {
    return {
        type: LOGIN_USER_FAILURE,
        payload: payload
    };
};

export const logoutUser = () => {
    return {
        type: LOGOUT_USER
    };
};

export const logoutUserSuccess = () => {
    return {
        type: LOGOUT_USER_SUCCESS
    };
};

export const clearAuthError = () => {
    return {
        type: CLEAR_AUTH_ERROR
    };
};

export const sendResetPasswordLink = email => {
    return {
        type: NON_AUTH_RESET_USER_PASSWORD,
        payload: email
    };
};

export const sendResetPasswordLinkSuccess = () => {
    return {
        type: NON_AUTH_RESET_USER_PASSWORD_SUCCESS
    };
};

export const sendResetPasswordLinkFailure = () => {
    return {
        type: NON_AUTH_RESET_USER_PASSWORD_FAILURE
    };
};

export const resettingPasswordRequestState = () => {
    return {
        type: RESET_PASSWORD_REQUEST_STATE
    };
};

export const executeResetPasswordFlight = password => {
    return {
        type: EXECUTE_RESET_PASSWORD_FLIGHT,
        payload: password
    };
};

export const validateActionCode = code => {
    return {
        type: VERIFY_ACTION_CODE,
        payload: code
    };
};

export const actionCodeValidationSuccess = () => {
    return {
        type: ACTION_CODE_VALID
    };
};

export const actionCodeValidationFailure = () => {
    return {
        type: ACTION_CODE_INVALID
    };
};

export const resetPasswordSuccess = () => {
    return {
        type: RESET_PASSWORD_SUCCESS
    };
};

export const resetPasswordFailure = () => {
    return {
        type: RESET_PASSWORD_FAILURE
    };
};
