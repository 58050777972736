// React
import React, { useEffect, useState } from 'react';

// Assets
import NewTransactionLogo from '../../assets/svg/NewTransactionLogo.svg';
import NewListing from '../../assets/svg/NewListing.svg';
import NewBuyer from '../../assets/svg/NewBuyer.svg';

// Components
import { ListingSelectForm, BuyerSelectForm } from '../../components';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import SimpleBar from 'simplebar-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Redux - Actions, Reducers, Sagas
import { resetPropertyData } from '../../store/actions/Property';
import { cancelDetailsListener } from '../../store/actions/Transactions';

const TransactionTypeSelect = props => {
    const [transactionType, setTransactionType] = useState(null);
    const { resetPropertyData, cancelDetailsListener, setShouldConfirmDisplayed } = props;
    const { title_label, listing_button_label, buyer_button_label } =
        useLang()['NewTransaction']['TransactionTypeSelect'];

    useEffect(() => {
        setShouldConfirmDisplayed(false);
    }, [setShouldConfirmDisplayed]);

    const transactionTypeHandler = type => {
        setTransactionType(type);
        resetPropertyData();
        cancelDetailsListener();
    };

    return (
        <main id="content" role="main" className="push-main">
            <SimpleBar style={{ height: '100vh', width: '100vw' }}>
                <div className="container space-1 mb-8">
                    <div className="w-lg-75 mx-lg-auto mt-0">
                        <div className="text-center mb-3">
                            <figure
                                id="embedIcon"
                                className="svg-preloader center-icon mx-auto"
                            >
                                <img
                                    className="js-svg-injector"
                                    src={NewTransactionLogo}
                                    alt="SVG"
                                    data-parent="#embedIcon"
                                />
                            </figure>
                            <h1 className="h2 font-weight-medium">{title_label}</h1>
                        </div>
                        <form id="uploadForm" className="js-validate svg-preloader">
                            <div className="mb-4">
                                <div className="row mx-gutters-2 justify-content-center">
                                    <div className="col-6 col-md-3">
                                        <div className="custom-control custom-radio custom-control-inline checkbox-outline text-center">
                                            <input
                                                type="radio"
                                                id="typeOfListingRadio1"
                                                name="typeOfListingRadio1"
                                                className="custom-control-input checkbox-outline__input"
                                                onClick={() =>
                                                    transactionTypeHandler('listing')
                                                }
                                            />
                                            <label
                                                className="checkbox-outline__label rounded py-3 px-1 mb-0"
                                                htmlFor="typeOfListingRadio1"
                                            >
                                                <img
                                                    className="js-svg-injector w-50 mb-3"
                                                    src={NewListing}
                                                    alt="SVG"
                                                    data-parent="#uploadForm"
                                                />
                                                <span className="d-block">
                                                    {listing_button_label}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-3">
                                        <div className="custom-control custom-radio custom-control-inline checkbox-outline text-center">
                                            <input
                                                type="radio"
                                                id="typeOfListingRadio2"
                                                name="typeOfListingRadio1"
                                                className="custom-control-input checkbox-outline__input"
                                                onClick={() =>
                                                    transactionTypeHandler('buyer')
                                                }
                                            />
                                            <label
                                                className="checkbox-outline__label rounded py-3 px-1 mb-0"
                                                htmlFor="typeOfListingRadio2"
                                            >
                                                <img
                                                    className="js-svg-injector w-50 mb-3"
                                                    src={NewBuyer}
                                                    alt="SVG"
                                                    data-parent="#uploadForm"
                                                />
                                                <span className="d-block">
                                                    {buyer_button_label}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {transactionType && transactionType === 'listing' && (
                                <ListingSelectForm {...props} />
                            )}
                            {transactionType && transactionType === 'buyer' && (
                                <BuyerSelectForm {...props} />
                            )}
                        </form>
                    </div>
                </div>
            </SimpleBar>
        </main>
    );
};

export default withRouter(
    connect(null, { resetPropertyData, cancelDetailsListener })(TransactionTypeSelect)
);
