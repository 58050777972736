// React
import React, { useState, useEffect } from 'react';

// Components
import { ControllerHeader, ViewController } from '../components';

// Hooks

// Packages
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const PdfView = ({ location, userDocuments, orgDocuments }) => {
    const [allDocs, setAllDocs] = useState();

    useEffect(() => {
        if (userDocuments.length || orgDocuments.length) {
            const docs = [].concat(userDocuments, orgDocuments);
            setAllDocs(docs);
        }
    }, [userDocuments, orgDocuments]);

    const docData = () => {
        if (allDocs && allDocs.length && location?.state?.id) {
            const getDoc = allDocs.filter(doc => doc.id === location.state.id);
            return getDoc[0];
        }
        return null;
    };

    if (!docData()) return null;

    return (
        <>
            <ControllerHeader document={docData()} />
            <main id="content" role="main" className="push-main">
                <div className="bg-light">
                    <div id="main" className="size container pt-3 pt-xl-4">
                        <ViewController document={docData()} />
                    </div>
                </div>
            </main>
        </>
    );
};

const mapStateToProps = ({ documents }) => {
    const { userDocuments, orgDocuments } = documents;
    return { userDocuments, orgDocuments };
};

export default withRouter(connect(mapStateToProps, {})(PdfView));
