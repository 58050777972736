import React from 'react';

const SecuritySettings = () => {
    return (
        <>
            <div className="card-header">
                <h1 className="h5 font-weight-medium mb-0">Security Settings</h1>
            </div>
            <div className="card-body">
                <p>
                    “The advance of technology is based on making it fit in so that you
                    don&rsquo;t really even notice it, so it&rsquo;s part of everyday
                    life.”
                </p>
            </div>
        </>
    );
};

export default SecuritySettings;
