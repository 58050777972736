// React
import React, { useEffect } from 'react';

// Assets
import logo from '../assets/img/jada-logo-v1.svg';

// Components
import { TextField, Loader } from '../components';

// Packages
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Container, Button, Label, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Redux - Actions, Reducers, Sagas
import {
    sendResetPasswordLink,
    resettingPasswordRequestState
} from '../store/actions/Auth';

// Router
import * as routes from '../router/config/routes';

// Utils
// import { errorMessage, passwordRegExp } from '../utils/Constants';
import { errorMessage } from '../utils/Constants';

const ResetPassword = ({
    sendResetPasswordLink,
    loading,
    resetPasswordComplete,
    resettingPasswordRequestState
}) => {
    useEffect(() => {
        return () => {
            resettingPasswordRequestState();
        };
    }, [resettingPasswordRequestState]);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .lowercase()
            .email(errorMessage.email.valid)
            .required(errorMessage.email.required)
    });
    return (
        <Container className="space-2">
            {!resetPasswordComplete ? (
                <Col>
                    <Link
                        className="d-flex align-items-center justify-content-center position-relative mt-11 mb-8"
                        to="/"
                        aria-label="Jada"
                    >
                        <img
                            src={logo}
                            className="logo"
                            alt="Jada Real Estate Real Simple"
                        />
                    </Link>
                    <Formik
                        initialValues={{
                            email: '',
                            password: ''
                        }}
                        onSubmit={({ email }) => {
                            sendResetPasswordLink({ email });
                        }}
                        validationSchema={validationSchema}
                        validateOnChange
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            touched,
                            values
                        }) => (
                            <Form className="w-md-50 mx-md-auto">
                                <div className="mb-7">
                                    <h2 className="h3 text-primary font-weight-normal mb-0">
                                        Reset Password
                                    </h2>
                                    <p>
                                        Send a reset link to your email address you used
                                        to set up your Jada account
                                    </p>
                                </div>
                                <div className="js-form-message form-group">
                                    <Label className="form-label" for="email">
                                        Email Address
                                    </Label>
                                    <TextField
                                        required
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        id="email"
                                        placeholder="Email address"
                                        aria-label="Email address"
                                        error={errors.email}
                                        value={values.email}
                                        onBlur={handleBlur('email')}
                                        onChange={handleChange('email')}
                                        invalid={touched.email && !!errors.email}
                                    />
                                </div>
                                <div className="row align-items-center mb-5">
                                    <div className="col-8">
                                        <span className="small text-muted">
                                            Remember your password?
                                        </span>
                                        <Link className="small" to={`${routes.LOGIN}`}>
                                            {' '}
                                            Login here
                                        </Link>
                                    </div>
                                    <div className="col-8">
                                        <span className="small text-muted">
                                            Don&apos;t have an account?
                                        </span>
                                        <Link className="small" to="/">
                                            {' '}
                                            Learn more
                                        </Link>
                                    </div>
                                    <div className="col-4 text-right">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="transition-3d-hover"
                                            onClick={handleSubmit}
                                        >
                                            Send link
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Col>
            ) : (
                <Col>
                    <div
                        className="d-flex align-items-center justify-content-center position-relative mt-11 mb-8"
                        aria-label="Jada"
                    >
                        <img
                            src={logo}
                            className="logo"
                            alt="Jada Real Estate Real Simple"
                        />
                    </div>
                    <div className="mb-7">
                        <h2 className="h3 text-primary font-weight-normal mb-0">
                            Request Complete
                        </h2>
                        <p>
                            If you have a Jada Account, an email has been sent with a
                            reset link to complete your reset password request
                        </p>
                        <Link className="small" to={`${routes.LOGIN}`}>
                            <Button color="primary" className="transition-3d-hover">
                                Finished
                            </Button>
                        </Link>
                    </div>
                </Col>
            )}
            {loading && <Loader />}
        </Container>
    );
};

const mapStateToProps = ({ auth }) => {
    const { loading, resetPasswordComplete } = auth;
    return { loading, resetPasswordComplete };
};

export default withRouter(
    connect(mapStateToProps, { sendResetPasswordLink, resettingPasswordRequestState })(
        ResetPassword
    )
);
