import { GET_TRANSACTION_DETAILS, SET_TRANSACTION_ACTIVITY } from './types';

export const gettingTransactionActivity = data => {
    return {
        type: GET_TRANSACTION_DETAILS,
        payload: data
    };
};

export const settingTransactionActivity = data => {
    return {
        type: SET_TRANSACTION_ACTIVITY,
        payload: data
    };
};
