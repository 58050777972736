import { TOGGLE_SIDE_NAV, ENABLING_NAV_BUTTON } from '../actions/types';

export const togglingSideNav = toggle => {
    if (toggle?.disableButton) {
        return {
            type: TOGGLE_SIDE_NAV,
            payload: toggle.disableButton
        };
    } else {
        return {
            type: TOGGLE_SIDE_NAV,
            payload: false
        };
    }
};

export const enableNavButton = () => {
    return {
        type: ENABLING_NAV_BUTTON
    };
};
