import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { ResetPassword } from '../components';
import * as routes from '../router/config/routes';

const AuthenticationServices = () => {
    const [mode, setMode] = useState(null);
    const [oobCode, setOobCode] = useState(null);
    const [email, setEmail] = useState(null);
    const [uid, setUid] = useState(null);
    const [mount, setMount] = useState(false);
    const history = useHistory();
    const location = useLocation();

    const useQuery = () => {
        return new URLSearchParams(location.search);
    };
    const getInternalUrlParams = url => {
        return new URLSearchParams(url);
    };

    const query = useQuery();
    const internalUrl = getInternalUrlParams(query.get('continueUrl'));

    useEffect(() => {
        setMode(query.get('mode'));
        setOobCode(query.get('oobCode'));
        setEmail(internalUrl.get('userEmail'));
        setUid(internalUrl.get('uid'));
        setMount(true);
    }, [query, internalUrl]);

    useEffect(() => {
        if (mount && !oobCode) {
            history.replace({
                pathname: `${routes.LANDING}`
            });
        }
    }, [mount, oobCode, history]);

    return (
        <>
            {mode === 'resetPassword' && (
                <ResetPassword mode={mode} oobCode={oobCode} email={email} uid={uid} />
            )}
        </>
    );
};

export default AuthenticationServices;
