// React
import React from 'react';

// Components
import { ActivityInput, ActivityThread } from '../index';

const Activity = ({
    activity,
    avatars,
    tasks,
    trxId,
    lang,
    team,
    isTransactionArchived
}) => {
    return (
        <div
            style={{
                animation: 'fadeIn 1s',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1
            }}
        >
            {!isTransactionArchived && (
                <ActivityInput
                    tasks={tasks}
                    trxId={trxId}
                    activity={activity}
                    team={team}
                />
            )}
            <ActivityThread
                activity={activity}
                avatars={avatars}
                lang={lang}
                isTransactionArchived={isTransactionArchived}
            />
        </div>
    );
};

export default Activity;
