import { SET_USER_ORG } from '../actions/types';

const INITIAL_STATE = {
    orgs: null,
    activeOrg: null
};

const filteredUserOrg = ({ orgs, active_org_id }) => {
    return orgs.filter(org => org.id === active_org_id)[0];
};

const orgReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER_ORG:
            return {
                ...state,
                activeOrg: filteredUserOrg(action.payload),
                orgs: action.payload.orgs
            };
        default:
            return state;
    }
};

export default orgReducer;
