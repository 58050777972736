// React
import React from 'react';
import { withRouter, Link, useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import { removeContact } from '../store/actions/Contacts';

// Packages
import Avatar from 'react-avatar';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faHome, faLink } from '@fortawesome/free-solid-svg-icons';

// Context
import { useLang } from '../context/LangContext';

// Router
import * as routes from '../router/config/routes';

// Components
import { ContactDetailsHistory, DetailsHeader } from '../components';
import DropdownMenuComponent from '../components/common/DropdownMenu';
import ConfirmationDialog from '../components/common/ConfirmationDialog';

// Consts
import { headerTypes, userTypes } from '../utils/Constants';

const ContactDetails = ({
    userContacts,
    orgContacts,
    location,
    removeContact,
    userData
}) => {
    const history = useHistory();
    const getContactId = () => {
        const pathname = window.location.pathname;
        if (location.state?.id) return location.state.id;
        return pathname.substr(pathname.lastIndexOf('/') + 1);
    };
    const id = getContactId();
    const avatar = location.state?.imgUrl;
    const contacts = [...userContacts, ...orgContacts];
    const contact =
        contacts &&
        contacts.find(contact => {
            return contact?.id?.toString() === id;
        });
    const isInternalContact = contact && userContacts.some(el => el.id === contact.id);

    const { notes_heading, history_heading, status, button_label } =
        useLang()['ContactDetails'];
    const { delete_label, edit_label } = useLang()['Common']['Dropdown'];
    const {
        title_question,
        content_delete,
        success_title_delete,
        success_content_delete,
        failed_title,
        failed_content,
        confirm_button_delete,
        cancel_button,
        loading_title,
        loading_content
    } = useLang()['Common']['ConfirmationDialog'];

    const dropItems = [
        {
            name: delete_label,
            icon: 'fas fa-archive dropdown-item-icon',
            onClickHandler: () => removeContact({ userData, contact, isInternalContact })
        },
        {
            name: edit_label,
            icon: 'fas fa-edit dropdown-item-icon',
            onClickHandler: () => {}
        }
    ];

    return (
        <>
            <ConfirmationDialog
                titleText={title_question}
                contentText={content_delete}
                confirmButtonText={confirm_button_delete}
                cancelButtonText={cancel_button}
                loadingTitleText={loading_title}
                loadingContentText={loading_content}
                successTitleText={success_title_delete}
                successContentText={success_content_delete}
                failedTitleText={failed_title}
                failedContentText={failed_content}
                successHandler={() =>
                    history.push(
                        `${routes.AUTHENTICATED}${routes.DASHBOARD}${routes.CONTACTS}`
                    )
                }
            />
            {contact && (
                <>
                    <DetailsHeader
                        contact={contact}
                        headerType={headerTypes.contactDetails}
                    />
                    <main id="content" role="main" className="push-main">
                        <div className="bg-light">
                            <div
                                id="main"
                                className="contact-frame size container mt-5 p-5 pt-3 pt-xl-4"
                            >
                                {(userData?.type === userTypes.owner ||
                                    isInternalContact) && (
                                    <div className="row justify-content-end pt-1">
                                        <DropdownMenuComponent dropItems={dropItems} />
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-2 contact-frame__avatars">
                                        <div className="row d-flex justify-content-center">
                                            {avatar ? (
                                                <img
                                                    src={avatar}
                                                    className="contact-frame__avatar"
                                                    alt={`${contact?.first_name} ${contact?.last_name}`}
                                                />
                                            ) : (
                                                <Avatar
                                                    className="btn btn-rel btn-icon btn-soft-primary contact-frame__avatar rounded-circle"
                                                    name={`${contact.first_name} ${contact.last_name}`}
                                                    unstyled
                                                />
                                            )}
                                        </div>
                                        {contact.connection && (
                                            <div className="row d-flex justify-content-center mt-3 mb-3">
                                                <FontAwesomeIcon
                                                    icon={faLink}
                                                    color="rgb(140, 152, 164)"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-10 mt-2 contact__details">
                                        <h2 className="contact-frame__fullname mb-3">
                                            {contact.first_name} {contact.last_name}
                                        </h2>
                                        <div className="row d-flex align-items-start ml-0 mt-2">
                                            <div className="col">
                                                {contact.phone && (
                                                    <p className="row contact__details-row">
                                                        <FontAwesomeIcon
                                                            className="contact__details-icon"
                                                            icon={faPhone}
                                                            color="rgb(140, 152, 164)"
                                                        />
                                                        <a href={`tel:${contact.phone}`}>
                                                            <NumberFormat
                                                                value={contact.phone}
                                                                displayType={'text'}
                                                                format="###.###.####"
                                                                mask="_"
                                                            />
                                                        </a>
                                                    </p>
                                                )}
                                                {contact.email && (
                                                    <p className="row contact__details-row">
                                                        <FontAwesomeIcon
                                                            className="contact__details-icon"
                                                            icon={faEnvelope}
                                                            color="rgb(140, 152, 164)"
                                                        />
                                                        <a
                                                            href={`mailto:${contact.email}`}
                                                        >
                                                            {contact.email}
                                                        </a>
                                                    </p>
                                                )}
                                            </div>
                                            {contact?.address && (
                                                <div className="col">
                                                    <div className="row mr-8 contact__details-row">
                                                        <FontAwesomeIcon
                                                            className="contact__details-icon"
                                                            icon={faHome}
                                                            color="rgb(140, 152, 164)"
                                                        />
                                                        {contact?.address?.lat &&
                                                        contact?.address?.lon ? (
                                                            <a
                                                                href={`https://google.com/maps/search/?api=1&query=${contact.address.lat},${contact.address.lon}`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                {
                                                                    contact.address
                                                                        .address_1
                                                                }
                                                                <br />
                                                                {
                                                                    contact.address.city
                                                                }, {contact.address.state}{' '}
                                                                {contact.address.zip}
                                                            </a>
                                                        ) : (
                                                            <span className="small text-muted">
                                                                {
                                                                    contact?.address
                                                                        ?.address_1
                                                                }
                                                                <br />
                                                                {
                                                                    contact?.address?.city
                                                                },{' '}
                                                                {contact?.address?.state}{' '}
                                                                {contact?.address?.zip}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {contact.connection && (
                                    <div className="row d-flex align-items-center contact-frame__relative">
                                        <img
                                            src={avatar}
                                            className="contact-frame__avatar--small"
                                            alt={`${contact.connection.first_name} ${contact.connection.last_name}`}
                                        />
                                        <div className="contact-frame__relative-fullname">
                                            {contact.connection.first_name}{' '}
                                            {contact.connection.last_name}
                                        </div>
                                    </div>
                                )}
                                {contact.notes && (
                                    <div className="mt-5 ml-3 notes">
                                        <h2 className="h6 mb-2 notes__heading">
                                            {notes_heading}
                                        </h2>
                                        <div className="notes__content">
                                            {contact.notes}
                                        </div>
                                    </div>
                                )}
                                {contact.history && (
                                    <ContactDetailsHistory
                                        history={contact.history}
                                        heading={history_heading}
                                        status={status}
                                    />
                                )}
                                <div className="d-flex mt-6 mb-2 justify-content-end">
                                    <Link
                                        className="btn btn-success transition-3d-hover"
                                        to={`${routes.AUTHENTICATED}${routes.NEW_TRANSACTION}`}
                                    >
                                        {button_label}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )}
        </>
    );
};

const mapStateToProps = ({ contacts, user }) => {
    const { userContacts, orgContacts } = contacts;
    const { userData } = user;
    return { userContacts, orgContacts, userData };
};

export default withRouter(connect(mapStateToProps, { removeContact })(ContactDetails));
