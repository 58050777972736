import React from 'react';
import DatePicker from './DatePicker';

const DatePickerWithIcon = props => (
    <div className="js-focus-state u-datepicker input-group">
        <div
            className="input-group-prepend"
            style={{
                maxHeight: 50
            }}
        >
            <span className="input-group-text">
                <span className="fas fa-calendar" />
            </span>
        </div>
        <DatePicker {...props} />
    </div>
);

export default DatePickerWithIcon;
