import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const PasswordModal = ({
    handleOpen,
    handleToggle,
    handleSubmit,
    title,
    password,
    handleChangePassword,
    cancelTitle,
    saveTitle,
    placeholder
}) => {
    return (
        <Modal
            centered
            isOpen={handleOpen}
            toggle={handleToggle}
            className="personalInfoModal"
        >
            <ModalHeader toggle={handleToggle}>{title}</ModalHeader>

            <ModalBody>
                <input
                    type="text"
                    value={password}
                    onChange={handleChangePassword}
                    className="fs-5 borderColor rounded form-control"
                    placeholder={placeholder}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleToggle}>{cancelTitle}</Button>
                <Button color="primary" onClick={handleSubmit}>
                    {saveTitle}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default PasswordModal;
