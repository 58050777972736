import React from 'react';

const Pitch = () => {
    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            New home for Pitch Deck!!!!!!!!!!!!!!!
        </div>
    );
};
export default Pitch;
