import { combineReducers } from 'redux';
import AuthReducer from './Auth';
import InitialReducer from './Initial';
import TransactionsReducer from './Transactions';
import UserReducer from './User';
import OrgReducer from './Org';
import AppReducer from './App';
import PreferencesReducer from './Preferences';
import ProfileReducer from './Profile';
import SuperAdminReducer from './SuperAdmin';
import PropertyReducer from './Property';
import DocumentsReducer from './Documents';
import ContactsReducer from './Contacts';
import LangReducer from './Lang';
import TransactionActivityReducer from './TransactionActivity';
import ModalReducer from './Modal';

import { LOGOUT_USER } from '../actions/types';

const appReducer = combineReducers({
    auth: AuthReducer,
    init: InitialReducer,
    transactions: TransactionsReducer,
    user: UserReducer,
    org: OrgReducer,
    app: AppReducer,
    pref: PreferencesReducer,
    profile: ProfileReducer,
    super: SuperAdminReducer,
    property: PropertyReducer,
    documents: DocumentsReducer,
    contacts: ContactsReducer,
    lang: LangReducer,
    activity: TransactionActivityReducer,
    modal: ModalReducer
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT_USER) {
        const { init } = state;
        state = { init };
    }
    return appReducer(state, action);
};

export default rootReducer;
