import {
    GET_PROPERTY_MLS,
    GET_PROPERTY_MLS_SUCCESS,
    RESET_PROPERTY_DATA
} from '../actions/types';

const INITIAL_STATE = {
    propertyData: null,
    loading: null
};

const propertyReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_PROPERTY_MLS:
            return {
                ...state,
                loading: true
            };
        case GET_PROPERTY_MLS_SUCCESS:
            return {
                ...state,
                loading: false,
                propertyData: action.payload
            };
        case RESET_PROPERTY_DATA:
            return {
                ...state,
                loading: null,
                propertyData: null
            };
        default:
            return state;
    }
};

export default propertyReducer;
