// React
import React from 'react';

// Packages
import { Field } from 'formik';
import { FormFeedback } from 'reactstrap';
import Flatpickr from 'react-flatpickr';

const DatePicker = ({
    required,
    type,
    className,
    name,
    id,
    placeholder,
    error,
    value,
    onBlur,
    onChange,
    myStyle,
    options,
    touched,
    nonFormik
}) => {
    return (
        <div style={myStyle}>
            {nonFormik ? (
                <Flatpickr
                    autoComplete="off"
                    className={className}
                    id={id}
                    placeholder={placeholder}
                    options={options}
                />
            ) : (
                <>
                    <Field
                        autoComplete="off"
                        required={required}
                        type={type}
                        className={className}
                        style={{
                            borderColor:
                                error && touched
                                    ? '#de4437'
                                    : value
                                    ? 'rgba(0, 201, 167, 0.5)'
                                    : ''
                        }}
                        name={name}
                        id={id}
                        placeholder={placeholder}
                        error={error}
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        options={options}
                        as={Flatpickr}
                    />
                    <FormFeedback
                        style={{ display: error && touched ? 'block' : 'none' }}
                    >
                        {error}
                    </FormFeedback>
                </>
            )}
        </div>
    );
};

export default DatePicker;
