// React
import React from 'react';

// Packages
import { Field } from 'formik';
import { Input, FormFeedback } from 'reactstrap';

const TextField = ({
    required,
    type,
    className,
    name,
    id,
    placeholder,
    error,
    value,
    onBlur,
    onChange,
    onFocus,
    invalid,
    myStyle,
    nonFormik,
    rows,
    parentStyle,
    min,
    max,
    hasDefaultFill,
    disabled
}) => {
    return (
        <div style={parentStyle ? parentStyle : myStyle}>
            {nonFormik ? (
                <Input
                    required={required}
                    type={type}
                    className={className}
                    style={{
                        ...myStyle,
                        borderColor: !invalid && value ? 'rgba(0, 201, 167, 0.5)' : ''
                    }}
                    name={name}
                    id={id}
                    placeholder={placeholder}
                    min={min}
                    max={max}
                    error={error}
                    value={value}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onChange={onChange}
                    invalid={invalid}
                    rows={rows}
                    autoComplete="google-disable"
                    disabled={disabled}
                />
            ) : (
                <>
                    <Field
                        required={required}
                        type={type}
                        className={className}
                        style={{
                            ...myStyle,
                            borderColor:
                                !invalid && value && !hasDefaultFill
                                    ? 'rgba(0, 201, 167, 0.5)'
                                    : ''
                        }}
                        name={name}
                        id={id}
                        placeholder={placeholder}
                        error={error}
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        as={Input}
                        invalid={invalid}
                        autoComplete="off"
                        disabled={disabled}
                    />
                    <FormFeedback>{error}</FormFeedback>
                </>
            )}
        </div>
    );
};

export default TextField;
