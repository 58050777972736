import {
    REMOVING_PREFERENCE_TASK,
    ADDING_PREFERENCE_TASK,
    REMOVING_PREFERENCE_TASK_SUCCESS,
    REMOVING_PREFERENCE_TASK_FAILURE,
    ADDING_PREFERENCE_TASK_SUCCESS,
    ADDING_PREFERENCE_TASK_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    loading: null,
    uploadError: null
};

const preferencesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REMOVING_PREFERENCE_TASK:
            return {
                ...state,
                loading: true,
                uploadError: false
            };
        case ADDING_PREFERENCE_TASK:
            return {
                ...state,
                loading: true,
                uploadError: false
            };
        case REMOVING_PREFERENCE_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                uploadError: false
            };
        case REMOVING_PREFERENCE_TASK_FAILURE:
            return {
                ...state,
                loading: false,
                uploadError: true
            };
        case ADDING_PREFERENCE_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                uploadError: false
            };
        case ADDING_PREFERENCE_TASK_FAILURE:
            return {
                ...state,
                loading: false,
                uploadError: true
            };
        default:
            return state;
    }
};

export default preferencesReducer;
