import { all, fork, take, cancelled, takeLatest, race, put } from 'redux-saga/effects';

import { GET_USER_ORG, LOGOUT_USER } from '../actions/types';

import { eventChannel } from 'redux-saga';

import { storeOrgData } from '../actions/Org';

import { db, docId } from '../../config/Firebase';

// Loggers
import { log } from '../../utils/Loggers';

const orgs = db.collection('orgs');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Get User Organization ////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* orgCollectionWatch(user) {
    /************************************ IMPORTANT ************************************/
    /*** We must be cogniscent in the future if any user will have more than 10 orgs ***
     *** they are connected to due to Firebase only allowing up to 10 comparisons in ***
     *** an "in" query ***/
    /***********************************************************************************/

    let unsubscribeUserOrgData;
    const userOrgs = orgs => {
        const orgIds = [];
        if (orgs) {
            orgs.forEach(org => orgIds.push(org.id));
            if (orgIds.length === orgs.length) return orgIds;
        }
    };

    const orgCollectionChannel = eventChannel(emit => {
        unsubscribeUserOrgData = orgs
            .where(docId, 'in', userOrgs(user?.orgs))
            .onSnapshot(function (querySnapshot) {
                if (querySnapshot) {
                    var userOrgs = [];
                    querySnapshot.forEach(function (doc) {
                        userOrgs.push(doc.data());
                    });
                    emit({
                        orgs: userOrgs,
                        active_org_id: user.active_org_id,
                        user_id: user.id
                    });
                } else {
                    const doc = { exists: false };
                    emit({ doc });
                }
            });
        return unsubscribeUserOrgData;
    });
    try {
        while (true) {
            const { userSignOut, userOrgData } = yield race({
                userSignOut: take(LOGOUT_USER),
                userOrgData: take(orgCollectionChannel)
            });

            if (userSignOut) {
                orgCollectionChannel.close(); // Detach saga event emitter
            } else {
                yield put(storeOrgData(userOrgData));
            }
        }
    } catch (error) {
        log('Org Error: getting user org collection data (FS)', {
            error,
            user
        });
    } finally {
        unsubscribeUserOrgData(); // Detach firebase listener
        if (yield cancelled()) {
            orgCollectionChannel.close(); // Detach saga event emitter
            unsubscribeUserOrgData(); // Detach firebase listener
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* getOrgCollection() {
    yield takeLatest(GET_USER_ORG, orgCollectionWatch);
}

export default function* rootSaga() {
    yield all([fork(getOrgCollection)]);
}
