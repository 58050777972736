import { TOGGLE_SIDE_NAV, ENABLING_NAV_BUTTON } from '../actions/types';

const INITIAL_STATE = {
    sideNav: false,
    pristine: true,
    disabledButton: false
};

const appReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TOGGLE_SIDE_NAV:
            return {
                ...state,
                sideNav: !state.sideNav,
                pristine: false,
                disabledButton: action.payload
            };
        case ENABLING_NAV_BUTTON:
            return {
                ...state,
                disabledButton: false
            };
        default:
            return state;
    }
};

export default appReducer;
