import React from 'react';
import { connect } from 'react-redux';

// Context
import { useLang } from '../../../context/LangContext';

import OfficesList from './OfficesList';
import Office from './Office';

const OrganizationInfo = ({ userData, activeOrg, orgs }) => {
    const lang = useLang()['Settings']['OrganizationInfo'];
    const activeOrgId = activeOrg?.id;

    return (
        <>
            <div className="card-header d-flex align-items-center">
                <h1 className="col-6 h5 font-weight-medium mb-0">{lang.heading}</h1>
                <div className="col-6 d-flex align-items-center justify-content-end card-header__actions">
                    <button
                        className="btn btn-outline-secondary d-flex align-items-center mr-2"
                        style={{ maxHeight: '36px' }}
                    >
                        {lang.addOrg_label}
                    </button>
                    <button
                        className="btn btn-outline-secondary d-flex align-items-center"
                        style={{ maxHeight: '36px' }}
                    >
                        {lang.addOffice_label}
                    </button>
                </div>
            </div>
            <div className="card-body">
                {orgs?.length > 1 ? (
                    <OfficesList lang={lang} orgs={orgs} activeOrgId={activeOrgId} />
                ) : (
                    <Office />
                )}
            </div>
        </>
    );
};

const mapStateToProps = ({ user, org }) => {
    const { userData } = user;
    const { activeOrg, orgs } = org;
    return { userData, activeOrg, orgs };
};

export default connect(mapStateToProps)(OrganizationInfo);
