// React
import React, { useState, useEffect } from 'react';

// Components
import { DocumentCard, DocumentRow } from '../../components';

// Hooks
import { useWindowSize } from '../../hooks/useWindowSize';

// Packages
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { isTablet } from 'react-device-detect';

// Redux
import { removeUserDocument } from '../../store/actions/Documents';

// Router
import * as routes from '../../router/config/routes';
import { useLang } from '../../context/LangContext';
import ConfirmationDialog from '../common/ConfirmationDialog';

const DocumentsGrid = ({
    match,
    filteredUserDocuments,
    documentList,
    filteredOrgDocuments,
    libraryList,
    searchSuggestions,
    userData,
    removeUserDocument
}) => {
    const [modVar, setModVar] = useState(4);
    const [cardMargin, setCardMargin] = useState('15px');
    const iPadProPixels = 2390;
    const { windowHeight, windowWidth } = useWindowSize();
    const totalWindowPixels = windowHeight + windowWidth;
    const library =
        `${routes.AUTHENTICATED}${routes.DASHBOARD}${routes.LIBRARY}` === match.path;
    const {
        title_question,
        content_delete,
        success_title_delete,
        success_content_delete,
        failed_title,
        failed_content,
        confirm_button_delete,
        cancel_button,
        loading_title,
        loading_content
    } = useLang()['Common']['ConfirmationDialog'];

    useEffect(() => {
        const iPadRegular = isTablet && totalWindowPixels < iPadProPixels;
        const mod3 = windowWidth < 992 || iPadRegular ? 3 : false;
        if (mod3) {
            setCardMargin('13.25px');
            setModVar(3);
        } else {
            if ((isTablet && !iPadRegular) || (!isTablet && windowWidth < 1200)) {
                setCardMargin('12px');
            } else {
                setCardMargin('15px');
            }
            setModVar(4);
        }
    }, [windowHeight, windowWidth, totalWindowPixels]);

    const filterBySuggestions = () => {
        return searchSuggestions.map(suggestion => suggestion.document);
    };

    const documents = searchSuggestions.length
        ? filterBySuggestions()
        : library
        ? filteredOrgDocuments
        : filteredUserDocuments;
    return (
        <>
            <ConfirmationDialog
                titleText={title_question}
                contentText={content_delete}
                confirmButtonText={confirm_button_delete}
                cancelButtonText={cancel_button}
                loadingTitleText={loading_title}
                loadingContentText={loading_content}
                successTitleText={success_title_delete}
                successContentText={success_content_delete}
                failedTitleText={failed_title}
                failedContentText={failed_content}
            />
            <div className="mb-4" style={{ width: '100%' }}>
                {/* {loading && <Loader />} */}
                {documents && documents.length ? (
                    <>
                        {library ? (
                            <>
                                {libraryList === 'grid' ? (
                                    <div
                                        className="card-deck-flex d-block d-md-flex"
                                        style={{ justifyContent: 'flex-start' }}
                                    >
                                        {documents.map((doc, index) => (
                                            <div
                                                className="card card-flex mb-5"
                                                key={doc.id}
                                                style={{
                                                    marginRight:
                                                        (index + 1) % modVar !== 0
                                                            ? cardMargin
                                                            : 0
                                                }}
                                            >
                                                <DocumentCard
                                                    match={match}
                                                    document={doc}
                                                    userData={userData}
                                                    removeUserDocument={
                                                        removeUserDocument
                                                    }
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <>
                                        {documents.map(doc => (
                                            <DocumentRow
                                                match={match}
                                                key={doc.id}
                                                document={doc}
                                                lang={userData?.language}
                                                userData={userData}
                                                removeUserDocument={removeUserDocument}
                                            />
                                        ))}
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                {documentList === 'grid' ? (
                                    <div
                                        className="card-deck-flex d-block d-md-flex"
                                        style={{ justifyContent: 'flex-start' }}
                                    >
                                        {documents.map((doc, index) => (
                                            <div
                                                className="card card-flex mb-5"
                                                key={doc.id}
                                                style={{
                                                    marginRight:
                                                        (index + 1) % modVar !== 0
                                                            ? cardMargin
                                                            : 0
                                                }}
                                            >
                                                <DocumentCard
                                                    match={match}
                                                    document={doc}
                                                    userData={userData}
                                                    removeUserDocument={
                                                        removeUserDocument
                                                    }
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <>
                                        {documents.map(doc => (
                                            <DocumentRow
                                                match={match}
                                                key={doc.id}
                                                document={doc}
                                                lang={userData?.language}
                                                userData={userData}
                                                removeUserDocument={removeUserDocument}
                                            />
                                        ))}
                                    </>
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <Alert color="info" className="mb-0">
                        No documents found!
                    </Alert>
                )}
            </div>
        </>
    );
};

const mapStateToProps = ({ documents, user }) => {
    const {
        filteredUserDocuments,
        filteredOrgDocuments,
        libraryList,
        documentList,
        searchSuggestions
    } = documents;
    const { userData } = user;
    return {
        filteredUserDocuments,
        filteredOrgDocuments,
        libraryList,
        documentList,
        searchSuggestions,
        userData
    };
};

export default withRouter(
    connect(mapStateToProps, { removeUserDocument })(DocumentsGrid)
);
