import {
    SET_TRANSACTION_LIST,
    SET_USER_TRANSACTIONS,
    SET_USER_CLOSED_TRANSACTIONS,
    SET_USER_ARCHIVED_TRANSACTIONS,
    SET_PROPERTY_DATA,
    CREATE_TRANSACTION,
    CREATE_TRANSACTION_SUCCESS,
    // CREATE_TRANSACTION_FAILURE,
    RESET_TRANSACTION_STATE,
    UPDATE_TRANSACTION_NOTE,
    UPDATE_TRANSACTION_NOTE_SUCCESS,
    // UPDATE_TRANSACTION_NOTE_FAILURE,
    UPDATE_TRANSACTION_TASK,
    UPDATE_TRANSACTION_TASK_SUCCESS,
    UPDATE_TRANSACTION_TASK_FAILURE,
    UPDATE_ACTIVITY_POST,
    UPDATE_ACTIVITY_POST_SUCCESS,
    // UPDATE_ACTIVITY_POST_FAILURE,
    SET_TRANSACTION_FILTER,
    SET_TRX_SEARCH_SUGGESTIONS,
    GET_TRANSACTION_DETAILS,
    SET_TRANSACTION_DETAILS,
    RESET_DETAILS_FLAG,
    CANCEL_DETAILS_LISTENER,
    UPDATE_TRANSACTION_STATUS
} from './types';

export const setTransactionList = type => {
    return {
        type: SET_TRANSACTION_LIST,
        payload: type
    };
};

export const storeUserTransactions = transactions => {
    return {
        type: SET_USER_TRANSACTIONS,
        payload: transactions
    };
};

export const storeClosedTransactions = transactions => {
    return {
        type: SET_USER_CLOSED_TRANSACTIONS,
        payload: transactions
    };
};

export const storeArchivedTransactions = transactions => {
    return {
        type: SET_USER_ARCHIVED_TRANSACTIONS,
        payload: transactions
    };
};

export const createNewTransaction = transaction => {
    return {
        type: CREATE_TRANSACTION,
        payload: transaction
    };
};

export const transactionWriteSuccess = id => {
    return {
        type: CREATE_TRANSACTION_SUCCESS,
        payload: id
    };
};

export const resettingTransactionState = () => {
    return {
        type: RESET_TRANSACTION_STATE
    };
};

export const setPropertyData = data => {
    return {
        type: SET_PROPERTY_DATA,
        payload: data
    };
};

export const updateTransactionNotes = notes => {
    return {
        type: UPDATE_TRANSACTION_NOTE,
        payload: notes
    };
};

export const noteWriteSuccess = () => {
    return {
        type: UPDATE_TRANSACTION_NOTE_SUCCESS
    };
};

export const updateTransactionTasks = tasks => {
    return {
        type: UPDATE_TRANSACTION_TASK,
        payload: tasks
    };
};

export const taskWriteSuccess = () => {
    return {
        type: UPDATE_TRANSACTION_TASK_SUCCESS
    };
};

export const taskWriteFailure = () => {
    return {
        type: UPDATE_TRANSACTION_TASK_FAILURE
    };
};

export const updateActivityPosts = posts => {
    return {
        type: UPDATE_ACTIVITY_POST,
        payload: posts
    };
};

export const postWriteSuccess = () => {
    return {
        type: UPDATE_ACTIVITY_POST_SUCCESS
    };
};

export const settingTransactionFilter = data => {
    return {
        type: SET_TRANSACTION_FILTER,
        payload: data
    };
};

export const settingSearchSuggestions = data => {
    return {
        type: SET_TRX_SEARCH_SUGGESTIONS,
        payload: data
    };
};

export const gettingTransactionDetails = data => {
    return {
        type: GET_TRANSACTION_DETAILS,
        payload: data
    };
};

export const settingTransactionDetails = data => {
    return {
        type: SET_TRANSACTION_DETAILS,
        payload: data
    };
};

export const resetDetailsFlag = () => {
    return {
        type: RESET_DETAILS_FLAG
    };
};

export const cancelDetailsListener = () => {
    return {
        type: CANCEL_DETAILS_LISTENER
    };
};

export const updateTransactionStatus = payload => {
    return {
        type: UPDATE_TRANSACTION_STATUS,
        payload
    };
};
