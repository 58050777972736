import { UPDATE_USER_AVATAR, UPDATE_USER_AVATAR_SUCCESS } from './types';

export const uploadUserAvatar = img => {
    return {
        type: UPDATE_USER_AVATAR,
        payload: img
    };
};

export const uploadUserAvatarSuccess = () => {
    return {
        type: UPDATE_USER_AVATAR_SUCCESS
    };
};
