import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { baseConfig } from '../utils/Constants';
import { history } from '../history';
import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import reducers from '../store/reducers';
import rootSaga from '../store/sagas';

export default function configureStore() {
    // Sentry error reporting init config
    Sentry.init({
        dsn: baseConfig.SENTRY.dsn,
        integrations: [new Integrations.BrowserTracing()],
        environment: process.env.NODE_ENV,
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0
    });
    Sentry.setTag('app-type', 'react-web-prototype');

    // Redux saga config
    const sagaMiddleware = createSagaMiddleware();

    const sentryReduxEnhancer = Sentry.createReduxEnhancer({
        // I can pass options here
    });

    const store = createStore(
        connectRouter(history)(reducers),
        {},
        compose(
            applyMiddleware(sagaMiddleware, routerMiddleware(history)),
            sentryReduxEnhancer
        )
    );

    sagaMiddleware.run(rootSaga);

    return store;
}
