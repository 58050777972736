// React
import React from 'react';

// Context
import { useLang } from '../../context/LangContext';

const TransactionFormHeader = ({ step, setStep, handleSubmit }) => {
    const { step_1_label, step_2_label, step_3_label } =
        useLang()['NewTransaction']['FormHeader'];
    const navItems = [
        {
            id: 1,
            name: step_1_label,
            className: 'fas fa-user-circle nav-icon-action-inner'
        },
        {
            id: 2,
            name: step_2_label,
            className: 'fas fa-file-invoice-dollar nav-icon-action-inner'
        },
        {
            id: 3,
            name: step_3_label,
            className: 'fas fa-cogs nav-icon-action-inner'
        }
    ];

    const handleFormNav = id => {
        if (step === id) {
            //do nothing
        } else if (step < id) {
            handleSubmit();
        } else if (step > id) {
            setStep(step - 1);
        }
    };

    return (
        <div className="card-header p-5">
            <nav
                id="progressStepForm"
                className="js-step-progress nav nav-icon nav-justified text-center"
            >
                {navItems.map(item => (
                    <div
                        key={item.id}
                        className={step === item.id ? 'nav-item active' : 'nav-item'}
                        style={{
                            pointerEvents: step === item.id ? 'none' : 'auto',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleFormNav(item.id)}
                    >
                        <span className="nav-icon-action">
                            <span className={item.className} />
                        </span>
                        {item.name}
                    </div>
                ))}
            </nav>
        </div>
    );
};

export default TransactionFormHeader;
