// React
import React from 'react';

// Packages
import ContentLoader from 'react-content-loader';

const CardSkeleton = props => {
    return (
        <ContentLoader
            viewBox="0 0 100 404"
            preserveAspectRatio="none"
            height={404}
            width={'100%'}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            speed={2}
            style={{ position: 'absolute', index: 10000, animation: 'fadeIn .25s' }}
            {...props}
        >
            <rect
                className="card-img-top w-100"
                x={0}
                y={0}
                width="100%"
                height="185px"
            />
            <rect x="10%" rx=".3125" ry=".3125" y={201.16} width="80%" height="18px" />
            <rect x="10%" rx=".3125" ry=".3125" y={224.16} width="35%" height="16px" />
            <rect x="10%" rx=".3125" ry=".3125" y={254.16} width="70%" height="25px" />
            <rect x="10%" rx=".3125" ry=".3125" y={290.16} width="50%" height="27px" />
            <rect x="10%" rx=".3125" ry=".3125" y={338.16} width="80%" height="43px" />
        </ContentLoader>
    );
};

export default CardSkeleton;
