// React
import React, { useState } from 'react';

// Components
import { TextField, StateCountrySelect } from '../../components';

// Constants
import { lookupRegExp } from '../../utils/Constants';
import { stateProvince } from '../../utils/Constants';

// Context
import { useLang } from '../../context/LangContext';

// Loggers
import { log } from '../../utils/Loggers';

// Packages
import SmartyStreetsSDK from 'smartystreets-javascript-sdk';
import { Label, Button } from 'reactstrap';

const SmartyStreetsCore = SmartyStreetsSDK.core;
const Lookup = SmartyStreetsSDK.usAutocomplete.Lookup;
const Address = SmartyStreetsSDK.usStreet.Lookup;

const AddContactForm = ({
    parties,
    checkError,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    arrayHelpers,
    handleChange,
    handleBlur,
    checkTouched,
    touched,
    addedParty,
    setIdCount,
    idCount,
    getUsState
}) => {
    const key = process.env.REACT_APP_SMARTY_WEBSITE_KEY;
    const credentials = new SmartyStreetsCore.SharedCredentials(key);
    const lookupClient = SmartyStreetsCore.buildClient.usAutocomplete(credentials);
    const addressClient = SmartyStreetsCore.buildClient.usStreet(credentials);
    const [lookupResults, setLookupResults] = useState(null);
    const [resultsIndex, setResultsIndex] = useState(null);
    const {
        first_name_label,
        first_name_placeholder,
        middle_initial_label,
        last_name_label,
        last_name_placeholder,
        nick_name_label,
        nick_name_placeholder,
        email_address_label,
        email_address_placeholder,
        phone_number_label,
        phone_number_placeholder,
        extension_label,
        extension_placeholder,
        address_label,
        address_placeholder,
        city_label,
        city_placeholder,
        unit_label,
        unit_placeholder,
        state_label,
        zip_label,
        zip_placeholder,
        mailing_address_same_label,
        contact_notes_label,
        contact_notes_placeholder,
        remove_contact_label,
        add_another_contact_label
    } = useLang()['Contacts']['AddContactForm'];

    const addressInputHandler = (address, index) => {
        if (lookupRegExp.valid.test(address)) {
            const lookup = new Lookup(address);
            lookup.maxSuggestions = 6;
            lookupClient
                .send(lookup)
                .then(results => {
                    const { result } = results;
                    setLookupResults(result);
                    setResultsIndex(index);
                })
                .catch(error => {
                    log('Smarty Streets Error: error in address lookup results', {
                        error,
                        address,
                        function: 'addressInputHandler'
                    });
                });
        } else {
            setLookupResults(null);
            setResultsIndex(null);
        }
    };

    const getAddressFromLookup = address => {
        const batch = new SmartyStreetsCore.Batch();
        const verifiedAddress = new Address();
        verifiedAddress.street = address.streetLine;
        verifiedAddress.urbanization = ''; // Only applies to Puerto Rico addresses
        verifiedAddress.city = address.city;
        verifiedAddress.state = address.state;
        verifiedAddress.maxCandidates = 3;
        verifiedAddress.match = 'invalid';
        batch.add(verifiedAddress);
        addressClient
            .send(batch)
            .then(result => {
                const { components, metadata } = result.lookups[0].result[0] || null;
                const address1 = `${components.primaryNumber}${
                    components.streetPredirection
                        ? ' ' + components.streetPredirection
                        : ''
                }${components.streetName ? ' ' + components.streetName : ''}${
                    components.streetSuffix ? ' ' + components.streetSuffix : ''
                }${
                    components.streetPostdirection
                        ? ' ' + components.streetPostdirection
                        : ''
                } `;
                const address2 = components.secondaryNumber
                    ? `${components.secondaryNumber} `
                    : '';

                if (result.lookups[0].result[0]) {
                    setFieldTouched(`parties.${resultsIndex}.address`, true);
                    setFieldValue(`parties.${resultsIndex}.address`, address1);
                    setFieldTouched(`parties.${resultsIndex}.address2`, true);
                    setFieldValue(`parties.${resultsIndex}.address2`, address2);
                    setFieldTouched(`parties.${resultsIndex}.city`, true);
                    setFieldValue(`parties.${resultsIndex}.city`, components.cityName);
                    setFieldTouched(`parties.${resultsIndex}.state`, true);
                    setFieldValue(
                        `parties.${resultsIndex}.state`,
                        getUsState(components.state)
                    );
                    setFieldTouched(`parties.${resultsIndex}.zip`, true);
                    setFieldValue(`parties.${resultsIndex}.zip`, components.zipCode);
                    setFieldValue(`parties.${resultsIndex}.lat`, metadata.latitude);
                    setFieldValue(`parties.${resultsIndex}.lon`, metadata.longitude);
                    setLookupResults(null);
                    setResultsIndex(null);
                }
            })
            .catch(error => {
                log('Smarty Streets Error: error in address lookup details', {
                    error,
                    address,
                    function: 'getAddressFromLookup'
                });
            });
    };

    return (
        <div
            id="contentStepForm"
            className="card-body p-5 mx-lg-auto"
            style={{
                animation: 'fadeIn .5s'
            }}
        >
            {parties && parties.length > 0
                ? parties.map((party, index) => (
                      <div key={party.id}>
                          <div className="row">
                              <div className="col-sm-4 mb-0">
                                  <div className="js-form-message form-group">
                                      <Label className="form-label">
                                          {first_name_label}
                                      </Label>
                                      <TextField
                                          type="text"
                                          className="form-control"
                                          name={`parties.${index}.firstName`}
                                          id={`parties.${index}.firstName`}
                                          placeholder={first_name_placeholder}
                                          aria-label={first_name_placeholder}
                                          error={checkError(errors, index, 'firstName')}
                                          value={values.parties[index].firstName}
                                          onBlur={e => {
                                              handleBlur(`parties.${index}.firstName`);
                                              setFieldTouched(
                                                  `parties.${index}.firstName`,
                                                  true
                                              );
                                              setFieldValue(
                                                  `parties.${index}.firstName`,
                                                  e.target.value.trim()
                                              );
                                          }}
                                          onChange={e => {
                                              handleChange(e);
                                          }}
                                          invalid={
                                              !!checkError(errors, index, 'firstName') &&
                                              checkTouched(touched, index, 'firstName')
                                          }
                                      />
                                  </div>
                              </div>
                              <div className="col-sm-1 mb-0">
                                  <div className="js-form-message">
                                      <Label className="form-label ml-1">
                                          {middle_initial_label}
                                      </Label>
                                      <TextField
                                          type="text"
                                          className="form-control p-0 pl-1"
                                          name={`parties.${index}.middleInitial`}
                                          id={`parties.${index}.middleInitial`}
                                          placeholder=""
                                          aria-label={middle_initial_label}
                                          error={checkError(
                                              errors,
                                              index,
                                              'middleInitial'
                                          )}
                                          value={values.parties[index].middleInitial}
                                          onBlur={e => {
                                              handleBlur(
                                                  `parties.${index}.middleInitial`
                                              );
                                              setFieldTouched(
                                                  `parties.${index}.middleInitial`,
                                                  true
                                              );
                                              setFieldValue(
                                                  `parties.${index}.middleInitial`,
                                                  e.target.value.trim()
                                              );
                                          }}
                                          onChange={e => {
                                              handleChange(e);
                                          }}
                                          invalid={
                                              !!checkError(
                                                  errors,
                                                  index,
                                                  'middleInitial'
                                              ) &&
                                              checkTouched(
                                                  touched,
                                                  index,
                                                  'middleInitial'
                                              )
                                          }
                                      />
                                  </div>
                              </div>
                              <div className="col-sm-4 mb-0">
                                  <div className="js-form-message form-group">
                                      <Label className="form-label">
                                          {last_name_label}
                                      </Label>
                                      <TextField
                                          type="text"
                                          className="form-control"
                                          name={`parties.${index}.lastName`}
                                          id={`parties.${index}.lastName`}
                                          placeholder={last_name_placeholder}
                                          aria-label={last_name_placeholder}
                                          error={checkError(errors, index, 'lastName')}
                                          value={values.parties[index].lastName}
                                          onBlur={e => {
                                              handleBlur(`parties.${index}.lastName`);
                                              setFieldTouched(
                                                  `parties.${index}.lastName`,
                                                  true
                                              );
                                              setFieldValue(
                                                  `parties.${index}.lastName`,
                                                  e.target.value.trim()
                                              );
                                          }}
                                          onChange={e => {
                                              handleChange(e);
                                          }}
                                          invalid={
                                              !!checkError(errors, index, 'lastName') &&
                                              checkTouched(touched, index, 'lastName')
                                          }
                                      />
                                  </div>
                              </div>
                              <div className="col-sm-3 mb-0">
                                  <div className="js-form-message form-group">
                                      <Label className="form-label">
                                          {nick_name_label}
                                      </Label>
                                      <TextField
                                          type="text"
                                          className="form-control"
                                          name={`parties.${index}.nickName`}
                                          id={`parties.${index}.nickName`}
                                          placeholder={nick_name_placeholder}
                                          aria-label={nick_name_placeholder}
                                          error={checkError(errors, index, 'nickName')}
                                          value={values.parties[index].nickName}
                                          onBlur={e => {
                                              handleBlur(`parties.${index}.nickName`);
                                              setFieldTouched(
                                                  `parties.${index}.nickName`,
                                                  true
                                              );
                                              setFieldValue(
                                                  `parties.${index}.nickName`,
                                                  e.target.value.trim()
                                              );
                                          }}
                                          onChange={e => {
                                              handleChange(e);
                                          }}
                                          invalid={
                                              !!checkError(errors, index, 'nickName') &&
                                              checkTouched(touched, index, 'nickName')
                                          }
                                      />
                                  </div>
                              </div>
                          </div>
                          <div className="row">
                              <div className="col-sm-6 mb-3">
                                  <div className="js-form-message form-group">
                                      <Label className="form-label">
                                          {email_address_label}
                                      </Label>
                                      <TextField
                                          type="text"
                                          className="form-control"
                                          name={`parties.${index}.email`}
                                          id={`parties.${index}.email`}
                                          placeholder={email_address_placeholder}
                                          aria-label={email_address_placeholder}
                                          error={checkError(errors, index, 'email')}
                                          value={values.parties[index].email}
                                          onBlur={e => {
                                              handleBlur(`parties.${index}.email`);
                                              setFieldTouched(
                                                  `parties.${index}.email`,
                                                  true
                                              );
                                              setFieldValue(
                                                  `parties.${index}.email`,
                                                  e.target.value.trim()
                                              );
                                          }}
                                          onChange={e => {
                                              handleChange(e);
                                          }}
                                          invalid={
                                              !!checkError(errors, index, 'email') &&
                                              checkTouched(touched, index, 'email')
                                          }
                                      />
                                  </div>
                              </div>
                              <div className="col-sm-4 mb-3">
                                  <div className="js-form-message form-group">
                                      <Label className="form-label">
                                          {phone_number_label}
                                      </Label>
                                      <TextField
                                          type="text"
                                          className="form-control"
                                          name={`parties.${index}.phone`}
                                          id={`parties.${index}.phone`}
                                          placeholder={phone_number_placeholder}
                                          aria-label={phone_number_placeholder}
                                          error={checkError(errors, index, 'phone')}
                                          value={values.parties[index].phone}
                                          onBlur={e => {
                                              handleBlur(`parties.${index}.phone`);
                                              setFieldTouched(
                                                  `parties.${index}.phone`,
                                                  true
                                              );
                                              setFieldValue(
                                                  `parties.${index}.phone`,
                                                  e.target.value.trim()
                                              );
                                          }}
                                          onChange={e => {
                                              handleChange(e);
                                          }}
                                          invalid={
                                              !!checkError(errors, index, 'phone') &&
                                              checkTouched(touched, index, 'phone')
                                          }
                                      />
                                  </div>
                              </div>
                              <div className="col-sm-2 mb-0">
                                  <div className="js-form-message">
                                      <Label id="usernameLabel" className="form-label">
                                          {extension_label}
                                      </Label>

                                      <div className="form-group">
                                          <TextField
                                              type="text"
                                              className="form-control"
                                              name={`parties.${index}.phoneExt`}
                                              id={`parties.${index}.phoneExt`}
                                              placeholder={extension_placeholder}
                                              aria-label={extension_placeholder}
                                              error={checkError(
                                                  errors,
                                                  index,
                                                  'phoneExt'
                                              )}
                                              value={values.parties[index].phoneExt}
                                              onBlur={e => {
                                                  handleBlur(`parties.${index}.phoneExt`);
                                                  setFieldTouched(
                                                      `parties.${index}.phoneExt`,
                                                      true
                                                  );
                                                  setFieldValue(
                                                      `parties.${index}.phoneExt`,
                                                      e.target.value.trim()
                                                  );
                                              }}
                                              onChange={e => {
                                                  handleChange(e);
                                              }}
                                              invalid={
                                                  !!checkError(
                                                      errors,
                                                      index,
                                                      'phoneExt'
                                                  ) &&
                                                  checkTouched(touched, index, 'phoneExt')
                                              }
                                          />
                                      </div>
                                  </div>
                              </div>
                          </div>
                          {!values.parties[index].sameAddress && (
                              <div
                                  style={{
                                      animation: 'fadeIn .5s'
                                  }}
                              >
                                  <div className="row">
                                      <div className="col-md-8 mb-3">
                                          <div className="form-group">
                                              <div className="js-focus-state">
                                                  <Label
                                                      className="form-label"
                                                      htmlFor="listingAddress"
                                                  >
                                                      {address_label}
                                                  </Label>

                                                  <div
                                                      className="input-group"
                                                      style={{
                                                          display: 'flex',
                                                          flexWrap: 'nowrap'
                                                      }}
                                                  >
                                                      <div
                                                          className="input-group-prepend"
                                                          style={{
                                                              maxHeight: 50
                                                          }}
                                                      >
                                                          <span
                                                              className="input-group-text"
                                                              id="listingAddressLabel"
                                                              style={{
                                                                  borderColor:
                                                                      !!checkError(
                                                                          errors,
                                                                          index,
                                                                          'address'
                                                                      ) &&
                                                                      checkTouched(
                                                                          touched,
                                                                          index,
                                                                          'address'
                                                                      )
                                                                          ? '#de4437'
                                                                          : ''
                                                              }}
                                                          >
                                                              <span className="fas fa-map-marker-alt" />
                                                          </span>
                                                      </div>

                                                      <TextField
                                                          type="text"
                                                          myStyle={{
                                                              borderTopLeftRadius: 0,
                                                              borderBottomLeftRadius: 0,
                                                              flexGrow: 1
                                                          }}
                                                          className="form-control"
                                                          name={`parties.${index}.address`}
                                                          id={`parties.${index}address`}
                                                          placeholder={
                                                              address_placeholder
                                                          }
                                                          aria-label={address_placeholder}
                                                          error={checkError(
                                                              errors,
                                                              index,
                                                              'address'
                                                          )}
                                                          value={
                                                              values.parties[index]
                                                                  .address
                                                          }
                                                          onBlur={e => {
                                                              handleBlur(
                                                                  `parties.${index}.address`
                                                              );
                                                              setFieldTouched(
                                                                  `parties.${index}.address`,
                                                                  true
                                                              );
                                                              setFieldValue(
                                                                  `parties.${index}.address`,
                                                                  e.target.value.trim()
                                                              );
                                                          }}
                                                          onChange={e => {
                                                              handleChange(e);
                                                              addressInputHandler(
                                                                  e.target.value,
                                                                  index
                                                              );
                                                          }}
                                                          invalid={
                                                              !!checkError(
                                                                  errors,
                                                                  index,
                                                                  'address'
                                                              ) &&
                                                              checkTouched(
                                                                  touched,
                                                                  index,
                                                                  'address'
                                                              )
                                                          }
                                                      />
                                                  </div>
                                              </div>
                                          </div>
                                          {lookupResults &&
                                          lookupResults.length &&
                                          resultsIndex === index ? (
                                              <ul className="auto-complete-container">
                                                  {lookupResults.map(result => (
                                                      <li
                                                          key={result.text}
                                                          className="auto-complete-item"
                                                          onClick={() => {
                                                              getAddressFromLookup(
                                                                  result
                                                              );
                                                          }}
                                                      >
                                                          {result.text}
                                                      </li>
                                                  ))}
                                              </ul>
                                          ) : null}
                                      </div>
                                      <div className="col-md-4 mb-3">
                                          <div className="form-group">
                                              <div className="js-focus-state">
                                                  <Label
                                                      className="form-label"
                                                      htmlFor="listingAddress"
                                                  >
                                                      {unit_label}
                                                  </Label>

                                                  <div
                                                      className="input-group"
                                                      style={{
                                                          display: 'flex',
                                                          flexWrap: 'nowrap'
                                                      }}
                                                  >
                                                      <div
                                                          className="input-group-prepend"
                                                          style={{
                                                              maxHeight: 50
                                                          }}
                                                      >
                                                          <span
                                                              className="input-group-text"
                                                              id="listingAddressLabel"
                                                              style={{
                                                                  borderColor:
                                                                      !!checkError(
                                                                          errors,
                                                                          index,
                                                                          'address2'
                                                                      ) &&
                                                                      checkTouched(
                                                                          touched,
                                                                          index,
                                                                          'address2'
                                                                      )
                                                                          ? '#de4437'
                                                                          : ''
                                                              }}
                                                          >
                                                              <span className="fas fa-map-marker-alt" />
                                                          </span>
                                                      </div>

                                                      <TextField
                                                          type="text"
                                                          myStyle={{
                                                              borderTopLeftRadius: 0,
                                                              borderBottomLeftRadius: 0,
                                                              flexGrow: 1
                                                          }}
                                                          className="form-control"
                                                          name={`parties.${index}.address2`}
                                                          id={`parties.${index}address2`}
                                                          placeholder={unit_placeholder}
                                                          aria-label={unit_placeholder}
                                                          error={checkError(
                                                              errors,
                                                              index,
                                                              'address2'
                                                          )}
                                                          value={
                                                              values.parties[index]
                                                                  .address2
                                                          }
                                                          onBlur={e => {
                                                              handleBlur(
                                                                  `parties.${index}.address2`
                                                              );
                                                              setFieldTouched(
                                                                  `parties.${index}.address2`,
                                                                  true
                                                              );
                                                              setFieldValue(
                                                                  `parties.${index}.address2`,
                                                                  e.target.value.trim()
                                                              );
                                                          }}
                                                          onChange={e => {
                                                              handleChange(e);
                                                          }}
                                                          invalid={
                                                              !!checkError(
                                                                  errors,
                                                                  index,
                                                                  'address2'
                                                              ) &&
                                                              checkTouched(
                                                                  touched,
                                                                  index,
                                                                  'address2'
                                                              )
                                                          }
                                                      />
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="row">
                                      <div className="col-md-6 mb-3">
                                          <div className="form-group">
                                              <div className="js-focus-state">
                                                  <Label className="form-label">
                                                      {city_label}
                                                  </Label>

                                                  <div
                                                      className="input-group"
                                                      style={{
                                                          display: 'flex',
                                                          flexWrap: 'nowrap'
                                                      }}
                                                  >
                                                      <div
                                                          className="input-group-prepend"
                                                          style={{
                                                              maxHeight: 50
                                                          }}
                                                      >
                                                          <span
                                                              className="input-group-text"
                                                              id="listingAddressLabel"
                                                              style={{
                                                                  borderColor:
                                                                      !!checkError(
                                                                          errors,
                                                                          index,
                                                                          `city`
                                                                      ) &&
                                                                      checkTouched(
                                                                          touched,
                                                                          index,
                                                                          `city`
                                                                      )
                                                                          ? '#de4437'
                                                                          : ''
                                                              }}
                                                          >
                                                              <span className="fas fa-city" />
                                                          </span>
                                                      </div>

                                                      <TextField
                                                          type="text"
                                                          myStyle={{
                                                              borderTopLeftRadius: 0,
                                                              borderBottomLeftRadius: 0,
                                                              flexGrow: 1
                                                          }}
                                                          className="form-control"
                                                          name={`parties.${index}.city`}
                                                          id={`parties.${index}.city`}
                                                          placeholder={city_placeholder}
                                                          aria-label={city_placeholder}
                                                          error={checkError(
                                                              errors,
                                                              index,
                                                              'city'
                                                          )}
                                                          value={
                                                              values.parties[index].city
                                                          }
                                                          onBlur={e => {
                                                              handleBlur(
                                                                  `parties.${index}.city`
                                                              );
                                                              setFieldTouched(
                                                                  `parties.${index}.city`,
                                                                  true
                                                              );
                                                              setFieldValue(
                                                                  `parties.${index}.city`,
                                                                  e.target.value.trim()
                                                              );
                                                          }}
                                                          onChange={e => {
                                                              handleChange(e);
                                                          }}
                                                          invalid={
                                                              !!checkError(
                                                                  errors,
                                                                  index,
                                                                  'city'
                                                              ) &&
                                                              checkTouched(
                                                                  touched,
                                                                  index,
                                                                  'city'
                                                              )
                                                          }
                                                      />
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-md-3 mb-3">
                                          <div className="form-group">
                                              <div className="js-focus-state">
                                                  <Label className="form-label">
                                                      {state_label}
                                                  </Label>
                                                  <StateCountrySelect
                                                      className="form-control custom-select"
                                                      name={`parties.${index}.state`}
                                                      id={`parties.${index}.state`}
                                                      placeholder=""
                                                      aria-label={state_label}
                                                      nestedOptions={true}
                                                      options={stateProvince}
                                                      error={checkError(
                                                          errors,
                                                          index,
                                                          'state'
                                                      )}
                                                      value={values.parties[index].state}
                                                      onBlur={() => {
                                                          handleBlur(
                                                              `parties.${index}.state`
                                                          );
                                                          setFieldTouched(
                                                              `parties.${index}.state`,
                                                              true
                                                          );
                                                      }}
                                                      onChange={e => {
                                                          handleChange(e);
                                                      }}
                                                      touched={checkTouched(
                                                          touched,
                                                          index,
                                                          'state'
                                                      )}
                                                      invalid={
                                                          !!checkError(
                                                              errors,
                                                              index,
                                                              'state'
                                                          ) &&
                                                          checkTouched(
                                                              touched,
                                                              index,
                                                              'state'
                                                          )
                                                      }
                                                  />
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-md-3 mb-3">
                                          <div className="form-group">
                                              <div className="js-focus-state">
                                                  <Label className="form-label">
                                                      {zip_label}
                                                  </Label>

                                                  <div
                                                      className="input-group"
                                                      style={{
                                                          display: 'flex',
                                                          flexWrap: 'nowrap'
                                                      }}
                                                  >
                                                      <div
                                                          className="input-group-prepend"
                                                          style={{
                                                              maxHeight: 50
                                                          }}
                                                      >
                                                          <span
                                                              className="input-group-text"
                                                              id="listingAddressLabel"
                                                              style={{
                                                                  borderColor:
                                                                      !!checkError(
                                                                          errors,
                                                                          index,
                                                                          `zip`
                                                                      ) &&
                                                                      checkTouched(
                                                                          touched,
                                                                          index,
                                                                          `zip`
                                                                      )
                                                                          ? '#de4437'
                                                                          : ''
                                                              }}
                                                          >
                                                              <span className="fas fa-city" />
                                                          </span>
                                                      </div>

                                                      <TextField
                                                          type="text"
                                                          myStyle={{
                                                              borderTopLeftRadius: 0,
                                                              borderBottomLeftRadius: 0,
                                                              flexGrow: 1
                                                          }}
                                                          className="form-control"
                                                          name={`parties.${index}.zip`}
                                                          id={`parties.${index}.zip`}
                                                          placeholder={zip_placeholder}
                                                          aria-label={zip_placeholder}
                                                          error={checkError(
                                                              errors,
                                                              index,
                                                              'zip'
                                                          )}
                                                          value={
                                                              values.parties[index].zip
                                                          }
                                                          onBlur={e => {
                                                              handleBlur(
                                                                  `parties.${index}.zip`
                                                              );
                                                              setFieldTouched(
                                                                  `parties.${index}.zip`,
                                                                  true
                                                              );
                                                              setFieldValue(
                                                                  `parties.${index}.zip`,
                                                                  e.target.value.trim()
                                                              );
                                                          }}
                                                          onChange={e => {
                                                              handleChange(e);
                                                          }}
                                                          invalid={
                                                              !!checkError(
                                                                  errors,
                                                                  index,
                                                                  'zip'
                                                              ) &&
                                                              checkTouched(
                                                                  touched,
                                                                  index,
                                                                  'zip'
                                                              )
                                                          }
                                                      />
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          )}
                          {index !== 0 && (
                              <div className="row">
                                  <div className="col-sm-6 mb-6">
                                      <div
                                          className="custom-control custom-checkbox"
                                          onClick={() =>
                                              setFieldValue(
                                                  `parties.${index}.sameAddress`,
                                                  !values.parties[index].sameAddress
                                              )
                                          }
                                      >
                                          <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              name={`parties.${index}.sameAddress`}
                                              id={`parties.${index}.sameAddress`}
                                              readOnly
                                              checked={values.parties[index].sameAddress}
                                          />
                                          <label
                                              className="custom-control-label"
                                              htmlFor="stylishCheckbox2"
                                          >
                                              {mailing_address_same_label}
                                          </label>
                                      </div>
                                  </div>
                                  {/* <div
                                      className="col-sm-6 mb-3 mt-4 text-right"
                                      onClick={() => arrayHelpers.remove(index)}
                                  >
                                      <span className="addParty small text-danger pointer">
                                          <ins>Remove contact</ins>
                                      </span>
                                  </div> */}
                              </div>
                          )}
                          <div className="row">
                              <div className="col-sm-12 mb-0">
                                  <div className="js-form-message form-group">
                                      <Label className="form-label">
                                          {contact_notes_label}
                                      </Label>
                                      <TextField
                                          type="textarea"
                                          rows="2"
                                          className="form-control"
                                          name={`parties.${index}.notes`}
                                          id={`parties.${index}.notes`}
                                          placeholder={contact_notes_placeholder}
                                          aria-label={contact_notes_placeholder}
                                          error={checkError(errors, index, 'notes')}
                                          value={values.parties[index].notes}
                                          onBlur={e => {
                                              handleBlur(`parties.${index}.notes`);
                                              setFieldTouched(
                                                  `parties.${index}.notes`,
                                                  true
                                              );
                                              setFieldValue(
                                                  `parties.${index}.notes`,
                                                  e.target.value.trim()
                                              );
                                          }}
                                          onChange={e => {
                                              handleChange(e);
                                          }}
                                          invalid={
                                              !!checkError(errors, index, 'notes') &&
                                              checkTouched(touched, index, 'notes')
                                          }
                                      />
                                  </div>
                              </div>
                          </div>

                          {index !== 0 && (
                              <div className="row">
                                  <div
                                      className="col-sm-12 mb-3 mt-4 text-right"
                                      onClick={() => arrayHelpers.remove(index)}
                                  >
                                      <span className="addParty small text-danger pointer">
                                          <ins>{remove_contact_label}</ins>
                                      </span>
                                  </div>
                              </div>
                          )}
                          <hr
                              style={{
                                  marginBottom: 32
                              }}
                          />
                      </div>
                  ))
                : null}
            <div className="row">
                <div className="col-sm-6 mb-6">
                    <Button
                        style={{
                            padding: 0
                        }}
                        color="link"
                        className="addParty d-inline-block u-text-muted"
                        type="button"
                        onClick={() => {
                            arrayHelpers.push(addedParty);
                            setIdCount(idCount + 1);
                        }}
                    >
                        <span className="mr-2">+</span>
                        {add_another_contact_label}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AddContactForm;
