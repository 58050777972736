import React from 'react';

const ActionButtons = ({
    handleClose,
    handleSubmit,
    saveLabel,
    cancelLabel,
    disabled
}) => (
    <div className="actionsContainer">
        <button className="btn btn-link text-danger" onClick={handleClose} type="button">
            {cancelLabel}
        </button>
        <button
            className="btn btn-primary"
            onClick={handleSubmit}
            type="submit"
            disabled={disabled}
        >
            {saveLabel}
        </button>
    </div>
);

export default ActionButtons;
