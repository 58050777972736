import React from 'react';
import { useDropzone } from 'react-dropzone';

const NewDocumentUpload = ({ setFieldValue, setDoc, dragClickLabel }) => {
    const maxSize = 2097152;
    const onDrop = acceptedFiles => {
        if (acceptedFiles.length) {
            const url = URL.createObjectURL(acceptedFiles[0]);
            setDoc(acceptedFiles[0]);
            setFieldValue('path', url);
        }
    };

    const { isDragActive, getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '.pdf',
        minSize: 0,
        maxSize
    });

    return (
        <div
            {...getRootProps()}
            style={{
                height: '140px',
                width: '660px',
                borderRadius: '12px',
                borderStyle: isDragActive ? 'solid' : 'dashed',
                borderColor: isDragActive ? '#377dff' : '#e7eaf3'
            }}
            className="d-flex flex-column justify-content-center align-items-center pointer"
        >
            <img
                className="js-svg-injector max-width-10 mb-3"
                src="/images/drag-n-drop.svg"
                alt="Add Document"
            />
            <input {...getInputProps()} name="path" />
            {dragClickLabel}
        </div>
    );
};

export default NewDocumentUpload;
