// React
import React from 'react';

// Components
import {
    AppIntegrations,
    Billing,
    OrganizationInfo,
    PersonalInfo,
    Preferences,
    SecuritySettings,
    Team,
    SettingsMenu
} from '../components';

// Packages
import { withRouter } from 'react-router-dom';
import { Switch, Route, Redirect } from 'react-router-dom';

//Router
import * as routes from '../router/config/routes';

const Settings = props => {
    return (
        <>
            <div
                id="main"
                className="size container pt-3 pt-xl-4"
                style={{ overflowY: 'auto' }}
            >
                <div className="row no-gutters">
                    <SettingsMenu match={props.match} />
                    <div className="col-lg-9 col-sm-12 no-gutters">
                        <div
                            className="card position-relative"
                            style={{
                                minHeight: '70vh',
                                height: '100%',
                                marginBottom: '50px'
                            }}
                        >
                            <Switch>
                                <Redirect
                                    exact
                                    from={`${props.match.url}`}
                                    to={`${props.match.url}${routes.PERSONAL_INFO}`}
                                />
                                <Route
                                    exact
                                    path={`${props.match.url}${routes.APP_INTEGRATIONS}`}
                                    render={props => <AppIntegrations {...props} />}
                                />
                                <Route
                                    path={`${props.match.url}${routes.BILLING}`}
                                    render={props => <Billing {...props} />}
                                />
                                <Route
                                    path={`${props.match.url}${routes.ORGANIZATION_INFO}`}
                                    render={props => <OrganizationInfo {...props} />}
                                />
                                <Route
                                    path={`${props.match.url}${routes.PERSONAL_INFO}`}
                                    render={props => <PersonalInfo {...props} />}
                                />
                                <Route
                                    path={`${props.match.url}${routes.PREFERENCES}`}
                                    render={props => <Preferences {...props} />}
                                />
                                <Route
                                    path={`${props.match.url}${routes.SECURITY_SETTINGS}`}
                                    render={props => <SecuritySettings {...props} />}
                                />
                                <Route
                                    path={`${props.match.url}${routes.TEAM}`}
                                    render={props => <Team {...props} />}
                                />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withRouter(Settings);
