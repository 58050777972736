// React
import React, { useState } from 'react';

// Components
import { TextField, SelectField } from '../../components';

// Context
import { useLang } from '../../context/LangContext';

//Packages
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';

const AddTaskModal = ({ addModal, toggleModal, addTask }) => {
    const [buyer, setBuyer] = useState(false);
    const [listing, setListing] = useState(false);
    const [taskTitle, setTaskTitle] = useState('');
    const [taskDescription, setTaskDescription] = useState('');
    const [days, setDays] = useState('');
    const [hours, setHours] = useState('');
    const [mins, setMins] = useState('');
    const [ordinal, setOrdinal] = useState('');
    const {
        add_task_label,
        add_task_tip,
        trx_type_label,
        buying_label,
        listing_label,
        task_title_label,
        task_title_placeholder,
        task_desc_label,
        task_desc_placeholder,
        default_expire_label,
        days_label,
        hour_label,
        min_label,
        ordinal_label,
        create_button_label,
        cancel_button_label
    } = useLang()['Common']['AddTaskModal'];

    const options = [
        { value: 'AM', label: 'AM' },
        { value: 'PM', label: 'PM' }
    ];

    const taskModalTip = add_task_tip;

    const handleAddition = () => {
        addTask({
            buyer,
            listing,
            title: taskTitle,
            description: taskDescription,
            days,
            hours,
            mins,
            ordinal
        });
        setBuyer(false);
        setListing(false);
        setTaskTitle('');
        setTaskDescription('');
        setDays('');
        setHours('');
        setMins('');
        setOrdinal('');
    };

    const onCancel = () => {
        toggleModal({ action: 'add', reset: false, cancel: true, task: null });
        setBuyer(false);
        setListing(false);
        setTaskTitle('');
        setTaskDescription('');
        setDays('');
        setHours('');
        setMins('');
        setOrdinal('');
    };

    const disabled =
        (!buyer && !listing) ||
        taskTitle.trim() === '' ||
        taskDescription.trim() === '' ||
        days.trim() === '' ||
        hours.trim() === '' ||
        mins.trim() === '' ||
        ordinal.trim() === '';

    return (
        <div>
            <Modal isOpen={addModal} toggle={toggleModal} className="add-task-modal">
                <ModalHeader toggle={onCancel}>{add_task_label}</ModalHeader>
                <ModalBody>
                    <div className="column">
                        <div
                            className="row"
                            style={{ float: 'left', flexWrap: 'nowrap' }}
                        >
                            <span
                                className="h6 text-secondary pl-3 mr-2"
                                style={{ display: 'inline' }}
                            >
                                *
                            </span>
                            <span
                                className="h6 text-secondary"
                                style={{ display: 'inline' }}
                            >
                                {taskModalTip}
                            </span>
                        </div>
                        <div className="row">
                            <div className="column pl-3 mt-3 mb-5">
                                <Label className="form-label">
                                    {trx_type_label}
                                    <span className="text-danger ml-1">*</span>
                                </Label>
                                <div className="custom-control custom-switch mb-3">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="customSwitch1"
                                        checked={buyer}
                                        onChange={e => setBuyer(e.target.checked)}
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor="customSwitch1"
                                    >
                                        {buying_label}
                                    </label>
                                </div>
                                <div className="custom-control custom-switch">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="customSwitch2"
                                        checked={listing}
                                        onChange={e => setListing(e.target.checked)}
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor="customSwitch2"
                                    >
                                        {listing_label}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-8 mb-0 mt-3 mb-2">
                                <div className="js-form-message form-group">
                                    <Label className="form-label">
                                        {task_title_label}
                                        <span className="text-danger ml-1">*</span>
                                    </Label>
                                    <TextField
                                        type="text"
                                        className="form-control"
                                        placeholder={task_title_placeholder}
                                        aria-label={task_title_placeholder}
                                        value={taskTitle}
                                        onChange={e => setTaskTitle(e.target.value)}
                                        nonFormik={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-8 mb-0 mt-3 mb-2">
                                <div className="js-form-message form-group">
                                    <Label className="form-label">
                                        {task_desc_label}
                                        <span className="text-danger ml-1">*</span>
                                    </Label>
                                    <TextField
                                        type="textarea"
                                        className="form-control"
                                        placeholder={task_desc_placeholder}
                                        aria-label={task_desc_placeholder}
                                        value={taskDescription}
                                        onChange={e => setTaskDescription(e.target.value)}
                                        nonFormik={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <Label className="form-label pl-3">
                                {default_expire_label}
                                <span className="text-danger ml-1">*</span>
                            </Label>
                            <div className="row pl-3 pr-3">
                                <div className="col-sm-3 mb-0 mt-3 mb-2">
                                    <div className="js-form-message form-group">
                                        <Label className="form-label">{days_label}</Label>
                                        <TextField
                                            type="number"
                                            className="form-control"
                                            placeholder="0"
                                            aria-label="Expiration Days"
                                            value={days}
                                            onChange={e => setDays(e.target.value)}
                                            nonFormik={true}
                                            min={0}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-3 mb-0 mt-3 mb-2">
                                    <div className="js-form-message form-group">
                                        <Label className="form-label">{hour_label}</Label>
                                        <TextField
                                            type="number"
                                            className="form-control"
                                            placeholder="0"
                                            aria-label="Expiration Hours"
                                            value={hours}
                                            onChange={e => setHours(e.target.value)}
                                            nonFormik={true}
                                            min={1}
                                            max={12}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-3 mb-0 mt-3 mb-2">
                                    <div className="js-form-message form-group">
                                        <Label className="form-label">{min_label}</Label>
                                        <TextField
                                            type="number"
                                            className="form-control"
                                            placeholder="00"
                                            aria-label="Expiration Minutes"
                                            value={mins}
                                            onChange={e => setMins(e.target.value)}
                                            nonFormik={true}
                                            min={0}
                                            max={59}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-3 mb-0 mt-3 mb-2">
                                    <div className="js-form-message form-group">
                                        <Label className="form-label">
                                            {ordinal_label}
                                        </Label>
                                        <SelectField
                                            type="select"
                                            className="form-control p-0 border-0"
                                            classNamePrefix={`react-select ${
                                                ordinal !== '' ? 'success' : 'pristine'
                                            }`}
                                            placeholder="AM"
                                            options={options}
                                            onChange={option => {
                                                setOrdinal(option.value);
                                            }}
                                            aria-label="Expiration Ordinal"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-link text-danger" onClick={onCancel}>
                        {cancel_button_label}
                    </button>
                    <Button color="primary" disabled={disabled} onClick={handleAddition}>
                        {create_button_label}
                    </Button>{' '}
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default AddTaskModal;
