// React
import React, { useState } from 'react';

// Components
import { Loader, TextField } from '../../components';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SimpleBar from 'simplebar-react';

const AddDocToTrxModal = ({
    document,
    toggleModal,
    loading,
    documentModal,
    transactions,
    unfilteredTransactions,
    transactionInputSearch,
    searchSuggestions,
    settingSearchSuggestions,
    selectedTransactions,
    setSelectedTransactions,
    addDocumentToTrx,
    Link,
    routes,
    userData,
    docTitle,
    docStatus
}) => {
    const [input, setInput] = useState('');
    const {
        add_label,
        search_placeholder,
        select_all_label,
        mls_label,
        add_button_label,
        cancel_button_label,
        already_exists_message,
        no_active_message,
        transaction_button_label,
        close_button_label
    } = useLang()['Common']['AddDocToTrxModal'];

    const selectStyle = {
        height: '100px',
        width: '100%',
        borderBottom: '1px solid #e7eaf3',
        backgroundColor: '#377dff',
        color: 'rgba(255,255,255,1)'
    };

    const nonSelectStyle = {
        height: '100px',
        width: '100%',
        borderBottom: '1px solid #e7eaf3',
        backgroundColor: 'transparent',
        color: '#1e2022'
    };

    const checkActive = transactions => {
        const found = transactions.some(trx => {
            return trx.status === 'active';
        });
        return found;
    };

    const activeFound = unfilteredTransactions?.length
        ? checkActive(unfilteredTransactions)
        : false;

    const onCancel = () => {
        toggleModal();
    };

    const onClosed = () => {
        settingSearchSuggestions([]);
        setSelectedTransactions([]);
    };

    const handleInput = input => {
        setInput(input);
        transactionInputSearch(input);
        setSelectedTransactions([]);
    };

    const onSelect = trx => {
        const selected = [...selectedTransactions];
        const index = selected.findIndex(item => item.id === trx.id);
        if (index === -1) {
            setSelectedTransactions([
                ...selected,
                {
                    id: trx.id
                }
            ]);
        } else {
            const newSelected = [...selected];
            newSelected.splice(index, 1);
            setSelectedTransactions(newSelected);
        }
    };

    const onCheck = check => {
        setInput('');
        settingSearchSuggestions([]);
        if (check) {
            setSelectedTransactions(
                transactions.map(trx => {
                    return {
                        id: trx.id
                    };
                })
            );
        } else {
            setSelectedTransactions([]);
        }
    };

    const handleDocumentAdd = () => {
        addDocumentToTrx({
            transactions: [...selectedTransactions],
            userData,
            document: {
                ...document,
                title: docTitle,
                upload_type: docStatus
            }
        });
        toggleModal();
    };

    const transactionList =
        searchSuggestions && searchSuggestions.length ? searchSuggestions : transactions;

    if (!transactions) return null;

    return (
        <div>
            <Modal
                isOpen={documentModal}
                toggle={toggleModal}
                contentClassName="document-upload-modal"
                className="document-upload-modal"
                onClosed={onClosed}
            >
                <ModalHeader toggle={toggleModal}>{add_label}</ModalHeader>
                <ModalBody>
                    {transactions.length ? (
                        <>
                            <div className="row" style={{ width: '100%' }}>
                                <div className="col-12 mt-3 mb-2">
                                    <div className="js-form-message form-group">
                                        <TextField
                                            type="text"
                                            className="form-control"
                                            placeholder={search_placeholder}
                                            aria-label={search_placeholder}
                                            value={input}
                                            onChange={e => handleInput(e.target.value)}
                                            nonFormik={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ width: '100%' }}>
                                <div className="custom-control custom-checkbox mb-2">
                                    <input
                                        type="checkbox"
                                        id="customCheckboxInline1"
                                        name="customCheckboxInline1"
                                        className="custom-control-input"
                                        checked={
                                            selectedTransactions.length ===
                                            transactions.length
                                        }
                                        onChange={e => onCheck(e.target.checked)}
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor="customCheckboxInline1"
                                    >
                                        {select_all_label}
                                    </label>
                                </div>
                            </div>
                            <div className="row" style={{ width: '100%' }}>
                                <div className="col-12 mt-3 mb-2">
                                    <SimpleBar
                                        style={{
                                            height: '300px',
                                            width: 'auto'
                                        }}
                                    >
                                        {transactionList.map(trx => (
                                            <div
                                                className="row"
                                                key={trx.id}
                                                style={
                                                    selectedTransactions.findIndex(
                                                        item => item.id === trx.id
                                                    ) > -1
                                                        ? selectStyle
                                                        : nonSelectStyle
                                                }
                                                onClick={() => onSelect(trx)}
                                            >
                                                <div className="col-12">
                                                    <div className="row p-1">
                                                        <div className="col-12">
                                                            <span
                                                                className={`badge badge-pill badge-${trx.type}`}
                                                            >
                                                                {trx.type}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row p-2">
                                                        <div
                                                            className="col-8"
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                fontSize: '16px'
                                                            }}
                                                        >
                                                            <span>
                                                                {trx.address.address_1}
                                                            </span>
                                                            <span>{`${trx.primary_client.first_name} ${trx.primary_client.last_name}`}</span>
                                                        </div>
                                                        <div
                                                            className="col-4"
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                fontSize: '14px'
                                                            }}
                                                        >
                                                            {trx.mls && (
                                                                <span>
                                                                    {`${mls_label} ${trx.mls}`}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </SimpleBar>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="row" style={{ width: '100%' }}>
                            <div className="col-12 mt-3 mb-2">
                                <span>
                                    {activeFound
                                        ? already_exists_message
                                        : no_active_message}
                                </span>
                            </div>
                        </div>
                    )}
                </ModalBody>
                {transactions.length && activeFound ? (
                    <ModalFooter>
                        {loading && <Loader />}
                        <button className="btn btn-link text-danger" onClick={onCancel}>
                            {cancel_button_label}
                        </button>
                        <Button
                            color="primary"
                            disabled={!selectedTransactions.length}
                            onClick={handleDocumentAdd}
                        >
                            {selectedTransactions.length > 1
                                ? `${add_button_label}s`
                                : add_button_label}
                        </Button>
                    </ModalFooter>
                ) : (
                    <ModalFooter>
                        <button className="btn btn-link text-danger" onClick={onCancel}>
                            {close_button_label}
                        </button>
                        <Button color="primary">
                            <Link
                                style={{ color: 'rgba(255,255,255,1)' }}
                                to={`${routes.AUTHENTICATED}${routes.NEW_TRANSACTION}`}
                            >
                                <span
                                    style={{ color: 'rgba(255,255,255,1)' }}
                                    className="fas fa-plus small mr-2"
                                />
                                {transaction_button_label}
                            </Link>
                        </Button>
                    </ModalFooter>
                )}
            </Modal>
        </div>
    );
};

export default AddDocToTrxModal;
