// React
import React from 'react';

// Components
import {
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

const DropdownMenuComponent = ({ dropItems }) => (
    <UncontrolledButtonDropdown>
        <DropdownToggle
            style={{ background: 'none', border: 'none' }}
            className="contact-frame__toggle"
        >
            <FontAwesomeIcon
                className="pointer"
                style={{ color: '#77838f', fontSize: '1.2rem' }}
                icon={faEllipsisV}
            />
        </DropdownToggle>
        <DropdownMenu className={'animate slideInStayRight'}>
            {dropItems.map(item => (
                <span key={item.name} className="dropdown-link">
                    <DropdownItem
                        className="dropdown-item"
                        color="#000"
                        onClick={item.onClickHandler}
                    >
                        <small className={item.icon} /> {item.name}
                    </DropdownItem>
                </span>
            ))}
        </DropdownMenu>
    </UncontrolledButtonDropdown>
);

export default DropdownMenuComponent;
