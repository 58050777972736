// React
import React, { useState, useRef, useEffect, useMemo } from 'react';

// Components
import { TabNavigation, Activity, Documents, Notes } from '../index';

// Hookes
import { useWindowSize } from '../../hooks/useWindowSize';

// Packages
import { isTablet } from 'react-device-detect';
import { trxStatus } from '../../utils/Constants';

const TransactionInterface = ({
    match,
    transaction,
    avatars,
    allDocs,
    userData,
    addDocumentToTrx,
    removeDocumentFromTrx,
    setDocumentView,
    documentFilter,
    settingTrxDocumentsFilter,
    filteredDocs,
    activity
}) => {
    const [tabContent, setTabContent] = useState('Activity');
    const [maxHeight, setMaxHeight] = useState(0);
    const [tabletDevice, setTabletDevice] = useState(isTablet);
    const interfaceRef = useRef();
    const { windowHeight, windowWidth } = useWindowSize();
    const isTransactionArchived = useMemo(
        () => transaction?.status === trxStatus.archived,
        [transaction]
    );

    useEffect(() => {
        setMaxHeight(windowHeight - interfaceRef.current.offsetTop);
        setTabletDevice(isTablet);
    }, [windowHeight, windowWidth]);

    return (
        <div
            ref={interfaceRef}
            className="col-md-9 col-lg-9 col-xl-6 no-gutters"
            style={{
                display: 'flex',
                flexDirection: tabletDevice ? 'column' : 'row',
                flexGrow: 1
            }}
        >
            <div
                className="col-md-12"
                style={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'column'
                }}
            >
                <div
                    className="card bg-transparent border-0"
                    style={{
                        display: 'flex',
                        flexGrow: 1,
                        flexDirection: 'column'
                    }}
                >
                    <TabNavigation onClick={setTabContent} selectedTab={tabContent} />
                    {tabContent === 'Activity' && (
                        <Activity
                            activity={activity}
                            avatars={avatars}
                            tasks={transaction?.tasks}
                            trxId={transaction?.id}
                            lang={userData?.language}
                            team={[...transaction?.team, ...transaction?.parties]}
                            isTransactionArchived={isTransactionArchived}
                        />
                    )}
                    {tabContent === 'Documents' && (
                        <Documents
                            documents={transaction?.documents}
                            allDocs={allDocs}
                            match={match}
                            containerHeight={maxHeight}
                            userData={userData}
                            trxId={transaction?.id}
                            addDocumentToTrx={addDocumentToTrx}
                            removeDocumentFromTrx={removeDocumentFromTrx}
                            setDocumentView={setDocumentView}
                            documentFilter={documentFilter}
                            settingTrxDocumentsFilter={settingTrxDocumentsFilter}
                            filteredDocs={filteredDocs}
                            tabContent={tabContent}
                            lang={userData?.language}
                            isTransactionArchived={isTransactionArchived}
                        />
                    )}
                    {tabContent === 'Notes' && (
                        <Notes
                            notes={transaction?.notes}
                            containerHeight={maxHeight}
                            trxId={transaction?.id}
                            isTransactionArchived={isTransactionArchived}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default TransactionInterface;
