// React
import React from 'react';

// Components
import { TextField } from '../../components';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import { Label } from 'reactstrap';

const ListingFlowStep3 = ({
    setStep,
    isSubmitting,
    checkError,
    errors,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    checkTouched,
    touched,
    initialTasks,
    selectedTasks,
    setSelectedTasks
}) => {
    const {
        commission_label,
        commission_placeholder,
        split_label,
        split_placeholder,
        fee_label,
        fee_placeholder,
        forms_label,
        tasks_label,
        back_button_label,
        done_button_label
    } = useLang()['NewTransaction']['ListingFlowStep3'];

    const handleTaskSelection = task => {
        const tasks = [...selectedTasks];
        const index = tasks.findIndex(item => item.title === task.title);
        if (index === -1) {
            setSelectedTasks([...tasks, task]);
        } else {
            const newTasks = [...tasks];
            newTasks.splice(index, 1);
            setSelectedTasks(newTasks);
        }
    };

    // const handleDocSelection = (task) => {
    //     let tasks = [...selectedTasks];
    //     const index = tasks.findIndex((item) => item.title === task.title);
    //     if (index === -1) {
    //         setSelectedTasks([...tasks, task]);
    //     } else {
    //         let newTasks = [...tasks];
    //         newTasks.splice(index, 1);
    //         setSelectedTasks(newTasks);
    //     }
    // };

    return (
        <div
            id="contentStepForm"
            className="card-body p-5 w-lg-75 mx-lg-auto"
            style={{
                animation: 'fadeIn .5s'
            }}
        >
            <div className="row">
                <div className="col-lg-4 mb-3">
                    <div className="form-group">
                        <div className="js-focus-state">
                            <Label className="form-label">
                                {commission_label}
                                <span className="text-danger ml-1">*</span>
                            </Label>
                            <div
                                className="input-group"
                                style={{
                                    display: 'flex',
                                    flexWrap: 'nowrap'
                                }}
                            >
                                <div
                                    className="input-group-prepend"
                                    style={{
                                        maxHeight: 50,
                                        cursor: 'pointer'
                                    }}
                                    name="trxMeta.commissionPercent"
                                    id="trxMeta.commissionPercent"
                                    aria-label={commission_label}
                                    error={checkError(errors, null, 'commissionPercent')}
                                    value={values.trxMeta.commissionPercent}
                                    onBlur={() => {
                                        handleBlur('commissionPercent');
                                        setFieldTouched(
                                            `trxMeta[commissionPercent]`,
                                            true
                                        );
                                    }}
                                    onClick={() => {
                                        handleChange(`trxMeta[commissionPercent]`);
                                        setFieldValue(
                                            `trxMeta[commissionPercent]`,
                                            !values.trxMeta.commissionPercent
                                        );
                                    }}
                                >
                                    <span
                                        className="input-group-text"
                                        id="listingAddressLabel"
                                        style={{
                                            borderColor:
                                                !!checkError(
                                                    errors,
                                                    null,
                                                    `commission`
                                                ) &&
                                                checkTouched(touched, null, `commission`)
                                                    ? '#de4437'
                                                    : values.trxMeta.commission
                                                    ? 'rgba(0, 201, 167, 0.5)'
                                                    : ''
                                        }}
                                    >
                                        <span
                                            className={`commissionTypeIcon fas ${
                                                values.trxMeta.commissionPercent
                                                    ? 'fa-percentage'
                                                    : 'fa-dollar-sign'
                                            }`}
                                        />
                                    </span>
                                </div>
                                <TextField
                                    type="number"
                                    myStyle={{
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        flexGrow: 1
                                    }}
                                    className="form-control"
                                    name="trxMeta.commission"
                                    id="trxMeta.commission"
                                    placeholder={commission_placeholder}
                                    aria-label={commission_label}
                                    error={checkError(errors, null, 'commission')}
                                    value={values.trxMeta.commission}
                                    onBlur={() => {
                                        handleBlur('commission');
                                        setFieldTouched(`trxMeta[commission]`, true);
                                    }}
                                    onChange={e => {
                                        handleChange(e);
                                    }}
                                    invalid={
                                        !!checkError(errors, null, 'commission') &&
                                        checkTouched(touched, null, 'commission')
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 mb-3">
                    <div className="form-group">
                        <div className="js-focus-state">
                            <Label className="form-label">
                                {split_label}
                                <span className="text-danger ml-1">*</span>
                            </Label>
                            <div
                                className="input-group"
                                style={{
                                    display: 'flex',
                                    flexWrap: 'nowrap'
                                }}
                            >
                                <div
                                    className="input-group-prepend"
                                    style={{
                                        maxHeight: 50,
                                        cursor: 'pointer'
                                    }}
                                    name="trxMeta.splitPercent"
                                    id="trxMeta.splitPercent"
                                    aria-label={split_label}
                                    error={checkError(errors, null, 'splitPercent')}
                                    value={values.trxMeta.splitPercent}
                                    onBlur={() => {
                                        handleBlur('splitPercent');
                                        setFieldTouched(`trxMeta[splitPercent]`, true);
                                    }}
                                    onClick={() => {
                                        handleChange(`trxMeta[splitPercent]`);
                                        setFieldValue(
                                            `trxMeta[splitPercent]`,
                                            !values.trxMeta.splitPercent
                                        );
                                    }}
                                >
                                    <span
                                        className="input-group-text"
                                        id="listingAddressLabel"
                                        style={{
                                            borderColor:
                                                !!checkError(errors, null, `split`) &&
                                                checkTouched(touched, null, `split`)
                                                    ? '#de4437'
                                                    : values.trxMeta.split
                                                    ? 'rgba(0, 201, 167, 0.5)'
                                                    : ''
                                        }}
                                    >
                                        <span
                                            className={`commissionTypeIcon fas ${
                                                values.trxMeta.splitPercent
                                                    ? 'fa-percentage'
                                                    : 'fa-dollar-sign'
                                            }`}
                                        />
                                    </span>
                                </div>
                                <TextField
                                    type="number"
                                    myStyle={{
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        flexGrow: 1
                                    }}
                                    className="form-control"
                                    name="trxMeta.split"
                                    id="trxMeta.split"
                                    placeholder={split_placeholder}
                                    aria-label={split_label}
                                    error={checkError(errors, null, 'split')}
                                    value={values.trxMeta.split}
                                    onBlur={() => {
                                        handleBlur('split');
                                        setFieldTouched(`trxMeta[split]`, true);
                                    }}
                                    onChange={e => {
                                        handleChange(e);
                                    }}
                                    invalid={
                                        !!checkError(errors, null, 'split') &&
                                        checkTouched(touched, null, 'split')
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 mb-3">
                    <div className="form-group">
                        <div className="js-focus-state">
                            <Label className="form-label">
                                {fee_label}
                                <span className="text-danger ml-1">*</span>
                            </Label>
                            <div
                                className="input-group"
                                style={{
                                    display: 'flex',
                                    flexWrap: 'nowrap'
                                }}
                            >
                                <div
                                    className="input-group-prepend"
                                    style={{
                                        maxHeight: 50,
                                        cursor: 'pointer'
                                    }}
                                    name="trxMeta.feePercent"
                                    id="trxMeta.feePercent"
                                    aria-label={fee_label}
                                    error={checkError(errors, null, 'feePercent')}
                                    value={values.trxMeta.feePercent}
                                    onBlur={() => {
                                        handleBlur('feePercent');
                                        setFieldTouched(`trxMeta[feePercent]`, true);
                                    }}
                                    onClick={() => {
                                        handleChange(`trxMeta[feePercent]`);
                                        setFieldValue(
                                            `trxMeta[feePercent]`,
                                            !values.trxMeta.feePercent
                                        );
                                    }}
                                >
                                    <span
                                        className="input-group-text"
                                        id="listingAddressLabel"
                                        style={{
                                            borderColor:
                                                !!checkError(errors, null, `fee`) &&
                                                checkTouched(touched, null, `fee`)
                                                    ? '#de4437'
                                                    : values.trxMeta.fee
                                                    ? 'rgba(0, 201, 167, 0.5)'
                                                    : ''
                                        }}
                                    >
                                        <span
                                            className={`commissionTypeIcon fas ${
                                                values.trxMeta.feePercent
                                                    ? 'fa-percentage'
                                                    : 'fa-dollar-sign'
                                            }`}
                                        />
                                    </span>
                                </div>
                                <TextField
                                    type="number"
                                    myStyle={{
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        flexGrow: 1
                                    }}
                                    className="form-control"
                                    name="trxMeta.fee"
                                    id="trxMeta.fee"
                                    placeholder={fee_placeholder}
                                    aria-label={fee_label}
                                    error={checkError(errors, null, 'fee')}
                                    value={values.trxMeta.fee}
                                    onBlur={() => {
                                        handleBlur('fee');
                                        setFieldTouched(`trxMeta[fee]`, true);
                                    }}
                                    onChange={e => {
                                        handleChange(e);
                                    }}
                                    invalid={
                                        !!checkError(errors, null, 'fee') &&
                                        checkTouched(touched, null, 'fee')
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-6 mb-6">
                    <div className="border-bottom pb-3 mb-5">
                        <h2 className="h6 text-secondary mb-0">{forms_label}</h2>
                    </div>
                    {/* <div className="custom-control custom-checkbox mb-4">
                        <input
                            type="checkbox"
                            id="customCheckboxInline1"
                            name="customCheckboxInline1"
                            className="custom-control-input"
                            onClick={(e) => console.log(e.target.checked)}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="customCheckboxInline1"
                        >
                            SFR Listing Pack (FAR)
                        </label>
                    </div>
                    <div className="custom-control custom-checkbox mb-4">
                        <input
                            type="checkbox"
                            id="customCheckboxInline2"
                            name="customCheckboxInline2"
                            className="custom-control-input"
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="customCheckboxInline2"
                        >
                            SFR Listing Pack (NABOR)
                        </label>
                    </div>
                    <div className="custom-control custom-checkbox mb-4">
                        <input
                            type="checkbox"
                            id="customCheckboxInline3"
                            name="customCheckboxInline3"
                            className="custom-control-input"
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="customCheckboxInline3"
                        >
                            Sellers Disclosure
                        </label>
                    </div>
                    <div className="custom-control custom-checkbox mb-4">
                        <input
                            type="checkbox"
                            id="customCheckboxInline4"
                            name="customCheckboxInline4"
                            className="custom-control-input"
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="customCheckboxInline4"
                        >
                            Short Sale Addendum
                        </label>
                    </div>
                    <div className="custom-control custom-checkbox mb-4">
                        <input
                            type="checkbox"
                            id="customCheckboxInline5"
                            name="customCheckboxInline5"
                            className="custom-control-input"
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="customCheckboxInline5"
                        >
                            Vacant Land Listing
                        </label>
                    </div> */}
                </div>
                <div className="col-6 mb-6">
                    <div className="border-bottom pb-3 mb-5">
                        <h2 className="h6 text-secondary mb-0">{tasks_label}</h2>
                    </div>
                    {initialTasks &&
                        initialTasks.length &&
                        initialTasks.map((task, index) => (
                            <div
                                className="custom-control custom-checkbox mb-4"
                                key={task.title}
                            >
                                <input
                                    type="checkbox"
                                    id={`customCheckboxInline${index}`}
                                    name={`customCheckboxInline${index}`}
                                    className="custom-control-input"
                                    onClick={() => {
                                        handleTaskSelection(task);
                                    }}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor={`customCheckboxInline${index}`}
                                >
                                    {task.title}
                                </label>
                            </div>
                        ))}
                </div>
            </div>
            <div className="d-flex justify-content-end">
                <button
                    className="btn btn-sm btn-soft-secondary transition-3d-hover mr-1"
                    onClick={() => setStep(2)}
                >
                    {back_button_label}
                </button>
                <button
                    type="submit"
                    className="btn btn-sm btn-primary transition-3d-hover"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                >
                    {done_button_label}
                </button>
            </div>
        </div>
    );
};

export default ListingFlowStep3;
