import { SET_MODAL_TYPE, MODAL_CANCEL } from '../actions/types';

const INITIAL_STATE = {
    modalDialogType: null
};

const modalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_MODAL_TYPE:
            return {
                ...state,
                modalDialogType: action.payload
            };

        case MODAL_CANCEL:
            return {
                ...state,
                modalDialogType: null
            };
        default:
            return state;
    }
};

export default modalReducer;
