// React
import React from 'react';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import { Field } from 'formik';
import { FormFeedback } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';

const DropdownIndicator = () => {
    return <span className="select-icon" />;
};

const IndicatorSeparator = () => {
    return null;
};

const CreatableSelectField = ({
    required,
    type,
    className,
    name,
    id,
    placeholder,
    error,
    value,
    onBlur,
    onChange,
    options,
    touched,
    classNamePrefix,
    onCreateOption
}) => {
    const { add_custom_label } = useLang()['Common']['CreatableSelectField'];
    return (
        <>
            <Field
                autoComplete="off"
                required={required}
                type={type}
                className={className}
                classNamePrefix={classNamePrefix}
                name={name}
                id={id}
                placeholder={placeholder}
                error={error}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                onCreateOption={onCreateOption}
                options={options}
                formatCreateLabel={value => (
                    <span>{`${add_custom_label} "${value}"`}</span>
                )}
                components={{
                    DropdownIndicator,
                    IndicatorSeparator
                }}
                as={CreatableSelect}
            />

            <FormFeedback style={{ display: error && touched ? 'block' : 'none' }}>
                {error}
            </FormFeedback>
        </>
    );
};

export default CreatableSelectField;
