import {
    SET_APP_LANGUAGE_TYPE,
    SET_APP_LANGUAGE_TYPE_SUCCESS,
    SET_APP_LANGUAGE_TYPE_FAILURE,
    SET_LANGUAGE_BY_LOCATION
} from './types';

export const setLanguageType = origin => {
    return {
        type: SET_APP_LANGUAGE_TYPE,
        payload: origin
    };
};

export const setLangageTypeSuccess = () => {
    return {
        type: SET_APP_LANGUAGE_TYPE_SUCCESS,
        payload: origin
    };
};

export const setLangageTypeFailure = () => {
    return {
        type: SET_APP_LANGUAGE_TYPE_FAILURE,
        payload: origin
    };
};

export const setLanguageByLocation = origin => {
    return {
        type: SET_LANGUAGE_BY_LOCATION,
        payload: origin
    };
};
