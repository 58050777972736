export const ALERTS = '/alerts';
export const APP_INTEGRATIONS = '/app-integrations';
export const AUTHENTICATED = '/user';
export const AUTH_SERVICES = '/auth-service';
export const BILLING = '/billing';
export const BUYER = '/buyer';
export const CONTACTS = '/contacts';
export const CONTACT_DETAIL = '/contact-detail';
export const DASHBOARD = '/dashboard';
export const DOCUMENTS = '/documents';
export const LIBRARY = '/library';
export const LANDING = '/';
export const LISTING = '/listing';
export const LOGIN = '/login';
export const NEW_TRANSACTION = '/new-transaction';
export const PERSONAL_INFO = '/personal-info';
export const NEW_DOCUMENT = '/new-document';
export const PITCH = '/pitch';
export const PREFERENCES = '/preferences';
export const PROFILE = '/profile';
export const ORGANIZATION_INFO = '/organization-info';
export const RESET_PASSWORD = '/reset-password';
export const SECURITY_SETTINGS = '/security-settings';
export const SETTINGS = '/settings';
export const TEAM = '/team';
export const TRANSACTION_DETAIL = '/transaction-detail';
export const TRANSACTIONS = '/transactions';
export const EDIT_TRANSACTION = '/edit-transaction';
export const VIEW_DOCUMENT = '/view-document';
