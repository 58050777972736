import { ADD_INTERNAL_USER, ADD_INTERNAL_USER_SUCCESS } from '../actions/types';

const INITIAL_STATE = {
    loading: null
};

const superAdminReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADD_INTERNAL_USER:
            return {
                ...state,
                loading: true
            };
        case ADD_INTERNAL_USER_SUCCESS:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};

export default superAdminReducer;
