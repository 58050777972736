import { ADD_INTERNAL_USER, ADD_INTERNAL_USER_SUCCESS } from './types';

export const addInternalUser = user => {
    return {
        type: ADD_INTERNAL_USER,
        payload: user
    };
};

export const addInternalUserSuccess = () => {
    return {
        type: ADD_INTERNAL_USER_SUCCESS
    };
};
