import DateTime from 'luxon/src/datetime.js';
import { timeStampJs } from '../config/Firebase';

///////////////// Activity Thread /////////////////

const dateOrdinal = day => {
    return 31 === day || 21 === day || 1 === day
        ? 'st'
        : 22 === day || 2 === day
        ? 'nd'
        : 23 === day || 3 === day
        ? 'rd'
        : 'th';
};

const formatThreadDate = (timeStamp, lang) => {
    if (lang === 'en') {
        return `${DateTime.fromSeconds(timeStamp).toFormat('cccc, LLL d')}${dateOrdinal(
            DateTime.fromSeconds(timeStamp).day
        )}`;
    } else if (lang === 'es') {
        return `${DateTime.fromSeconds(timeStamp)
            .setLocale('es-ES')
            .toFormat('cccc, LLL d')}`;
    } else {
        return;
    }
};

export const formatThreadTime = timeStamp => {
    return DateTime.fromSeconds(timeStamp).toFormat('t');
};

export const formatActivity = (data, lang) => {
    const formattedEvents = [];
    const activity = [...data];
    const sortedEvents = activity.sort((x, y) => {
        return y.created_at.seconds - x.created_at.seconds;
    });
    sortedEvents.forEach(activity => {
        const activityDay = formatThreadDate(activity.created_at.seconds, lang);

        const found = formattedEvents.findIndex(event => event.day === activityDay);

        if (found >= 0) {
            formattedEvents[found].events.push(activity);
        } else {
            formattedEvents.push({ day: activityDay, events: [{ ...activity }] });
        }
    });
    return formattedEvents;
};

///////////////////////////////////////////////////

/////////////////// Documents /////////////////////

export const formatDocumentDate = (timeStamp, lang) => {
    if (lang === 'en') {
        return `${DateTime.fromSeconds(timeStamp).toFormat('LLL d')}${dateOrdinal(
            DateTime.fromSeconds(timeStamp).day
        )}, ${DateTime.fromSeconds(timeStamp).toFormat('yyyy')}`;
    } else if (lang === 'es') {
        return `${DateTime.fromSeconds(timeStamp)
            .setLocale('es-ES')
            .toFormat('LLL d')}, ${DateTime.fromSeconds(timeStamp).toFormat('yyyy')}`;
    } else {
        return;
    }
};

///////////////////////////////////////////////////

////////////////////// Tasks //////////////////////

export const formatTaskDate = timeStamp => {
    return `${DateTime.fromSeconds(timeStamp).toFormat('LLL d')}${dateOrdinal(
        DateTime.fromSeconds(timeStamp).day
    )}, ${DateTime.fromSeconds(timeStamp).toFormat('h:mm')}${DateTime.fromSeconds(
        timeStamp
    )
        .toFormat('a')
        .toLocaleLowerCase()}
        `;
};

export const setTaskStatus = task => {
    const date = DateTime.fromSeconds(task.date_time.seconds);
    const diff = date.diffNow().as('hours');
    if (task.status === 'complete') return 'complete';
    if (diff < 0) {
        return 'danger';
    } else if (diff > 24) {
        return 'success';
    } else {
        return 'warning';
    }
};

export const tsFromJsDate = date => {
    return timeStampJs.fromDate(date);
};

///////////////////////////////////////////////////

////////////// Transaction List/Grid //////////////

export const formatCardDate = timeStamp => {
    return `${DateTime.fromSeconds(timeStamp).toFormat('LLL d')}${dateOrdinal(
        DateTime.fromSeconds(timeStamp).day
    )}`;
};

///////////////////////////////////////////////////

/////////////// Load FB Avatar Image //////////////

// Will revisit this to make sure we are doing the right thing for efficeincy
// export const setUserAvatar = (id) => {
//     const avatarRef = storage.ref().child(`users/${id}/avatar.jpg`);
//     avatarRef.getDownloadURL();
// };

///////////////////////////////////////////////////

//////////////////// Transactions //////////////////

export const getSources = (userSources, orgSources) => {
    if (orgSources && userSources) {
        return [...orgSources, ...userSources];
    } else if (orgSources) {
        return [...orgSources];
    } else if (userSources) {
        return [...userSources];
    } else {
        return [];
    }
};

export const daysOnMls = seconds => {
    const date = DateTime.fromSeconds(seconds);
    const days = Math.floor(Math.abs(date.diffNow().as('days')));
    return days;
};

export const daysTillClosing = seconds => {
    const date = DateTime.fromSeconds(seconds);
    const days = Math.ceil(Math.abs(date.diffNow().as('days')));
    return days;
};

///////////////////////////////////////////////////

// let parties = formData.parties.reduce((partyObjects, party) => {
//     if (party.email !== '')
//         partyObjects.push({ email: party.email.toLowerCase(), id: null });
//     return partyObjects;
// }, []);
