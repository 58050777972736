import React, { createContext, useContext } from 'react';
import { connect } from 'react-redux';
import library from '../lang/library';

const LangContext = createContext();

const mapStateToProps = ({ lang }) => {
    const { origin } = lang;
    return { origin };
};

const LangProvider = connect(mapStateToProps)(({ origin, ...props }) => (
    <LangContext.Provider value={library[origin]} {...props} />
));

const useLang = () => useContext(LangContext);

export { LangProvider, useLang };
