// React
import React from 'react';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import { Link, useHistory } from 'react-router-dom';

// Router
import * as routes from '../../router/config/routes';

// Utils
import { formatDocumentDate } from '../../utils/Helpers';

// Components
import DropdownMenuComponent from '../common/DropdownMenu';

const DocumentRow = ({ document, lang, removeUserDocument }) => {
    const { title, name, upload_type, uploaded_at, id, url } = document;
    const history = useHistory();
    const path = title.trim();
    const type = history.location.pathname;
    const endPath = path.replace(/ /g, '_');
    const {
        buyer_label,
        seller_label,
        other_label,
        button_label,
        file_name_label,
        upload_date_label
    } = useLang()['Documents']['DocumentRow'];
    const { delete_label, edit_label } = useLang()['Common']['Dropdown'];

    const dropItems = [
        {
            name: delete_label,
            icon: 'fas fa-archive dropdown-item-icon',
            onClickHandler: () => removeUserDocument({ document, type })
        },
        {
            name: edit_label,
            icon: 'fas fa-edit dropdown-item-icon',
            onClickHandler: () =>
                history.push({
                    pathname: `${routes.AUTHENTICATED}${routes.VIEW_DOCUMENT}/${endPath}`,
                    state: { title, upload_type, id, url }
                })
        }
    ];

    return (
        <div className="card">
            <div className="card-body p-3">
                <div className="d-sm-flex justify-content-between align-items-center">
                    <div className="mr-3 mb-2 mb-sm-0 card-col-0">
                        <span className={`badge badge-${upload_type}`}>
                            {upload_type !== 'seller'
                                ? upload_type === 'buyer'
                                    ? buyer_label
                                    : other_label
                                : seller_label}
                        </span>
                    </div>
                    <div className="mr-3 mb-2 mb-sm-0 card-col-10">
                        <h2 className="h6 mb-0">{title}</h2>
                    </div>
                    <div className="mr-3 mb-3 mb-sm-0 card-col-20">
                        <Link
                            className="font-size-1"
                            href="transaction-detail.html"
                            to={{
                                pathname: `${routes.AUTHENTICATED}${routes.VIEW_DOCUMENT}/${endPath}`,
                                state: { title, name, upload_type, id, url }
                            }}
                        >
                            {`${file_name_label}: ${name}`}
                        </Link>
                        <small className="d-block text-muted">{`${upload_date_label}:  ${formatDocumentDate(
                            uploaded_at.seconds,
                            lang
                        )}`}</small>
                    </div>
                    <div className="mr-3 card-col-2">
                        <Link
                            to={{
                                pathname: `${routes.AUTHENTICATED}${routes.VIEW_DOCUMENT}/${endPath}`,
                                state: { title, name, upload_type, id, url }
                            }}
                            className={
                                'btn btn-block btn-soft-primary transition-3d-hover ml-auto'
                            }
                        >
                            {button_label}
                            <span className="fas fa-angle-right ml-1" />
                        </Link>
                    </div>
                    <DropdownMenuComponent dropItems={dropItems} />
                </div>
            </div>
        </div>
    );
};

export default DocumentRow;
