// React
import React from 'react';

// Components
import { Loader } from '../../components';

// Context
import { useLang } from '../../context/LangContext';

//Packages
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const RemoveTaskModal = ({
    removeModal,
    toggleModal,
    selectedTask,
    removeTask,
    loading
}) => {
    const {
        remove_task_label,
        assurance_label,
        delete_button_label,
        cancel_button_label
    } = useLang()['Common']['RemoveTaskModal'];
    const onCancel = () => {
        toggleModal({ action: 'remove', reset: false, cancel: true, task: null });
    };

    return (
        <div>
            <Modal isOpen={removeModal} toggle={toggleModal} className="add-task-modal">
                <ModalHeader toggle={onCancel}>{remove_task_label}</ModalHeader>
                <ModalBody>
                    <div className="column">
                        <div className="row">
                            <div className="col-sm-8 mb-0 mt-0 mb-5">
                                <span className="h5 text-secondary">
                                    {assurance_label}
                                </span>
                            </div>
                        </div>
                        <div className="row pl-3">
                            <span className="h5 text-dark">{selectedTask?.title}</span>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {loading && <Loader />}
                    <button className="btn btn-link text-danger" onClick={onCancel}>
                        {cancel_button_label}
                    </button>
                    <Button color="primary" onClick={removeTask}>
                        {delete_button_label}
                    </Button>{' '}
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default RemoveTaskModal;
