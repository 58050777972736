import React from 'react';
import { NavLink } from 'react-router-dom';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import { connect } from 'react-redux';

// Router
import * as routes from '../../router/config/routes';

const SettingsMenu = ({ match, userData }) => {
    const {
        personal_info_link,
        organization_info_link,
        team_link,
        preferences_link,
        security_settings_link,
        billing_link,
        app_integratons_link
    } = useLang()['Settings']['SettingsMenu'];

    const settingsLinks = [
        {
            name: personal_info_link,
            route: `${match.url}${routes.PERSONAL_INFO}`,
            icon: `id-card`
        },
        {
            name: organization_info_link,
            route: `${match.url}${routes.ORGANIZATION_INFO}`,
            icon: `building`
        },
        {
            name: team_link,
            route: `${match.url}${routes.TEAM}`,
            icon: `user-friends`
        },
        {
            name: preferences_link,
            route: `${match.url}${routes.PREFERENCES}`,
            icon: `sliders-h`
        },
        {
            name: security_settings_link,
            route: `${match.url}${routes.SECURITY_SETTINGS}`,
            icon: `fingerprint`
        },
        {
            name: billing_link,
            route: `${match.url}${routes.BILLING}`,
            icon: `file-invoice-dollar`
        },
        {
            name: app_integratons_link,
            route: `${match.url}${routes.APP_INTEGRATIONS}`,
            icon: `puzzle-piece`
        }
    ];

    return (
        <div className="d-none d-xl-block col-3 pr-3">
            <div className="card" style={{ minHeight: '70vh', height: '100%' }}>
                <div className="card-body">
                    <div className="u-xl-avatar mb-3 mt-4 mx-auto">
                        {userData?.user_avatar && (
                            <img
                                src={userData.user_avatar}
                                className="img-fluid rounded-circle"
                                alt={`${userData?.first_name} ${userData?.last_name}`}
                            />
                        )}
                    </div>
                    <div className="text-center mb-4">
                        <h1 className="h5 font-weight-medium mb-0">{`${userData?.first_name} ${userData?.last_name}`}</h1>
                        <p>{`${userData?.email}`}</p>
                    </div>
                    <hr className="mr-3 ml-3" />
                    <ul
                        id="settings-menu"
                        className="list-unstyled u-sidebar--account__list mb-5"
                    >
                        {settingsLinks.map(item => (
                            <li className="u-sidebar--account__list-item" key={item.name}>
                                <NavLink
                                    to={item.route}
                                    className="nav-link u-header__nav-link"
                                    activeClassName="active"
                                >
                                    <span className={`fas fa-${item.icon} mr-2`} />
                                    {item.name}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ user }) => {
    const { userData } = user;
    return { userData };
};

export default connect(mapStateToProps)(SettingsMenu);
