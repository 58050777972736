import { all, fork, takeLatest, put, call, select } from 'redux-saga/effects';

import { SET_APP_LANGUAGE_TYPE } from '../actions/types';

import { setLangageTypeSuccess, setLangageTypeFailure } from '../actions/Lang';

import { db } from '../../config/Firebase';

import * as selectors from './Selectors';

// Loggers
import { log } from '../../utils/Loggers';

const users = db.collection('users');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Update User Langage /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const setUserLanguageRequest = async ({ origin, userData }) => {
    const ref = users.doc(userData.id);
    return new Promise((resolve, reject) => {
        ref.update({
            language: origin
        })
            .then(() => {
                resolve({ languageSet: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* setUserLanguage({ payload }) {
    const { origin } = payload;
    const userData = yield select(selectors._userData);
    const { languageSet, error } = yield call(() =>
        setUserLanguageRequest({ origin, userData })
    );
    if (languageSet) {
        yield put(setLangageTypeSuccess());
    } else {
        // Error Handling for sentry with put and maybe UI message
        yield put(setLangageTypeFailure());
        log('Language Error: setting user language', {
            error,
            origin
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* settingUserLanguage() {
    yield takeLatest(SET_APP_LANGUAGE_TYPE, setUserLanguage);
}

export default function* rootSaga() {
    yield all([fork(settingUserLanguage)]);
}
