// React
import React, { useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';

// Consts
import { confirmationDialogTypes } from '../../utils/Constants';

// Packages
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { connect } from 'react-redux';

// Redux
import { confirmModal, cancelModal } from '../../store/actions/Modal';

// Components
import DatePickerWithIcon from './DatePickerWithIcon';

const SwalWithReactContent = withReactContent(Swal);

const dialogStyles = {
    warning: {
        icon: 'warning',
        iconColor: '#00C9A6',
        confirmButtonColor: '#377dff',
        cancelButtonColor: '#de4437'
    },
    success: {
        icon: 'success',
        iconColor: '#00C9A6',
        confirmButtonColor: '#377dff'
    },
    failed: {
        icon: 'error',
        iconColor: '#de4437',
        confirmButtonColor: '#377dff'
    },
    question: {
        icon: 'question',
        iconColor: '#00C9A6',
        confirmButtonColor: '#377dff',
        cancelButtonColor: '#de4437'
    }
};

const ConfirmationDialog = ({
    titleText,
    contentText,
    confirmButtonText,
    cancelButtonText,
    loadingTitleText,
    loadingContentText,
    successTitleText,
    successContentText,
    failedTitleText,
    failedContentText,
    modalDialogType,
    confirmModal,
    cancelModal,
    confirmHandler = () => {},
    successHandler = () => {},
    inputPlaceholder,
    invalidInput
}) => {
    const alertContent = useMemo(
        () => ({
            confirm: {
                title: titleText,
                text: contentText,
                confirmButtonText: confirmButtonText,
                cancelButtonText: cancelButtonText
            },
            loading: {
                title: loadingTitleText,
                text: loadingContentText
            },
            success: {
                title: successTitleText,
                text: successContentText
            },
            failed: {
                title: failedTitleText,
                text: failedContentText
            },
            withInput: {
                title: contentText,
                confirmButtonText: confirmButtonText,
                cancelButtonText: cancelButtonText
            }
        }),
        [
            confirmButtonText,
            contentText,
            failedContentText,
            failedTitleText,
            loadingContentText,
            loadingTitleText,
            successContentText,
            successTitleText,
            titleText,
            cancelButtonText
        ]
    );

    useEffect(() => {
        switch (modalDialogType) {
            case confirmationDialogTypes.delete:
            case confirmationDialogTypes.archiveTrx:
            case confirmationDialogTypes.activateTrx:
                Swal.fire({
                    showCancelButton: true,
                    ...alertContent.confirm,
                    ...dialogStyles.warning
                }).then(result => {
                    if (result.isConfirmed) {
                        confirmModal();
                    } else {
                        cancelModal();
                    }
                });
                break;

            case confirmationDialogTypes.loading:
                Swal.fire({
                    ...alertContent.loading,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });
                break;

            case confirmationDialogTypes.success:
                Swal.fire({
                    willClose: cancelModal,
                    ...alertContent.success,
                    ...dialogStyles.success
                }).then(() => {
                    successHandler();
                });
                break;

            case confirmationDialogTypes.failed:
                Swal.fire({
                    didClose: cancelModal,
                    ...alertContent.failed,
                    ...dialogStyles.failed
                });
                break;

            case confirmationDialogTypes.cancel:
                Swal.fire({
                    willClose: cancelModal,
                    showCancelButton: true,
                    reverseButtons: true,
                    focusCancel: true,
                    ...alertContent.confirm,
                    ...dialogStyles.warning
                }).then(result => {
                    if (result.isConfirmed) {
                        confirmHandler();
                    }
                });
                break;

            case confirmationDialogTypes.closeTrx:
                SwalWithReactContent.fire({
                    html: (
                        <DatePickerWithIcon
                            nonFormik
                            className="js-range-datepicker form-control bg-white rounded-right flatpickr-input"
                            myStyle={{
                                flexGrow: 1
                            }}
                            id="swal-date-input"
                            placeholder={inputPlaceholder}
                            options={{
                                disableMobile: 'true',
                                dateFormat: 'm-d-Y'
                            }}
                        />
                    ),
                    showCancelButton: true,
                    ...alertContent.withInput,
                    ...dialogStyles.question,

                    preConfirm: () => {
                        const value = document.getElementById('swal-date-input').value;
                        if (!value) {
                            SwalWithReactContent.showValidationMessage(invalidInput);
                        }
                        return value;
                    }
                }).then(result => {
                    if (result.isConfirmed) {
                        confirmModal(result.value);
                    } else {
                        cancelModal();
                    }
                });
                break;

            default:
                return null;
        }
    }, [
        modalDialogType,
        cancelModal,
        confirmHandler,
        confirmModal,
        alertContent,
        successHandler,
        inputPlaceholder,
        invalidInput
    ]);

    return null;
};

const mapStateToProps = ({ modal }) => {
    const { modalDialogType } = modal;
    return {
        modalDialogType
    };
};

export default withRouter(
    connect(mapStateToProps, { confirmModal, cancelModal })(ConfirmationDialog)
);
