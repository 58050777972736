// React
import React, { useEffect } from 'react';

// Context
import { useLang } from '../../context/LangContext';

// Router
import * as routes from '../../router/config/routes';
import { useHistory } from 'react-router-dom';

const TransactionRow = ({
    id,
    address: { address_1, address_2, city },
    primary_client: { first_name, last_name },
    type,
    mls,
    gettingTransactionDetails,
    loading,
    transactionDetails,
    detailsFlag
}) => {
    const history = useHistory();

    const { buyer_label, seller_label, button_label } =
        useLang()['Transactions']['TransactionRow'];

    const handleDetailsRequest = trxId => {
        gettingTransactionDetails({ trxId, detailsFlag: true });
    };

    useEffect(() => {
        if (!loading && transactionDetails && detailsFlag) {
            if (transactionDetails.id === id) {
                history.push({
                    pathname: `${routes.AUTHENTICATED}${routes.TRANSACTION_DETAIL}/${id}`,
                    state: { id }
                });
            }
        }
    }, [loading, transactionDetails, detailsFlag, id, history]);

    return (
        <div className="card">
            <div className="card-body p-3">
                <div className="d-sm-flex justify-content-between align-items-center">
                    <div className="mr-3 mb-2 mb-sm-0 card-col-0">
                        <span className={`badge badge-${type} w-50`}>
                            {type === 'buyer' ? buyer_label : seller_label}
                        </span>
                    </div>
                    <div className="mr-3 mb-2 mb-sm-0 card-col-10">
                        <h2 className="h6 mb-0">{`${first_name} ${last_name}`}</h2>
                    </div>
                    <div
                        className="mr-3 mb-3 mb-sm-0 card-col-20 text-primary"
                        style={{ cursor: 'pointer' }}
                    >
                        <div
                            className="font-size-1"
                            onClick={() => handleDetailsRequest(id)}
                        >
                            {`${address_1}
                            ${address_2 ? `#${address_2}` : ''}, ${city}`}
                        </div>
                        <small className="d-block text-muted">mls# {mls}</small>
                    </div>
                    <div className="mr-3 card-col-2">
                        <div
                            className={
                                'btn btn-block btn-soft-primary transition-3d-hover ml-auto'
                            }
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleDetailsRequest(id)}
                        >
                            {button_label}
                            <span className="fas fa-angle-right ml-1" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionRow;
