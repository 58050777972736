import React from 'react';

const Team = () => {
    return (
        <>
            <div className="card-header">
                <h1 className="h5 font-weight-medium mb-0">Team</h1>
            </div>
            <div className="card-body">
                <p>“Technology is best when it brings people together.”</p>
            </div>
        </>
    );
};

export default Team;
