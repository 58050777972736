// React
import { useEffect, useRef } from 'react';

export const useMountEffect = func => {
    const funcRef = useRef();
    funcRef.current = func;
    useEffect(() => {
        funcRef.current();
    }, []);
};
