import {
    SET_USER_DOCUMENTS,
    SET_ORG_DOCUMENTS,
    UPLOAD_STORAGE_DOCUMENT,
    UPLOAD_STORAGE_DOCUMENT_SUCCESS,
    UPLOAD_STORAGE_DOCUMENT_FAILURE,
    SET_DOCUMENT_FILTER,
    SET_DOC_SEARCH_SUGGESTIONS,
    SET_DOCUMENT_LIST,
    SET_LIBRARY_LIST,
    SET_LIBRARY_FILTER,
    UPDATE_DOCUMENT,
    REMOVE_DOCUMENT,
    ADD_DOCUMENT_TO_TRANSACTION,
    REMOVE_DOCUMENT_FROM_TRANSACTION,
    SET_TRANSACTION_DOCUMENTS_FILTER
} from './types';

export const storingUserDocuments = documents => {
    return {
        type: SET_USER_DOCUMENTS,
        payload: documents?.doc?.empty ? [] : documents
    };
};

export const storingOrgDocuments = documents => {
    return {
        type: SET_ORG_DOCUMENTS,
        payload: documents?.doc?.empty ? [] : documents
    };
};

export const uploadStorageDocument = doc => {
    return {
        type: UPLOAD_STORAGE_DOCUMENT,
        payload: doc
    };
};

export const uploadDocumentSuccess = () => {
    return {
        type: UPLOAD_STORAGE_DOCUMENT_SUCCESS
    };
};

export const uploadDocumentFailure = () => {
    return {
        type: UPLOAD_STORAGE_DOCUMENT_FAILURE
    };
};

export const settingDocumentFilter = data => {
    return {
        type: SET_DOCUMENT_FILTER,
        payload: data
    };
};

export const settingSearchSuggestions = data => {
    return {
        type: SET_DOC_SEARCH_SUGGESTIONS,
        payload: data
    };
};

export const setDocumentList = type => {
    return {
        type: SET_DOCUMENT_LIST,
        payload: type
    };
};

export const setLibraryList = type => {
    return {
        type: SET_LIBRARY_LIST,
        payload: type
    };
};

export const settingLibraryFilter = data => {
    return {
        type: SET_LIBRARY_FILTER,
        payload: data
    };
};

export const updateDocument = data => {
    return {
        type: UPDATE_DOCUMENT,
        payload: data
    };
};

export const addDocumentToTrx = docs => {
    return {
        type: ADD_DOCUMENT_TO_TRANSACTION,
        payload: docs
    };
};

export const removeDocumentFromTrx = data => {
    return {
        type: REMOVE_DOCUMENT_FROM_TRANSACTION,
        payload: data
    };
};

export const settingTrxDocumentsFilter = filter => {
    return {
        type: SET_TRANSACTION_DOCUMENTS_FILTER,
        payload: filter
    };
};

export const removeUserDocument = data => {
    return {
        type: REMOVE_DOCUMENT,
        payload: data
    };
};
