import React from 'react';
import cx from 'classnames';
import SimpleBar from 'simplebar-react';

const ContactDetailsHistory = ({ history, heading, status }) => {
    return (
        <div className="mt-5 ml-3 history">
            <h2 className="h6 mb-2 history__heading">{heading}</h2>
            <div className="history__list">
                <SimpleBar style={{ height: '120px', overflowY: 'auto' }}>
                    {history.map(item => (
                        <div className="d-flex align-items-center" key={item.id}>
                            <div
                                className={cx('col-1', {
                                    'label--seller': item.role === 'Seller',
                                    'label--buyer': item.role === 'Buyer'
                                })}
                            >
                                {item.role === 'Seller' ? status.seller : status.buyer}
                            </div>
                            <div className="col-11 label__text">
                                {item.date} {item.status} {item.address}
                            </div>
                        </div>
                    ))}
                </SimpleBar>
            </div>
        </div>
    );
};

export default ContactDetailsHistory;
