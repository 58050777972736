// React
import React, { useEffect } from 'react';

// Assets
import logo from '../assets/img/jada-logo.svg';

// Components
import { TextField, Loader } from '../components';

// Context
import { useLang } from '../context/LangContext';

// Packages
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Container, Button, Label, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Redux - Actions, Reducers, Sagas
import { loginUserEmailPassword, clearAuthError } from '../store/actions/Auth';

// Router
import * as routes from '../router/config/routes';

const Login = ({ loginUserEmailPassword, clearAuthError, error, loading }) => {
    const {
        title_1,
        title_2,
        sub_title,
        email_label,
        email_placeholder,
        password_label,
        forgot_password,
        no_account,
        no_account_link,
        button_label
    } = useLang()['Login'];

    const { errorMessage } = useLang()['Constants'];

    useEffect(() => {
        return () => {
            clearAuthError();
        };
    }, [clearAuthError]);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .lowercase()
            .email(errorMessage.email.valid)
            .required(errorMessage.email.required),
        password: Yup.string()
            // .matches(passwordRegExp.lowerCase, errorMessage.password.lowerCase)
            // .matches(passwordRegExp.upperCase, errorMessage.password.upperCase)
            // .matches(passwordRegExp.numeric, errorMessage.password.numeric)
            // .min(8, errorMessage.password.length)
            .required(errorMessage.password.required)
    });
    return (
        <Container className="space-2">
            <Col>
                <Link
                    className="d-flex align-items-center justify-content-center position-relative mt-11 mb-8"
                    to="/"
                    aria-label="Jada"
                >
                    <img
                        src={logo}
                        className="logo"
                        style={{ width: '322px' }}
                        alt="Jada Real Estate Real Simple"
                    />
                </Link>
                <Formik
                    initialValues={{
                        email: '',
                        password: ''
                    }}
                    onSubmit={({ email, password }) => {
                        loginUserEmailPassword({ email, password });
                        clearAuthError();
                    }}
                    validationSchema={validationSchema}
                    validateOnChange
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values
                    }) => (
                        <Form className="w-md-50 mx-md-auto">
                            <div className="mb-7">
                                <h2 className="h3 text-primary font-weight-normal mb-0">
                                    {title_1}
                                    <span className="font-weight-semi-bold">
                                        {title_2}
                                    </span>
                                </h2>
                                <p>{sub_title}</p>
                                {error && (
                                    <span
                                        style={{
                                            width: '100%',
                                            marginTop: '0.25rem',
                                            fontSize: '80%',
                                            color: '#de4437'
                                        }}
                                    >
                                        {error.message}
                                    </span>
                                )}
                            </div>
                            <div className="js-form-message form-group">
                                <Label className="form-label" for="email">
                                    {email_label}
                                </Label>
                                <TextField
                                    required
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    id="email"
                                    placeholder={email_placeholder}
                                    aria-label={email_placeholder}
                                    error={errors.email}
                                    value={values.email}
                                    onBlur={handleBlur('email')}
                                    onChange={handleChange('email')}
                                    invalid={touched.email && !!errors.email}
                                />
                            </div>
                            <div className="js-form-message form-group">
                                <Label className="form-label" for="password">
                                    <span className="d-flex justify-content-between align-items-center">
                                        {password_label}
                                        <Link
                                            className="link-muted text-capitalize font-weight-normal"
                                            to={`${routes.RESET_PASSWORD}`}
                                        >
                                            {forgot_password}
                                        </Link>
                                    </span>
                                </Label>
                                <TextField
                                    required
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    id="password"
                                    placeholder="********"
                                    aria-label="********"
                                    error={errors.password}
                                    value={values.password}
                                    onBlur={handleBlur('password')}
                                    onChange={handleChange('password')}
                                    invalid={touched.password && !!errors.password}
                                />
                            </div>
                            <div className="row align-items-center mb-5">
                                <div className="col-8">
                                    <span className="small text-muted">{no_account}</span>
                                    <Link className="small" to="/">
                                        {no_account_link}
                                    </Link>
                                </div>
                                <div className="col-4 text-right">
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="transition-3d-hover"
                                        onClick={handleSubmit}
                                    >
                                        {button_label}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Col>
            {loading && <Loader />}
        </Container>
    );
};

const mapStateToProps = ({ auth }) => {
    const { error, loading } = auth;
    return { error, loading };
};

export default withRouter(
    connect(mapStateToProps, { loginUserEmailPassword, clearAuthError })(Login)
);
