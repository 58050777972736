import { USER_AUTHENTICATED_SUCCESS, USER_AUTHENTICATED_FAILURE } from '../actions/types';

const INITIAL_STATE = {
    appInitialized: false
};

const initialReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_AUTHENTICATED_SUCCESS:
            return {
                ...state,
                appInitialized: true
            };
        case USER_AUTHENTICATED_FAILURE:
            return {
                ...state,
                appInitialized: true
            };
        default:
            return state;
    }
};

export default initialReducer;
