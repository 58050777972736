// React
import React from 'react';

// Packages
import { Col, Row, Spinner } from 'reactstrap';

const Loader = props => {
    return (
        <Row className="align-items-center justify-content-center py-5 row">
            <Col xs="auto">
                <Spinner {...props} />
            </Col>
        </Row>
    );
};

Loader.propTypes = { ...Spinner.propTypes };

Loader.defaultProps = {
    type: 'grow',
    size: 'lg',
    color: 'primary'
};

export default Loader;
