import { SET_USER_COLLECTION } from '../actions/types';

const INITIAL_STATE = {
    userData: null
};

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER_COLLECTION:
            return {
                ...state,
                userData: action.payload
            };
        default:
            return state;
    }
};

export default userReducer;
