// React
import React, { useState, useCallback } from 'react';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import { Link, NavLink } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

// Components
import { AddContactsModal } from '../../components';

// Router
import * as routes from '../../router/config/routes';

const BUTTON_CONTEXT = {
    TRANSACTIONS: '/user/dashboard/transactions',
    CONTACTS: '/user/dashboard/contacts',
    LIBRARY: '/user/dashboard/library',
    DOCUMENTS: '/user/dashboard/documents'
};

const MainHeader = ({ match, history, userData }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [contactModal, setContactModal] = useState(false);

    const org = userData?.orgs.filter(org => org.id === userData.active_org_id);
    const userType = org && org.length ? org[0].sub_type : null;
    const libraryShowButton =
        (userType && userType === 'broker') ||
        (userData?.type && userData.type === 'owner');

    const toggle = () => setDropdownOpen(prevState => !prevState);
    const path = history.location.pathname;
    const {
        transactions_link,
        contacts_link,
        forms_link,
        documents_link,
        profile_link,
        preferences_link,
        alerts_link,
        transaction_button_label,
        contact_button_label,
        library_button_label,
        document_button_label
    } = useLang()['Common']['MainHeader'];

    const showSettings =
        path === `${routes.AUTHENTICATED}${routes.PROFILE}` ||
        path === `${routes.AUTHENTICATED}${routes.SETTINGS}` ||
        path === `${routes.AUTHENTICATED}${routes.ALERTS}` ||
        path === `${routes.AUTHENTICATED}${routes.PREFERENCES}`;

    const mainLinks = [
        {
            name: `${transactions_link}`,
            route: `${match.url}${routes.TRANSACTIONS}`,
            icon: `objects-column`
        },
        {
            name: `${contacts_link}`,
            route: `${match.url}${routes.CONTACTS}`,
            icon: `address-book`
        },
        {
            name: `${forms_link}`,
            route: `${match.url}${routes.LIBRARY}`,
            icon: `file-signature`
        },
        {
            name: `${documents_link}`,
            route: `${match.url}${routes.DOCUMENTS}`,
            icon: `file-user`
        }
    ];

    const settingsLinks = [
        { name: `${profile_link}`, route: `${routes.AUTHENTICATED}${routes.PROFILE}` },
        {
            name: `${preferences_link}`,
            route: `${routes.AUTHENTICATED}${routes.PREFERENCES}`
        },
        { name: `${alerts_link}`, route: `${routes.AUTHENTICATED}${routes.ALERTS}` }
    ];

    const links = showSettings ? settingsLinks : mainLinks;

    const dropTitle = () => {
        const title = links.filter(item => item.route === history.location.pathname);

        return title[0]?.name;
    };

    const toggleControl = useCallback(({ reset, cancel }) => {
        if (reset || cancel) {
            setContactModal(modal => !modal);
        } else {
            setContactModal(modal => !modal);
        }
    }, []);

    const activateContactsModal = () => {
        toggleControl({ reset: false, cancel: false });
    };

    const buttonContext = () => {
        switch (path) {
            case BUTTON_CONTEXT.TRANSACTIONS:
                return (
                    <Link
                        className="btn btn-sm btn-soft-white text-nowrap transition-3d-hover"
                        to={`${routes.AUTHENTICATED}${routes.NEW_TRANSACTION}`}
                    >
                        {transaction_button_label}
                    </Link>
                );
            case BUTTON_CONTEXT.CONTACTS:
                return (
                    <button
                        className="btn btn-sm btn-soft-white text-nowrap transition-3d-hover link-button"
                        onClick={activateContactsModal}
                    >
                        {contact_button_label}
                    </button>
                );
            case BUTTON_CONTEXT.LIBRARY:
                return (
                    libraryShowButton && (
                        <Link
                            className="btn btn-sm btn-soft-white text-nowrap transition-3d-hover link-button"
                            to={`${routes.AUTHENTICATED}${routes.NEW_DOCUMENT}`}
                        >
                            {library_button_label}
                        </Link>
                    )
                );
            case BUTTON_CONTEXT.DOCUMENTS:
                return (
                    <Link
                        className="btn btn-sm btn-soft-white text-nowrap transition-3d-hover link-button"
                        to={`${routes.AUTHENTICATED}${routes.NEW_DOCUMENT}`}
                    >
                        {document_button_label}
                    </Link>
                );
            default:
                return <></>;
        }
    };

    return (
        <div className="bg-primary">
            <div className="container">
                <AddContactsModal
                    toggleControl={toggleControl}
                    contactModal={contactModal}
                />
                <div
                    id="xs-nav"
                    className="d-block d-lg-none d-flex justify-content-between align-items-center pt-1 pb-1"
                >
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle
                            style={{
                                borderColor: 'transparent',
                                width: '150px',
                                height: '50px',
                                backgroundColor: 'transparent',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: 0,
                                fontWeight: 600
                            }}
                        >
                            {dropTitle()}
                            <FontAwesomeIcon
                                style={{ marginBottom: 0, marginLeft: '10px' }}
                                className={
                                    dropdownOpen ? 'drop-caret down' : 'drop-caret'
                                }
                                icon={faAngleRight}
                                color="rgba(255,255,255,1)"
                            />
                        </DropdownToggle>
                        <DropdownMenu className={'animate slideIn'}>
                            {links.map(item => (
                                <NavLink
                                    key={item.name}
                                    className="dropdown-link"
                                    to={item.route}
                                    activeClassName="active"
                                >
                                    <DropdownItem className="dropdown-item">
                                        {item.name}
                                    </DropdownItem>
                                </NavLink>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                    {!showSettings && buttonContext()}
                </div>
                <div
                    id="lg-nav"
                    className="d-lg-flex justify-content-lg-between align-items-lg-center d-none d-lg-block"
                    style={{ width: '100%' }}
                >
                    <div className="u-header u-header-left-aligned-nav u-header--bg-transparent-lg u-header--white-nav-links z-index-4">
                        <div className="u-header__section bg-transparent">
                            <nav className="navbar navbar-expand-lg u-header__navbar u-header__navbar--no-space">
                                <ul className="navbar-nav u-header__navbar-nav">
                                    {links.map(item => (
                                        <li
                                            className="nav-item u-header__nav-item"
                                            key={item.name}
                                        >
                                            <NavLink
                                                to={item.route}
                                                className="nav-link u-header__nav-link"
                                                activeClassName="active"
                                            >
                                                <span
                                                    className={`fa-regular fa-${item.icon} mr-2`}
                                                />
                                                {item.name}
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    </div>
                    {!showSettings && (
                        <div className="ml-lg-auto d-none d-sm-block">
                            {buttonContext()}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MainHeader;
