import * as Sentry from '@sentry/react';

export function log(message, extra = {}, level = Sentry.Severity.Error) {
    Sentry.withScope(scope => {
        Object.keys(extra).forEach(item => {
            scope.setExtra(item, extra[item]);
        });
        if (typeof message === 'object') {
            Sentry.captureException(message, level);
        } else {
            Sentry.captureMessage(message, level);
        }
    });
}
