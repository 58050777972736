// React
import React, { useState, useCallback } from 'react';

// Components
import { DocumentsGrid, DocumentsSearchFilter } from '../components';

// Hooks
import { useWindowSize } from '../hooks/useWindowSize';

// Packages
import SimpleBar from 'simplebar-react';
// import { withRouter } from 'react-router-dom';
// import { connect } from 'react-redux';

// Redux - Actions, Reducers, Sagas
// import { logoutUser } from '../store/actions/Auth';

const Documents = ({ match }) => {
    const [scrollHeight, setScrollHeight] = useState(0);
    const { windowHeight, windowWidth } = useWindowSize();
    const measuredRef = useCallback(
        node => {
            if (node?.getBoundingClientRect()?.top && windowHeight) {
                setScrollHeight(windowHeight - node.getBoundingClientRect().top);
            }
        },
        [windowHeight]
    );
    return (
        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <DocumentsSearchFilter />
            <div ref={measuredRef}>
                <SimpleBar style={{ height: scrollHeight, width: windowWidth }}>
                    <div className="container space-bottom-1">
                        <DocumentsGrid match={match} />
                    </div>
                </SimpleBar>
            </div>
        </div>
    );
};

export default Documents;
