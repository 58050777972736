// React
import React from 'react';

// Assets
import Background from '../assets/img/img32.jpg';

// Context
import { useLang } from '../context/LangContext';

// Packages
import { Container, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

// Router
import * as routes from '../router/config/routes';

const Landing = () => {
    const { title_1, title_2, button_label } = useLang()['Landing'];

    return (
        <div
            className="d-md-flex bg-img-hero"
            style={{ backgroundImage: `url(${Background})` }}
        >
            <Container className="d-md-flex align-items-md-center justify-content-center height-md-100vh position-relative space-top-2 space-bottom-3 space-top-md-3 space-top-lg-2 space-bottom-md-0">
                <div className="w-lg-80 mb-5">
                    <div className="mb-5">
                        <h1 className="display-4 text-white mb-6">
                            {title_1}
                            <strong className="font-weight-semi-bold">{title_2}</strong>
                        </h1>
                        <Button
                            color="primary"
                            className="btn-wide transition-3d-hover"
                            tag={Link}
                            to={routes.LOGIN}
                        >
                            {button_label}
                            <span className="fas fa-angle-right ml-2" />
                        </Button>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Landing;
