import React from 'react';

const Billing = () => {
    return (
        <>
            <div className="card-header">
                <h1 className="h5 font-weight-medium mb-0">Billing</h1>
            </div>
            <div className="card-body">
                <p>
                    “The Web as I envisaged it, we have not seen it yet. The future is
                    still so much bigger than the past.”
                </p>
            </div>
        </>
    );
};

export default Billing;
