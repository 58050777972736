// React
import React from 'react';

// Assets
import PDF from '../../assets/svg/PDF.svg';

// Components
import { Loader, TextField } from '../../components';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';

const AddDocumentModal = ({
    setSource,
    setType,
    source,
    toggleModal,
    loading,
    documentModal,
    uploadDocument,
    type,
    fileTitle,
    setFileTitle,
    setActiveUpload,
    setModalCancelled
}) => {
    const kb = 1024;
    const mb = 1048576;
    const {
        upload_label,
        name_label,
        size_label,
        add_title_label,
        title_placeholder,
        doc_type_label,
        buyer_label,
        seller_label,
        other_label,
        add_button_label,
        cancel_button_label
    } = useLang()['Common']['AddDocumentModal'];

    const onCancel = () => {
        setModalCancelled(true);
        setActiveUpload(false);
        toggleModal();
        setSource(null);
        setType(null);
    };
    const handleRadioPress = type => {
        setType(type);
    };

    return (
        <div>
            <Modal
                isOpen={documentModal}
                toggle={toggleModal}
                contentClassName="document-upload-modal"
                className="document-upload-modal"
            >
                <ModalHeader toggle={onCancel}>{upload_label}</ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'hidden',
                            maxWidth: '450px'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <img
                                className={'mt-3 mb-1'}
                                style={{
                                    width: '125px',
                                    height: '150px'
                                }}
                                src={PDF}
                                alt="PDF"
                            />

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <span>
                                    {name_label}
                                    <span className="text-primary">{source?.name}</span>
                                </span>
                                <span className="mb-3">
                                    {size_label}
                                    <span className="text-primary">
                                        {source
                                            ? Math.ceil(source.size / kb) > 1000
                                                ? `${
                                                      Math.round(
                                                          (source.size / mb) * 100
                                                      ) / 100
                                                  } MB`
                                                : `${Math.ceil(source.size / kb)} KB`
                                            : null}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ width: '100%' }}>
                        <div className="col-12 mt-3 mb-2">
                            <div className="js-form-message form-group">
                                <Label className="form-label">{add_title_label}</Label>
                                <TextField
                                    type="text"
                                    className="form-control"
                                    placeholder={title_placeholder}
                                    aria-label={title_placeholder}
                                    value={fileTitle}
                                    onChange={e => setFileTitle(e.target.value)}
                                    nonFormik={true}
                                />
                            </div>
                        </div>
                    </div>
                    <>
                        <div className="column mt-5 mb-3">
                            <Label className="form-label">
                                {doc_type_label}
                                <span className="text-danger ml-1">*</span>
                            </Label>
                        </div>
                        <div className="column mb-3">
                            <div className="form-check mb-2">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="documentRadio"
                                    id="radio1"
                                    onChange={() => handleRadioPress('buyer')}
                                    value="option1"
                                />
                                <label className="form-check-label" htmlFor="radio1">
                                    {buyer_label}
                                </label>
                            </div>
                            <div className="form-check mb-2">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="documentRadio"
                                    id="radio2"
                                    onChange={() => handleRadioPress('seller')}
                                    value="option2"
                                />
                                <label className="form-check-label" htmlFor="radio2">
                                    {seller_label}
                                </label>
                            </div>
                            <div className="form-check mb-2">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="documentRadio"
                                    id="radio3"
                                    onChange={() => handleRadioPress('other')}
                                    value="option3"
                                />
                                <label className="form-check-label" htmlFor="radio3">
                                    {other_label}
                                </label>
                            </div>
                        </div>
                    </>
                </ModalBody>
                <ModalFooter>
                    {loading && <Loader />}
                    <button className="btn btn-link text-danger" onClick={onCancel}>
                        {cancel_button_label}
                    </button>
                    <Button color="primary" disabled={!type} onClick={uploadDocument}>
                        {add_button_label}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default AddDocumentModal;
