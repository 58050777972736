// React
import React from 'react';

// Context
import { useLang } from '../../context/LangContext';

const TabNavigation = ({ onClick, selectedTab }) => {
    const { activity_label, documents_label, notes_label } =
        useLang()['TransactionDetails']['TabNavigation'];
    const tabs = [
        {
            name: activity_label,
            value: 'Activity',
            id: 'pills-one-example2-tab',
            dataToggle: 'pill',
            role: 'tab',
            ariaControls: 'pills-one-example2'
        },
        {
            name: documents_label,
            value: 'Documents',
            id: 'pills-two-example2-tab',
            dataToggle: 'pill',
            role: 'tab',
            ariaControls: 'pills-two-example2'
        },
        {
            name: notes_label,
            value: 'Notes',
            id: 'pills-three-example2-tab',
            dataToggle: 'pill',
            role: 'tab',
            ariaControls: 'pills-three-example2'
        }
    ];
    return (
        <ul
            className="nav nav-classic nav-shadow border nav-justified bg-white"
            role="tablist"
        >
            {tabs.map(tab => (
                <li
                    className="nav-item"
                    key={tab.id}
                    onClick={() => onClick(tab.value)}
                    style={{ cursor: 'pointer' }}
                >
                    <div
                        className={
                            selectedTab === tab.value
                                ? 'nav-link font-weight-medium active'
                                : 'nav-link font-weight-medium'
                        }
                        id={tab.id}
                        data-toggle={tab.dataToggle}
                        role={tab.role}
                        aria-controls={tab.ariaControls}
                        aria-selected={selectedTab === tab.value ? 'true' : 'false'}
                    >
                        <div className="d-md-flex justify-content-md-center align-items-md-center">
                            {tab.name}
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default TabNavigation;
