import {
    all,
    fork,
    // take,
    // cancelled,
    takeLatest,
    // race,
    put,
    call,
    select
} from 'redux-saga/effects';

import { REMOVING_PREFERENCE_TASK, ADDING_PREFERENCE_TASK } from '../actions/types';

import {
    taskRemovalSuccess,
    taskAdditionSuccess,
    taskRemovalFailure,
    taskAdditionFailure
} from '../actions/Preferences';

import {
    db
    //  timeStampNow,
    //  timeStampJs
} from '../../config/Firebase';

import * as selectors from './Selectors';

// Loggers
import { log } from '../../utils/Loggers';

// const transactions = db.collection('transactions');
const users = db.collection('users');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////// Removing Preference Task ///////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const removeUserTaskRequest = ({ tasks, type, userData }) => {
    const ref = users.doc(userData.id);
    const preferences = userData.preferences;
    const org = userData.active_org_id;
    const location = userData.active_location_id;
    const newPref = {
        ...preferences,
        [`${org}`]: [...preferences[`${org}`]]
    };
    newPref[`${org}`][`${location}`].initial_tasks[type] = tasks;
    return new Promise((resolve, reject) => {
        ref.update({
            preferences: { ...newPref }
        })
            .then(() => {
                resolve({ res: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* removeUserTask({ payload }) {
    const { tasks, type } = payload;
    const userData = yield select(selectors._userData);
    const { res, error } = yield call(() =>
        removeUserTaskRequest({ tasks, type, userData })
    );
    if (res) {
        yield put(taskRemovalSuccess());
    } else {
        yield put(taskRemovalFailure());
        log('Preferences Error: removing task from initial tasks in preferences (FS)', {
            error,
            tasks,
            type
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// Adding Preference Task ////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const addPreferenceTaskRequest = ({ buyerTasks, listingTasks, userData }) => {
    const ref = users.doc(userData.id);
    const preferences = userData.preferences;
    const org = userData.active_org_id;
    const location = userData.active_location_id;
    const newPref = {
        ...preferences,
        [`${org}`]: [...preferences[`${org}`]]
    };
    newPref[`${org}`][`${location}`].initial_tasks.buyer = buyerTasks;
    newPref[`${org}`][`${location}`].initial_tasks.listing = listingTasks;
    return new Promise((resolve, reject) => {
        ref.update({
            preferences: { ...newPref }
        })
            .then(() => {
                resolve({ res: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* addPreferenceTask({ payload }) {
    const { buyerTasks, listingTasks } = payload;
    const userData = yield select(selectors._userData);
    const { res, error } = yield call(() =>
        addPreferenceTaskRequest({ buyerTasks, listingTasks, userData })
    );
    if (res) {
        yield put(taskAdditionSuccess());
    } else {
        yield put(taskAdditionFailure());
        log('Preferences Error: adding task to initial tasks in preferences (FS)', {
            error,
            buyerTasks,
            listingTasks
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* removingUserTask() {
    yield takeLatest(REMOVING_PREFERENCE_TASK, removeUserTask);
}

export function* addingPreferenceTask() {
    yield takeLatest(ADDING_PREFERENCE_TASK, addPreferenceTask);
}

export default function* rootSaga() {
    yield all([fork(removingUserTask), fork(addingPreferenceTask)]);
}
