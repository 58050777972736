import { GET_PROPERTY_MLS, GET_PROPERTY_MLS_SUCCESS, RESET_PROPERTY_DATA } from './types';

export const getMlsData = mlsNumber => {
    return {
        type: GET_PROPERTY_MLS,
        payload: mlsNumber
    };
};

export const getMlsDataSuccess = property => {
    return {
        type: GET_PROPERTY_MLS_SUCCESS,
        payload: property
    };
};

export const resetPropertyData = () => {
    return {
        type: RESET_PROPERTY_DATA
    };
};
