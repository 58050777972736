import {
    SET_USER_CONTACTS,
    SET_ORG_CONTACTS,
    SET_USER_COLLECTION,
    SET_CONTACTS_FILTER,
    ADD_NEW_CONTACTS,
    ADD_NEW_CONTACTS_SUCCESS,
    ADD_NEW_CONTACTS_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    loading: null,
    userContacts: [],
    orgContacts: [],
    filterType: {
        name: 'All Contacts',
        value: 'all'
    },
    uploadError: null
};

const contactsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER_CONTACTS:
            return {
                ...state,
                userContacts: action.payload
            };
        case SET_ORG_CONTACTS:
            return {
                ...state,
                orgContacts: action.payload
            };
        case SET_USER_COLLECTION:
            return {
                ...state,
                filterType:
                    action.payload.language === 'en'
                        ? { name: 'All Contacts', value: 'all' }
                        : { name: 'Todos Contactos', value: 'all' }
            };
        case SET_CONTACTS_FILTER:
            return {
                ...state,
                filterType: action.payload
            };
        case ADD_NEW_CONTACTS:
            return {
                ...state,
                loading: true,
                uploadError: false
            };
        case ADD_NEW_CONTACTS_SUCCESS:
            return {
                ...state,
                loading: false,
                uploadError: false
            };
        case ADD_NEW_CONTACTS_FAILURE:
            return {
                ...state,
                loading: false,
                uploadError: true
            };

        default:
            return state;
    }
};

export default contactsReducer;
