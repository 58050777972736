// React
import React, { useEffect, useState } from 'react';

// Components
import { CardSkeleton } from '../../components';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import numeral from 'numeral';

// Router
import { useHistory } from 'react-router-dom';
import * as routes from '../../router/config/routes';

// Images
import defaultTransactionPhoto from '../../assets/img/default_transaction_photo.png';

const TransactionCard = ({
    id,
    address: { address_1, address_2, city },
    primary_client: { first_name, last_name },
    price,
    display,
    type,
    mls,
    gettingTransactionDetails,
    loading,
    transactionDetails,
    detailsFlag
}) => {
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [usePlaceHolder, setUsePlaceHolder] = useState(false);
    const history = useHistory();

    const { buyer_label, seller_label, button_label } =
        useLang()['Transactions']['TransactionCard'];

    const handleDetailsRequest = trxId => {
        gettingTransactionDetails({ trxId, detailsFlag: true });
    };

    const handleLoad = () => {
        setShowSkeleton(!showSkeleton);
    };

    const handleError = () => {
        setShowSkeleton(!showSkeleton);
        setUsePlaceHolder(!usePlaceHolder);
    };

    useEffect(() => {
        if (!loading && transactionDetails && detailsFlag) {
            if (transactionDetails.id === id) {
                history.push({
                    pathname: `${routes.AUTHENTICATED}${routes.TRANSACTION_DETAIL}/${id}`,
                    state: { id }
                });
            }
        }
    }, [loading, transactionDetails, detailsFlag, id, history]);

    return (
        <>
            {showSkeleton && <CardSkeleton />}
            <div
                style={{ opacity: showSkeleton ? 0 : 1 }}
                onClick={() => handleDetailsRequest(id)}
            >
                <div style={{ width: '100%', height: '185px', maxHeight: '185px' }}>
                    <img
                        src={display || defaultTransactionPhoto}
                        onLoad={handleLoad}
                        onError={handleError}
                        className="card-img-top w-100"
                        style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                        alt="property main"
                    />
                </div>
                <div className="position-absolute top-0 left-0 pt-2 pl-3 col-12">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-6">
                            <span className={`badge badge-pill badge-${type}`}>
                                {type === 'buyer' ? buyer_label : seller_label}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body p-4" style={{ opacity: showSkeleton ? 0 : 1 }}>
                <div className="mb-2">
                    <div
                        className="font-size-1 d-block text-truncate text-primary"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleDetailsRequest(id)}
                    >
                        {address_1}
                        {address_2 && <span> {address_2}</span>}, {city}
                    </div>
                    <small
                        className="d-block text-muted"
                        style={{ opacity: mls ? 1 : 0, cursor: mls ? 'text' : 'default' }}
                    >
                        mls# {mls}
                    </small>
                </div>
                <div className="mb-2">
                    <h2 className="h6 mb-0">
                        {first_name}&nbsp;{last_name}
                    </h2>
                </div>
                <div className="d-flex flex-wrap align-items-center font-size-1">
                    <h2 className="h5 mb-3 text-primary">
                        ${numeral(price).format('0,0')}
                    </h2>
                    <div
                        className="btn btn-sm btn-soft-primary transition-3d-hover w-100"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleDetailsRequest(id)}
                    >
                        {button_label}
                        <span className="fas fa-angle-right ml-1" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default TransactionCard;
