// React
import React, { useState, useEffect, useCallback } from 'react';

// Components
import {
    DetailsHeader,
    TransactionMeta,
    TransactionInterface,
    Tasks,
    Loader,
    DocumentView
} from '../components';
import ConfirmationDialog from '../components/common/ConfirmationDialog';

// Firebase
import { storage } from '../config/Firebase';

// Hooks
import { useWindowSize } from '../hooks/useWindowSize';

// Loggers
import { log } from '../utils/Loggers';

// Packages
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isTablet } from 'react-device-detect';

// Redux - Actions, Reducers, Sagas
import {
    gettingTransactionDetails,
    resetDetailsFlag,
    cancelDetailsListener,
    updateTransactionStatus
} from '../store/actions/Transactions';
import {
    addDocumentToTrx,
    removeDocumentFromTrx,
    settingTrxDocumentsFilter
} from '../store/actions/Documents';

// Context
import { useLang } from '../context/LangContext';

// Constants
import { headerTypes, confirmationDialogTypes } from '../utils/Constants';

const TransactionDetails = ({
    match,
    location,
    transactionDetails,
    userDocuments,
    orgDocuments,
    gettingTransactionDetails,
    detailsFlag,
    resetDetailsFlag,
    cancelDetailsListener,
    userData,
    addDocumentToTrx,
    removeDocumentFromTrx,
    filteredTransactionDocuments,
    trxDetailsFilterType,
    settingTrxDocumentsFilter,
    transactionActivity,
    updateTransactionStatus,
    modalDialogType
}) => {
    const {
        title_question,
        activate_content,
        close_content,
        close_input_placeholder,
        invalid_closing_date,
        archive_content,
        success_title_update,
        success_content_update,
        failed_title,
        failed_content,
        confirm_button,
        cancel_button,
        loading_title,
        loading_content
    } = useLang()['Common']['ConfirmationDialog'];
    const [tabletDevice, setTabletDevice] = useState(isTablet);
    const [imgByIds, setImgByIds] = useState([]);
    const [documentView, setDocumentView] = useState(null);
    const { windowHeight, windowWidth } = useWindowSize();
    const transaction = transactionDetails;
    const activity = transactionActivity;

    const allDocs = () => {
        const docs = [].concat(userDocuments, orgDocuments);
        return docs;
    };

    const getTrxId = useCallback(() => {
        const pathname = window.location.pathname;
        if (location.state?.id) {
            if (!transactionDetails) {
                gettingTransactionDetails({
                    trxId: location.state.id,
                    detailsFlag: false
                });
            }
            return location.state.id;
        } else {
            const id = pathname.substr(pathname.lastIndexOf('/') + 1);
            gettingTransactionDetails({ trxId: id, detailsFlag: false });
            return id;
        }
    }, [transactionDetails, gettingTransactionDetails, location]);

    useEffect(() => {
        return () => {
            cancelDetailsListener();
        };
    }, [cancelDetailsListener]);

    useEffect(() => {
        getTrxId();
    }, [getTrxId]);

    useEffect(() => {
        if (detailsFlag) {
            resetDetailsFlag();
        }
    }, [detailsFlag, resetDetailsFlag]);

    useEffect(() => {
        setTabletDevice(isTablet);
    }, [windowHeight, windowWidth]);

    const getUserAvatars = ids => {
        const imgObjArr = [];
        ids.forEach(id => {
            if (id) {
                const ref = storage.ref().child(`users/${id}/avatar.jpg`);
                ref.getDownloadURL()
                    .then(imgUrl => {
                        imgObjArr.push({ id, url: imgUrl });
                        if (imgObjArr.length === ids.length) {
                            setImgByIds(imgObjArr);
                        }
                    })
                    .catch(error => {
                        imgObjArr.push({ id, url: null, err: error.code_ });
                        if (imgObjArr.length === ids.length) {
                            setImgByIds(imgObjArr);
                        }
                        log('Transaction Details Avatar: error in getting avatar (FBS)', {
                            error,
                            storage_path: `users/${id}/avatar.jpg`,
                            function: 'getUserAvatars'
                        });
                    });
            } else {
                imgObjArr.push({ id: null, url: null });
            }
        });
    };

    useEffect(() => {
        if (transaction?.avatars) {
            getUserAvatars([...transaction.avatars]);
        }
    }, [transaction]);

    if (!transaction) return <Loader />;
    return (
        <>
            <ConfirmationDialog
                titleText={title_question}
                contentText={
                    modalDialogType === confirmationDialogTypes.activateTrx
                        ? activate_content
                        : modalDialogType === confirmationDialogTypes.archiveTrx
                        ? archive_content
                        : close_content
                }
                confirmButtonText={confirm_button}
                cancelButtonText={cancel_button}
                loadingTitleText={loading_title}
                loadingContentText={loading_content}
                successTitleText={success_title_update}
                successContentText={success_content_update}
                failedTitleText={failed_title}
                failedContentText={failed_content}
                inputPlaceholder={close_input_placeholder}
                invalidInput={invalid_closing_date}
            />
            {documentView && (
                <DocumentView
                    setDocumentView={setDocumentView}
                    documentView={documentView}
                />
            )}
            <>
                <DetailsHeader
                    headerType={headerTypes.transactionDetails}
                    transaction={transaction}
                    updateTransactionStatus={updateTransactionStatus}
                    userData={userData}
                />
                <main id="content" role="main" className="push-main">
                    <div className="bg-light">
                        <div id="main" className="size container pt-3 pt-xl-4">
                            {tabletDevice ? (
                                <>
                                    {windowWidth >= 1366 ? (
                                        <>
                                            <div className="row no-gutters">
                                                <TransactionMeta
                                                    transaction={transaction}
                                                    avatars={imgByIds}
                                                />
                                                <TransactionInterface
                                                    transaction={transaction}
                                                    activity={activity}
                                                    match={match}
                                                    avatars={imgByIds}
                                                    allDocs={allDocs()}
                                                    userData={userData}
                                                    addDocumentToTrx={addDocumentToTrx}
                                                    removeDocumentFromTrx={
                                                        removeDocumentFromTrx
                                                    }
                                                    setDocumentView={setDocumentView}
                                                    documentFilter={trxDetailsFilterType}
                                                    settingTrxDocumentsFilter={
                                                        settingTrxDocumentsFilter
                                                    }
                                                    filteredDocs={
                                                        filteredTransactionDocuments
                                                    }
                                                />
                                                <Tasks
                                                    transaction={transaction}
                                                    avatars={imgByIds}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <div
                                            className="no-gutters"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flexGrow: 1
                                            }}
                                        >
                                            <TransactionMeta
                                                transaction={transaction}
                                                avatars={imgByIds}
                                            />
                                            <div className="row no-gutters">
                                                <TransactionInterface
                                                    transaction={transaction}
                                                    activity={activity}
                                                    match={match}
                                                    avatars={imgByIds}
                                                    allDocs={allDocs()}
                                                    userData={userData}
                                                    addDocumentToTrx={addDocumentToTrx}
                                                    removeDocumentFromTrx={
                                                        removeDocumentFromTrx
                                                    }
                                                    setDocumentView={setDocumentView}
                                                    documentFilter={trxDetailsFilterType}
                                                    settingTrxDocumentsFilter={
                                                        settingTrxDocumentsFilter
                                                    }
                                                    filteredDocs={
                                                        filteredTransactionDocuments
                                                    }
                                                />
                                                <Tasks
                                                    transaction={transaction}
                                                    avatars={imgByIds}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className="row no-gutters">
                                    <TransactionMeta
                                        transaction={transaction}
                                        avatars={imgByIds}
                                    />
                                    <TransactionInterface
                                        transaction={transaction}
                                        activity={activity}
                                        match={match}
                                        avatars={imgByIds}
                                        allDocs={allDocs()}
                                        userData={userData}
                                        addDocumentToTrx={addDocumentToTrx}
                                        removeDocumentFromTrx={removeDocumentFromTrx}
                                        setDocumentView={setDocumentView}
                                        documentFilter={trxDetailsFilterType}
                                        settingTrxDocumentsFilter={
                                            settingTrxDocumentsFilter
                                        }
                                        filteredDocs={filteredTransactionDocuments}
                                    />
                                    <Tasks transaction={transaction} avatars={imgByIds} />
                                </div>
                            )}
                        </div>
                    </div>
                </main>
            </>
        </>
    );
};

const mapStateToProps = ({ transactions, documents, user, activity, modal }) => {
    const { userData } = user;
    const { transactionDetails, detailsFlag } = transactions;
    const {
        userDocuments,
        orgDocuments,
        filteredTransactionDocuments,
        trxDetailsFilterType
    } = documents;
    const { transactionActivity } = activity;
    const { modalDialogType } = modal;
    return {
        userDocuments,
        orgDocuments,
        transactionDetails,
        detailsFlag,
        userData,
        filteredTransactionDocuments,
        trxDetailsFilterType,
        transactionActivity,
        modalDialogType
    };
};

export default withRouter(
    connect(mapStateToProps, {
        gettingTransactionDetails,
        resetDetailsFlag,
        cancelDetailsListener,
        addDocumentToTrx,
        removeDocumentFromTrx,
        settingTrxDocumentsFilter,
        updateTransactionStatus
    })(TransactionDetails)
);
