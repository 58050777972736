// React
import React, { useEffect, useState } from 'react';

// Components
import { SideNavClickWrapper } from '../../components';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Avatar from 'react-avatar';
import * as routes from '../../router/config/routes';

// Redux - Actions, Reducers, Sagas
import { togglingSideNav, enableNavButton } from '../../store/actions/App';
import { logoutUser } from '../../store/actions/Auth';

const SideNavigation = ({
    togglingSideNav,
    sideNav,
    userData,
    logoutUser,
    pristine,
    enableNavButton
}) => {
    const [userTitle, setUserTitle] = useState();
    const [orgTitle, setOrgTitle] = useState();

    const { home_link, settings_link, alerts_link, logout_link } =
        useLang()['Common']['SideNavigation'];

    useEffect(() => {
        if (userData) {
            const org = userData.orgs.filter(org => org.id === userData.active_org_id);
            setUserTitle(org[0].title);
            setOrgTitle(org[0].company_name);
        }
    }, [userData]);

    const handleLogout = () => {
        togglingSideNav();
        logoutUser();
    };

    if (!userData) return null;

    return (
        <SideNavClickWrapper>
            <aside
                id="sidebarContent"
                className={`u-sidebar ${sideNav ? 'show-nav' : 'hide-nav'}`}
                aria-label="Side Navigation"
                aria-labelledby="sidebarNavToggler"
                onAnimationEnd={!sideNav ? enableNavButton : null}
                style={{
                    display: pristine ? 'none' : 'block',
                    opacity: pristine ? 0 : 1,
                    visibility: pristine ? 'hidden' : 'visible'
                }}
            >
                <div className="u-sidebar__scroller">
                    <div className="u-sidebar__container">
                        <div className="u-sidebar--account__footer-offset">
                            <div className="d-flex justify-content-between align-items-center pt-4 px-7">
                                <h3 className="h6 mb-0">{orgTitle}</h3>
                                <button
                                    type="button"
                                    className="close ml-auto"
                                    aria-label="Side Nav Close Button"
                                    onClick={togglingSideNav}
                                >
                                    <span aria-hidden="true">
                                        <i className="fa-regular fa-xmark" />
                                    </span>
                                </button>
                            </div>
                            <div className="js-scrollbar u-sidebar__body">
                                <header className="d-flex align-items-center u-sidebar--account__holder mt-3">
                                    <div className="position-relative">
                                        {userData?.user_avatar ? (
                                            <img
                                                className="u-sidebar--account__holder-img"
                                                src={userData.user_avatar}
                                                alt={`${userData.first_name} ${
                                                    userData.last_name
                                                        ? userData.last_name
                                                        : ''
                                                }`}
                                            />
                                        ) : (
                                            <Avatar
                                                className="u-sidebar--account__holder-img"
                                                name={`${userData.first_name} ${userData.last_name}`}
                                                unstyled
                                            />
                                        )}
                                        <span className="badge badge-xs badge-outline-success badge-pos rounded-circle" />
                                    </div>
                                    <div className="ml-3">
                                        <span className="font-weight-semi-bold">
                                            {`${userData?.first_name} ${userData?.last_name}`}
                                        </span>
                                        <span className="u-sidebar--account__holder-text">
                                            {userTitle}
                                        </span>
                                    </div>
                                </header>

                                <div className="u-sidebar__content--account">
                                    <ul className="list-unstyled u-sidebar--account__list">
                                        <li className="u-sidebar--account__list-item">
                                            <NavLink
                                                className="u-sidebar--account__list-link"
                                                to={`${routes.AUTHENTICATED}${routes.DASHBOARD}`}
                                                onClick={togglingSideNav}
                                                activeClassName="active"
                                            >
                                                <span className="fas fa-home u-sidebar--account__list-icon mr-2" />
                                                {home_link}
                                            </NavLink>
                                        </li>
                                        <li className="u-sidebar--account__list-item">
                                            <NavLink
                                                className="u-sidebar--account__list-link"
                                                to={`${routes.AUTHENTICATED}${routes.DASHBOARD}${routes.SETTINGS}`}
                                                onClick={togglingSideNav}
                                                activeClassName="active"
                                            >
                                                <span className="fas fa-tasks u-sidebar--account__list-icon mr-2" />
                                                {settings_link}
                                            </NavLink>
                                        </li>
                                        <li className="u-sidebar--account__list-item">
                                            <NavLink
                                                className="u-sidebar--account__list-link"
                                                to={`${routes.AUTHENTICATED}${routes.ALERTS}`}
                                                onClick={togglingSideNav}
                                                activeClassName="active"
                                            >
                                                <span className="fas fa-layer-group u-sidebar--account__list-icon mr-2" />
                                                {alerts_link}
                                                <span className="badge badge-danger float-right mt-1">
                                                    3
                                                </span>
                                            </NavLink>
                                        </li>
                                        <li className="u-sidebar--account__list-item">
                                            <span
                                                className="u-sidebar--account__list-link"
                                                style={{ cursor: 'pointer' }}
                                                onClick={handleLogout}
                                            >
                                                <span className="fas fa-sign-out-alt u-sidebar--account__list-icon mr-2" />
                                                {logout_link}
                                            </span>
                                        </li>
                                    </ul>
                                    <div className="u-sidebar--account__list-divider" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </SideNavClickWrapper>
    );
};

const mapStateToProps = ({ app, user }) => {
    const { sideNav, pristine } = app;
    const { userData } = user;
    return { sideNav, pristine, userData };
};

export default withRouter(
    connect(mapStateToProps, { togglingSideNav, enableNavButton, logoutUser })(
        SideNavigation
    )
);
