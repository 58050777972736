import {
    CHECK_USER_AUTHENTICATED,
    USER_AUTHENTICATED_SUCCESS,
    USER_AUTHENTICATED_FAILURE,
    LOGIN_USER_EMAIL_PASSWORD,
    LOGIN_USER_FAILURE,
    LOGOUT_USER_SUCCESS,
    CLEAR_AUTH_ERROR,
    NON_AUTH_RESET_USER_PASSWORD,
    NON_AUTH_RESET_USER_PASSWORD_SUCCESS,
    NON_AUTH_RESET_USER_PASSWORD_FAILURE,
    RESET_PASSWORD_REQUEST_STATE,
    EXECUTE_RESET_PASSWORD_FLIGHT,
    ACTION_CODE_VALID,
    ACTION_CODE_INVALID,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    authUser: null,
    loading: false,
    error: null,
    resetPasswordComplete: false,
    validActionCode: null,
    validatingCode: true,
    resetPasswordSuccess: false,
    resetPasswordFailure: false
};

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CHECK_USER_AUTHENTICATED:
            return {
                ...state,
                loading: true
            };
        case USER_AUTHENTICATED_SUCCESS:
            return {
                ...state,
                loading: false,
                authUser: action.payload,
                error: null
            };
        case USER_AUTHENTICATED_FAILURE:
            return {
                ...state,
                loading: false,
                authUser: null
            };
        case LOGIN_USER_EMAIL_PASSWORD:
            return {
                ...state,
                loading: true
            };
        case LOGIN_USER_FAILURE:
            return {
                ...state,
                loading: false,
                authUser: null,
                error: action.payload
            };
        case LOGOUT_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                authUser: null,
                error: null
            };
        case CLEAR_AUTH_ERROR:
            return {
                ...state,
                error: null
            };
        case NON_AUTH_RESET_USER_PASSWORD:
            return {
                ...state,
                loading: true
            };
        case NON_AUTH_RESET_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                resetPasswordComplete: true
            };
        case NON_AUTH_RESET_USER_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                resetPasswordComplete: true
            };
        case RESET_PASSWORD_REQUEST_STATE:
            return {
                ...state,
                loading: false,
                resetPasswordComplete: false,
                resetPasswordFailure: false,
                resetPasswordSuccess: false
            };
        case EXECUTE_RESET_PASSWORD_FLIGHT:
            return {
                ...state,
                loading: true
            };
        case ACTION_CODE_VALID:
            return {
                ...state,
                validActionCode: true,
                validatingCode: false
            };
        case ACTION_CODE_INVALID:
            return {
                ...state,
                validActionCode: false,
                validatingCode: false
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                resetPasswordSuccess: true
            };
        case RESET_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                resetPasswordFailure: true
            };
        default:
            return state;
    }
};

export default authReducer;
