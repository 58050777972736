import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Assets
import PDF from '../assets/svg/PDF.svg';

// Utils
import { confirmationDialogTypes } from '../utils/Constants';

// Redux
import { uploadStorageDocument } from '../store/actions/Documents';

// Components
import NewDocumentHeader from '../components/Documents/NewDocumentHeader';
import NewDocumentUpload from '../components/common/NewDocumentUpload';
import ActionButtons from '../components/Settings/shared/ActionButtons';
import ConfirmationDialog from '../components/common/ConfirmationDialog';

// Context
import { useLang } from '../context/LangContext';

import * as routes from '../router/config/routes';

const NewDocument = ({ userData, uploadStorageDocument }) => {
    const history = useHistory();
    const [doc, setDoc] = useState(null);
    const library =
        `${routes.AUTHENTICATED}${routes.DASHBOARD}${routes.LIBRARY}` ===
        history.location;

    const { success_content_text, loading_title_text, loading_content_text } =
        useLang()['Common']['DocumentSuccessDialog'];
    const {
        drag_click_label,
        add_button_label,
        cancel_button_label,
        add_title_label,
        optional_label,
        title_placeholder,
        buyer_label,
        seller_label,
        other_label,
        doc_type_label
    } = useLang()['Common']['AddDocumentModal'];

    const initialValues = {
        path: '',
        title: '',
        type: ''
    };

    const validationSchema = Yup.object().shape({
        path: Yup.string().required('Document is required!'),
        title: Yup.string(),
        type: Yup.string().required('Type is required. Choose one')
    });

    return (
        <>
            <ConfirmationDialog
                successContentText={success_content_text}
                loadingTitleText={loading_title_text}
                loadingContentText={loading_content_text}
                successHandler={() => history.goBack()}
                modalDialogType={confirmationDialogTypes.success}
            />
            <div className="row">
                <NewDocumentHeader />
            </div>
            <main id="content" role="main" className="push-main m-5">
                <div className="container p-5 new-document--container">
                    <Formik
                        validateOnMount
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={values => {
                            uploadStorageDocument({
                                src: doc,
                                uploader_id: userData.id,
                                org_id: userData.active_org_id,
                                upload_type: values.type,
                                type: 'application/pdf',
                                title: values.title === '' ? null : values.title,
                                fillable: false,
                                library: library
                            });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleSubmit,
                            setFieldValue
                        }) => (
                            <Form>
                                <div className="d-flex">
                                    {doc ? (
                                        <div className="row p-0 m-0">
                                            <div className="col-4 pl-0">
                                                <img
                                                    src={PDF}
                                                    alt="Pdf placeholder"
                                                    style={{
                                                        width: '120px',
                                                        height: '120px'
                                                    }}
                                                />
                                            </div>
                                            <div className="col-8 mt-3 pl-4">
                                                <div className="doc-props__label">
                                                    Name:{' '}
                                                    <span className="doc-props__value">
                                                        {doc.name}
                                                    </span>
                                                </div>
                                                <div className="doc-props__label">
                                                    Size:{' '}
                                                    <span className="doc-props__value">
                                                        {doc.size} KB
                                                    </span>
                                                </div>
                                                <div>
                                                    <button
                                                        className="btn btn-link text-danger p-0 mt-4"
                                                        type="button"
                                                        onClick={() => setDoc(null)}
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="row pl-4 pr-4">
                                            <NewDocumentUpload
                                                setDoc={setDoc}
                                                setFieldValue={setFieldValue}
                                                dragClickLabel={drag_click_label}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="row d-flex flex-column mt-8 pl-4 pr-4">
                                    <label
                                        htmlFor="title"
                                        className="new-document--heading"
                                    >
                                        {add_title_label}{' '}
                                        <span style={{ textTransform: 'capitalize' }}>
                                            {optional_label}
                                        </span>
                                    </label>
                                    <input
                                        id="title"
                                        name="title"
                                        type="text"
                                        value={values.title}
                                        onChange={handleChange}
                                        placeholder={title_placeholder}
                                        className="form-control w-50"
                                    />
                                </div>
                                <div className="row d-flex flex-column mt-5 pl-4 pr-4">
                                    <p className="new-document--heading">
                                        {doc_type_label}{' '}
                                        <span style={{ color: '#f00' }}>*</span>
                                    </p>
                                    <div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                id="buyer"
                                                type="radio"
                                                name="type"
                                                className="form-check-input"
                                                value="buyer"
                                                onChange={handleChange}
                                            />
                                            <label
                                                htmlFor="buyer"
                                                className="form-check-label"
                                            >
                                                {buyer_label}
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                id="seller"
                                                type="radio"
                                                name="type"
                                                className="form-check-input"
                                                value="seller"
                                                onChange={handleChange}
                                            />
                                            <label
                                                htmlFor="seller"
                                                className="form-check-label"
                                            >
                                                {seller_label}
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                id="other"
                                                type="radio"
                                                name="type"
                                                className="form-check-input"
                                                value="other"
                                                onChange={handleChange}
                                            />
                                            <label
                                                htmlFor="other"
                                                className="form-check-label"
                                            >
                                                {other_label}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <ActionButtons
                                    saveLabel={add_button_label}
                                    cancelLabel={cancel_button_label}
                                    handleSubmit={handleSubmit}
                                    handleClose={() => history.goBack()}
                                    disabled={errors.type || !doc}
                                />
                            </Form>
                        )}
                    </Formik>
                </div>
            </main>
        </>
    );
};

const mapStateToProps = ({ user }) => {
    const { userData } = user;
    return { userData };
};

export default withRouter(
    connect(mapStateToProps, { uploadStorageDocument })(NewDocument)
);
