import {
    // SET_APP_LANGUAGE_TYPE,
    SET_USER_COLLECTION,
    SET_LANGUAGE_BY_LOCATION,
    SET_APP_LANGUAGE_TYPE_SUCCESS,
    SET_APP_LANGUAGE_TYPE_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    origin: 'en'
};

const langReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        // case SET_APP_LANGUAGE_TYPE:
        //     return {
        //         ...state,
        //         origin: action.payload
        //     };
        case SET_USER_COLLECTION:
            return {
                ...state,
                origin: action.payload.language
            };
        case SET_LANGUAGE_BY_LOCATION:
            return {
                ...state
                // origin: action.payload.language
            };
        case SET_APP_LANGUAGE_TYPE_SUCCESS:
            return {
                ...state
                // origin: action.payload.language
            };
        case SET_APP_LANGUAGE_TYPE_FAILURE:
            return {
                ...state,
                origin: 'en'
            };
        default:
            return state;
    }
};

export default langReducer;
