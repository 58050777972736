// React
import React from 'react';

// Components
import {
    Dashboard,
    TransactionDetails,
    NewTransaction,
    NewDocument,
    Alerts,
    Preferences,
    Profile,
    ContactDetails,
    PdfViewer,
    EditTransaction
} from '../pages';

// Packages
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

//Router
import * as routes from '../router/config/routes';

const AuthenticatedRouter = props => {
    return (
        <Switch>
            <Redirect
                exact
                from={`${props.match.url}`}
                to={`${props.match.url}${routes.DASHBOARD}`}
            />
            <Route
                authUser={props.authUser}
                path={`${props.match?.url}${routes.DASHBOARD}`}
                render={props => <Dashboard {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.TRANSACTION_DETAIL}`}
                render={props => <TransactionDetails {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.EDIT_TRANSACTION}`}
                render={props => <EditTransaction {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.CONTACT_DETAIL}`}
                render={props => <ContactDetails {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.NEW_TRANSACTION}`}
                render={props => <NewTransaction {...props} />}
            />
            <Route
                exact
                path={`${props.match.url}${routes.NEW_DOCUMENT}`}
                render={props => <NewDocument {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.ALERTS}`}
                render={props => <Alerts {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.PREFERENCES}`}
                render={props => <Preferences {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.PROFILE}`}
                render={props => <Profile {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.VIEW_DOCUMENT}`}
                render={props => <PdfViewer {...props} />}
            />
        </Switch>
    );
};

export default withRouter(AuthenticatedRouter);
