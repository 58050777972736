import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import cx from 'classnames';

const StatusDropdown = ({ statuses, options, form: { values }, onChange }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle
                style={{
                    width: '100%',
                    borderRadius: '6px',
                    padding: '5px 10px'
                }}
                className="d-flex justify-content-between align-items-center settings-dropdown-toggle"
                caret
            >
                <span
                    style={{ textTransform: 'capitalize' }}
                    className={cx({
                        'office-status': statuses,
                        'office-status--active': values?.officeStatus === 'Active',
                        'office-status--inactive': values?.officeStatus === 'Inactive'
                    })}
                >
                    {values?.officeStatus || values?.officeType}
                </span>
            </DropdownToggle>
            <DropdownMenu className="w-100 p-0" right>
                {options?.map(option => (
                    <DropdownItem
                        className={cx({
                            'office-status office-status-item ': statuses,
                            'office-status--active': option.name === 'Active',
                            'office-status--inactive': option.name === 'Inactive'
                        })}
                        style={{
                            textTransform: 'capitalize'
                        }}
                        key={option.name}
                        onClick={() => {
                            onChange(option.name);
                        }}
                    >
                        {option.name}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
};

export default StatusDropdown;
