import React, { useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { UncontrolledTooltip } from 'reactstrap';

// Components
import TextField from '../../common/TextField';
import PhonesField from '../shared/PhonesField';
import ActionButtons from '../shared/ActionButtons';
import OfficeAddress from './OfficeAddress';

// Utils
import { useLang } from '../../../context/LangContext';
import { phoneRegExp } from '../../../utils/Constants';

const Office = ({ handleOfficeChange, organization, location, officeLang }) => {
    const { save_label, cancel_label } = useLang()['Common']['ActionButtons'];
    const { errorMessage } = useLang()['Constants'];
    const { labels, placeholders } = officeLang || {};
    const [logo, setLogo] = useState('');

    const initialValues = {
        officeLogo: organization?.logo_url || '',
        officeName: location?.office_name || '',
        phones: location?.phone || [],
        officeMLS: location?.mls_id || '',
        address:
            `${location?.address_1}, ${location?.city} ${location?.state} ${location?.zip}` ||
            '',
        officeOptionalAddress: location?.address_2 || '',
        officeCity: location?.city || '',
        officeState: location?.state || '',
        officeZip: location?.zip || ''
    };

    const validationSchema = Yup.object().shape({
        officeLogo: Yup.string(),
        officeName: Yup.string(),
        officeMLS: Yup.string(),
        phones: Yup.array().of(
            Yup.object().shape({
                number: Yup.string()
                    .matches(phoneRegExp.format, errorMessage.phone.valid)
                    .required(errorMessage.phone.required)
            })
        ),
        address: Yup.string()
    });

    return (
        <>
            <div className="container pr-5">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={values => console.log(values)}
                >
                    {({
                        values,
                        errors,
                        dirty,
                        touched,
                        handleBlur,
                        handleChange,
                        setFieldValue,
                        setFieldTouched
                    }) => (
                        <Form
                            className="d-flex flex-column org-info"
                            style={{ gap: '25px' }}
                        >
                            <div className="row d-flex align-items-center">
                                <div className="col-4 d-flex align-items-center">
                                    {officeLang?.labels?.officeLogo}
                                    <i
                                        style={{
                                            color: 'rgb(140, 152, 164)',
                                            marginLeft: '10px'
                                        }}
                                        className="far fa-info-circle"
                                        id="infoIcon"
                                    />
                                    <UncontrolledTooltip
                                        placement="right"
                                        target="infoIcon"
                                    >
                                        {officeLang?.labels?.officeLogo_tooltip}
                                    </UncontrolledTooltip>
                                </div>
                                {values.officeLogo ? (
                                    <div className="col-3">
                                        <img
                                            className="settings-upload__logo"
                                            src={values.officeLogo}
                                            alt="Office logo"
                                        />
                                    </div>
                                ) : (
                                    logo && (
                                        <div className="col-3">
                                            <img
                                                className="settings-upload__logo"
                                                src={logo}
                                                alt="Office logo"
                                            />
                                        </div>
                                    )
                                )}
                                <div className="col-5 d-flex align-items-center settings-upload">
                                    <label className="mb-0 settings-upload__label">
                                        {labels?.uploadPhoto}
                                        <TextField
                                            name="officeLogo"
                                            className="form-control w-100"
                                            type="file"
                                            accept="image/*"
                                            onChange={e => {
                                                if (e.target.files) {
                                                    if (values.officeLogo)
                                                        setFieldValue('officeLogo', '');

                                                    setLogo(
                                                        URL.createObjectURL(
                                                            e.target.files[0]
                                                        )
                                                    );
                                                }
                                            }}
                                        />
                                    </label>
                                    <button
                                        className="ml-2"
                                        type="button"
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            color: '#de4437'
                                        }}
                                        onClick={() => {
                                            if (values.officeLogo)
                                                setFieldValue('officeLogo', '');
                                            setLogo('');
                                        }}
                                    >
                                        {labels?.remove}
                                    </button>
                                </div>
                            </div>
                            <div className="row d-flex align-items-center">
                                <div className="col-4">{labels?.officeName}</div>
                                <div className="col-8">
                                    <TextField
                                        name="officeName"
                                        className="form-control w-100"
                                        type="text"
                                        placeholder={placeholders?.enterName}
                                        error={errors.officeName}
                                        value={values.officeName}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        invalid={!!errors.officeName && dirty}
                                        hasDefaultFill
                                    />
                                </div>
                            </div>
                            <div className="row d-flex align-items-center">
                                <div className="col-4">{labels?.officeMLS}</div>
                                <div className="col-8">
                                    <TextField
                                        name="officeMLS"
                                        className="form-control w-100"
                                        type="text"
                                        placeholder={placeholders?.enterId}
                                        error={errors.officeMLS}
                                        value={values.officeMLS}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={!!errors.officeMLS && (dirty || touched)}
                                        hasDefaultFill
                                    />
                                </div>
                            </div>
                            <div className="row d-flex align-items-center">
                                <div className="col-4 align-self-start">
                                    {labels?.officePhone}
                                </div>
                                <div className="col-8">
                                    <FieldArray name="phones" component={PhonesField} />
                                </div>
                            </div>
                            <div className="row d-flex align-items-center">
                                <div className="col-4 align-self-start">
                                    {labels?.officeAddress}
                                </div>
                                <div className="col-8">
                                    <OfficeAddress
                                        values={values}
                                        setFieldTouched={setFieldTouched}
                                        setFieldValue={setFieldValue}
                                        handleChange={handleChange}
                                        placeholder={placeholders?.enterAddress}
                                    />
                                </div>
                            </div>
                            <ActionButtons
                                handleClose={() => handleOfficeChange(null, null)}
                                saveLabel={save_label}
                                cancelLabel={cancel_label}
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default Office;
