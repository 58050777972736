/////////////////////////////// APP //////////////////////////////
export const TOGGLE_SIDE_NAV = 'toggle_side_nav';
export const ENABLING_NAV_BUTTON = 'enabling_nav_button';

////////////////////////////// Auth //////////////////////////////
export const CHECK_USER_AUTHENTICATED = 'check_user_authenticated';
export const USER_AUTHENTICATED_SUCCESS = 'user_authenticated_success';
export const USER_AUTHENTICATED_FAILURE = 'user_authenticated_failure';
export const LOGIN_USER_EMAIL_PASSWORD = 'login_user_email_password';
export const LOGIN_USER_FAILURE = 'login_user_failure';
export const LOGOUT_USER = 'logout_user';
export const LOGOUT_USER_SUCCESS = 'logout_user_success';
export const CLEAR_AUTH_ERROR = 'clear_auth_error';
export const RESET_USER_PASSWORD = 'reset_user_password';
export const NON_AUTH_RESET_USER_PASSWORD = 'non_auth_reset_user_password';
export const NON_AUTH_RESET_USER_PASSWORD_SUCCESS =
    'non_auth_reset_user_password_success';
export const NON_AUTH_RESET_USER_PASSWORD_FAILURE =
    'non_auth_reset_user_password_failure';
export const RESET_PASSWORD_REQUEST_STATE = 'reset_password_request_state';
export const EXECUTE_RESET_PASSWORD_FLIGHT = 'execute_reset_password_flight';
export const VERIFY_ACTION_CODE = 'verify_action_code';
export const ACTION_CODE_VALID = 'action_code_valid';
export const ACTION_CODE_INVALID = 'action_code_invalid';
export const RESET_PASSWORD_SUCCESS = 'reset_password_success';
export const RESET_PASSWORD_FAILURE = 'reset_password_failure';

////////////////////////// Transactions //////////////////////////
export const GET_TRANSACTION_LIST = 'get_transaction_list';
export const SET_TRANSACTION_LIST = 'set_transaction_list';
export const GET_USER_TRANSACTIONS = 'get_user_transactions';
export const SET_USER_TRANSACTIONS = 'set_user_transactions';
export const SET_USER_CLOSED_TRANSACTIONS = 'set_user_closed_transactions';
export const SET_USER_ARCHIVED_TRANSACTIONS = 'set_user_archived_transactions';
export const CREATE_TRANSACTION = 'create_transaction';
export const CREATE_TRANSACTION_SUCCESS = 'create_transaction_success';
export const CREATE_TRANSACTION_FAILURE = 'create_transaction_failure';
export const RESET_TRANSACTION_STATE = 'reset_transaction_state';
export const SET_PROPERTY_DATA = 'set_property_data';
export const UPDATE_TRANSACTION_NOTE = 'update_transaction_note';
export const UPDATE_TRANSACTION_NOTE_SUCCESS = 'update_transaction_note_success';
export const UPDATE_TRANSACTION_NOTE_FAILURE = 'update_transaction_note_failure';
export const UPDATE_TRANSACTION_TASK = 'update_transaction_task';
export const UPDATE_TRANSACTION_TASK_SUCCESS = 'update_transaction_task_success';
export const UPDATE_TRANSACTION_TASK_FAILURE = 'update_transaction_task_failure';
export const UPDATE_ACTIVITY_POST = 'update_activity_post';
export const UPDATE_ACTIVITY_POST_SUCCESS = 'update_activity_post_success';
export const UPDATE_ACTIVITY_POST_FAILURE = 'update_activity_post_failure';
export const SET_TRANSACTION_FILTER = 'set_transaction_filter';
export const SET_TRX_SEARCH_SUGGESTIONS = 'set_trx_search_suggestions';
export const GET_TRANSACTION_DETAILS = 'get_transaction_details';
export const SET_TRANSACTION_DETAILS = 'set_transaction_details';
export const RESET_DETAILS_FLAG = 'reset_details_flag';
export const CANCEL_DETAILS_LISTENER = 'cancel_details_listener';
export const UPDATE_TRANSACTION_STATUS = 'update_transaction_status';

////////////////////////////// User //////////////////////////////
export const GET_USER_COLLECTION = 'get_user_collection';
export const SET_USER_COLLECTION = 'set_user_collection';

////////////////////////////// Orgs //////////////////////////////
export const GET_USER_ORG = 'get_user_org';
export const SET_USER_ORG = 'set_user_org';

////////////////////////// Preferences ///////////////////////////
export const REMOVING_PREFERENCE_TASK = 'removing_preference_task';
export const ADDING_PREFERENCE_TASK = 'adding_preference_task';
export const REMOVING_PREFERENCE_TASK_SUCCESS = 'removing_preference_task_success';
export const REMOVING_PREFERENCE_TASK_FAILURE = 'removing_preference_task_failure';
export const ADDING_PREFERENCE_TASK_SUCCESS = 'adding_preference_task_success';
export const ADDING_PREFERENCE_TASK_FAILURE = 'adding_preference_task_failure';

//////////////////////////// Profile /////////////////////////////
export const UPDATE_USER_AVATAR = 'update_user_avatar';
export const UPDATE_USER_AVATAR_SUCCESS = 'update_user_avatar_success';
export const UPDATE_USER_AVATAR_FAILURE = 'update_user_avatar_failure';

///////////////////////// Super Admin ///////////////////////////
export const ADD_INTERNAL_USER = 'add_internal_user';
export const ADD_INTERNAL_USER_SUCCESS = 'add_internal_user_success';

///////////////////////// Property ///////////////////////////
export const GET_PROPERTY_MLS = 'get_property_mls';
export const GET_PROPERTY_MLS_SUCCESS = 'get_property_mls_success';
export const RESET_PROPERTY_DATA = 'reset_property_data';

///////////////////////// Documents //////////////////////////
export const GET_DOCUMENTS = 'get_documents';
export const SET_USER_DOCUMENTS = 'set_user_documents';
export const SET_ORG_DOCUMENTS = 'set_org_documents';
export const UPLOAD_STORAGE_DOCUMENT = 'upload_storage_document';
export const UPLOAD_STORAGE_DOCUMENT_SUCCESS = 'upload_storage_document_success';
export const UPLOAD_STORAGE_DOCUMENT_FAILURE = 'upload_storage_document_failure';
export const SET_DOCUMENT_FILTER = 'set_document_filter';
export const SET_DOC_SEARCH_SUGGESTIONS = 'set_doc_search_suggestions';
export const SET_DOCUMENT_LIST = 'set_document_list';
export const SET_LIBRARY_FILTER = 'set_library_filter';
export const SET_LIBRARY_LIST = 'set_library_list';
export const UPDATE_DOCUMENT = 'update_document';
export const REMOVE_DOCUMENT = 'remove_document';
export const ADD_DOCUMENT_TO_TRANSACTION = 'add_document_to_transaction';
export const REMOVE_DOCUMENT_FROM_TRANSACTION = 'remove_document_from_transaction';
export const REFRESH_TRANSACTION_DOCUMENTS = 'refresh_transaction_documents';
export const SET_TRANSACTION_DOCUMENTS_FILTER = 'set_transaction_documents_filter';

///////////////////////// Contacts //////////////////////////
export const GET_CONTACTS = 'get_contacts';
export const SET_USER_CONTACTS = 'set_user_contacts';
export const SET_ORG_CONTACTS = 'set_org_contacts';
export const SET_CONTACTS_FILTER = 'set_contacts_filter';
export const ADD_NEW_CONTACTS = 'add_new_contacts';
export const ADD_NEW_CONTACTS_SUCCESS = 'add_new_contacts_success';
export const ADD_NEW_CONTACTS_FAILURE = 'add_new_contacts_failure';
export const REMOVE_CONTACT = 'remove_contact';

///////////////////////// Langauge //////////////////////////
export const SET_APP_LANGUAGE_TYPE = 'set_app_language_type';
export const SET_APP_LANGUAGE_TYPE_SUCCESS = 'set_app_language_type_success';
export const SET_APP_LANGUAGE_TYPE_FAILURE = 'set_app_language_type_failure';
export const SET_LANGUAGE_BY_LOCATION = 'set_langauga_by_location';

/////////////////// Transaction Activity ////////////////////
export const SET_TRANSACTION_ACTIVITY = 'set_transaction_activity';
//////////////////////////  Modal  ///////////////////////////
export const SET_MODAL_TYPE = 'set_modal_type';
export const MODAL_CONFIRM = 'modal_confirm';
export const MODAL_CANCEL = 'modal_cancel';
