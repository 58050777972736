// React
import React from 'react';

// Assets
import logo from '../../assets/img/jada-logo.svg';

// Components
import { MainHeader } from '../index';

// Packages
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Redux - Actions, Reducers, Sagas
import { togglingSideNav } from '../../store/actions/App';
import { setLanguageType } from '../../store/actions/Lang';

// // Router
import * as routes from '../../router/config/routes';

const AppHeader = ({
    match,
    history,
    togglingSideNav,
    userData,
    disabledButton,
    setLanguageType
}) => {
    const homePath = `${routes.AUTHENTICATED}${routes.DASHBOARD}${routes.TRANSACTIONS}`;

    const setLanguage = () => {
        if (userData.language === 'es') {
            setLanguageType({ origin: 'en' });
        } else {
            setLanguageType({ origin: 'es' });
        }
    };

    if (!userData) return null;

    return (
        <header id="header" className="u-header u-header--sticky-top shadow-soft">
            <div className="u-header__section p-15">
                <div
                    id="logoAndNav"
                    className="container d-flex align-items-center justify-content-between"
                >
                    <nav className="navbar navbar-expand-md u-header__navbar w-100">
                        <Link
                            className="navbar-brand u-header__navbar-brand u-header__navbar-brand-center"
                            style={{
                                pointerEvents:
                                    homePath === history.location.pathname
                                        ? 'none'
                                        : 'auto'
                            }}
                            to={`${routes.AUTHENTICATED}${routes.DASHBOARD}`}
                        >
                            <img
                                src={logo}
                                className="logo"
                                style={{ height: '42px' }}
                                alt="jada"
                            />
                        </Link>

                        <div id="navBar" className="ml-auto">
                            <ul className="navbar-nav u-header__navbar-nav">
                                <li className="nav-item u-header__nav-item">
                                    <button
                                        id="sidebarNavToggler"
                                        className="btn btn-xs btn-text-secondary u-sidebar--account__toggle-bg target-of-invoker-has-unfolds"
                                        type="button"
                                        onClick={setLanguage}
                                        style={{
                                            padding: '0.43rem 1.95rem .43rem 0.5rem',
                                            marginRight: '-32px',
                                            backgroundColor: 'rgba(224, 224, 224, 1)'
                                        }}
                                    >
                                        <span className="position-relative">
                                            <span className="u-sidebar--account__toggle-text">
                                                {userData.language}
                                            </span>
                                        </span>
                                    </button>
                                </li>
                                <li className="nav-item u-header__nav-item">
                                    <button
                                        id="sidebarNavToggler"
                                        onClick={!disabledButton ? togglingSideNav : null}
                                        className="btn btn-xs btn-text-secondary u-sidebar--account__toggle-bg ml-1 target-of-invoker-has-unfolds"
                                        type="button"
                                        style={{
                                            backgroundColor: 'rgba(255, 255, 255, 1)',
                                            border: '1px solid #e0e0e0'
                                        }}
                                    >
                                        <span className="position-relative">
                                            <span className="u-sidebar--account__toggle-text">
                                                {`${userData?.first_name} ${userData?.last_name}`}
                                            </span>
                                            {userData?.user_avatar && (
                                                <img
                                                    src={userData.user_avatar}
                                                    className="u-sidebar--account__toggle-img"
                                                    alt={`${userData?.first_name} ${userData?.last_name}`}
                                                />
                                            )}
                                            <span className="badge badge-sm badge-danger badge-pos rounded-circle">
                                                2
                                            </span>
                                        </span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
            <MainHeader match={match} history={history} userData={userData} />
        </header>
    );
};

const mapStateToProps = ({ app, user }) => {
    const { userData } = user;
    const { sideNav, disabledButton } = app;
    return { userData, sideNav, disabledButton };
};

export default connect(mapStateToProps, { togglingSideNav, setLanguageType })(AppHeader);
