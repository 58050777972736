import {
    SET_USER_COLLECTION,
    SET_TRANSACTION_LIST,
    SET_USER_TRANSACTIONS,
    SET_USER_CLOSED_TRANSACTIONS,
    SET_USER_ARCHIVED_TRANSACTIONS,
    SET_PROPERTY_DATA,
    CREATE_TRANSACTION,
    CREATE_TRANSACTION_SUCCESS,
    // CREATE_TRANSACTION_FAILURE,
    RESET_TRANSACTION_STATE,
    UPDATE_TRANSACTION_NOTE,
    UPDATE_TRANSACTION_NOTE_SUCCESS,
    // UPDATE_TRANSACTION_NOTE_FAILURE,
    UPDATE_TRANSACTION_TASK,
    UPDATE_TRANSACTION_TASK_SUCCESS,
    UPDATE_TRANSACTION_TASK_FAILURE,
    UPDATE_ACTIVITY_POST,
    UPDATE_ACTIVITY_POST_SUCCESS,
    // UPDATE_ACTIVITY_POST_FAILURE,
    SET_TRANSACTION_FILTER,
    SET_TRX_SEARCH_SUGGESTIONS,
    GET_TRANSACTION_DETAILS,
    SET_TRANSACTION_DETAILS,
    RESET_DETAILS_FLAG,
    RESET_PROPERTY_DATA,
    CANCEL_DETAILS_LISTENER
} from '../actions/types';

const INITIAL_STATE = {
    detailsFlag: false,
    transactionList: 'grid',
    userTransactions: null,
    closedTransactions: null,
    archivedTransactions: null,
    propertyData: null,
    writingTrx: null,
    writingTrxSuccess: null,
    writingTrxFailure: null,
    newTrxId: null,
    loading: false,
    filterType: {
        name: 'All Active',
        value: 'active'
    },
    filteredTransactions: null,
    searchSuggestions: [],
    transactionDetails: null,
    uploading: null,
    uploadError: null
};
const trxFilter = (transactions, filter) => {
    const initTransactions = [...transactions];
    if (filter.value !== 'active' && filter.value !== 'closed') {
        const filtered = initTransactions.filter(
            trx => trx.type === filter.value && trx.status !== 'closed'
        );
        return filtered;
    } else {
        const filtered = transactions.filter(trx => trx.status === filter.value);
        return filtered;
    }
};

const transactionsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER_COLLECTION:
            return {
                ...state,
                filterType:
                    action.payload.language === 'en'
                        ? { name: 'All Active', value: 'active' }
                        : { name: 'Todo Activo', value: 'active' }
            };
        case SET_TRANSACTION_LIST:
            return {
                ...state,
                transactionList: action.payload
            };
        case SET_USER_TRANSACTIONS:
            return {
                ...state,
                userTransactions: action.payload,
                filteredTransactions: action.payload.length
                    ? trxFilter(action.payload, state.filterType)
                    : []
            };
        case SET_USER_CLOSED_TRANSACTIONS:
            return {
                ...state,
                closedTransactions: action.payload
            };
        case SET_USER_ARCHIVED_TRANSACTIONS:
            return {
                ...state,
                archivedTransactions: action.payload
            };
        case SET_PROPERTY_DATA:
            return {
                ...state,
                propertyData: action.payload
            };
        case CREATE_TRANSACTION:
            return {
                ...state,
                writingTrx: true
            };
        case CREATE_TRANSACTION_SUCCESS:
            return {
                ...state,
                writingTrxSuccess: true,
                newTrxId: action.payload
            };
        case RESET_TRANSACTION_STATE:
            return {
                ...state,
                writingTrx: INITIAL_STATE.writingTrx,
                writingTrxSuccess: INITIAL_STATE.writingTrxSuccess,
                writingTrxFailure: INITIAL_STATE.writingTrxFailure,
                listingAddress: INITIAL_STATE.listingAddress,
                buyingAddress: INITIAL_STATE.buyingAddress
            };
        case UPDATE_TRANSACTION_NOTE:
            return {
                ...state,
                loading: true
            };
        case UPDATE_TRANSACTION_NOTE_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case UPDATE_TRANSACTION_TASK:
            return {
                ...state,
                loading: true,
                uploading: true
            };
        case UPDATE_TRANSACTION_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                uploading: false,
                uploadError: false
            };
        case UPDATE_TRANSACTION_TASK_FAILURE:
            return {
                ...state,
                loading: false,
                uploading: false,
                uploadError: true
            };
        case UPDATE_ACTIVITY_POST:
            return {
                ...state,
                loading: true,
                uploading: true
            };
        case UPDATE_ACTIVITY_POST_SUCCESS:
            return {
                ...state,
                loading: false,
                uploading: false,
                uploadError: false
            };
        case SET_TRANSACTION_FILTER:
            return {
                ...state,
                filteredTransactions: action.payload.filtered,
                filterType: action.payload.filter
            };
        case SET_TRX_SEARCH_SUGGESTIONS:
            return {
                ...state,
                searchSuggestions: action.payload
            };
        case GET_TRANSACTION_DETAILS:
            return {
                ...state,
                loading: true,
                detailsFlag: false,
                transactionDetails: INITIAL_STATE.transactionDetails
            };
        case SET_TRANSACTION_DETAILS:
            return {
                ...state,
                transactionDetails: action.payload.transactionDetails,
                loading: false,
                detailsFlag: action.payload.detailsFlag
            };
        case RESET_DETAILS_FLAG:
            return {
                ...state,
                detailsFlag: false
            };
        case RESET_PROPERTY_DATA:
            return {
                ...state,
                detailsFlag: false,
                transactionDetails: INITIAL_STATE.transactionDetails
            };
        case CANCEL_DETAILS_LISTENER:
            return {
                ...state,
                detailsFlag: false,
                transactionDetails: INITIAL_STATE.transactionDetails
            };
        default:
            return state;
    }
};

export default transactionsReducer;
