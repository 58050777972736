// React
import React, { useState, useEffect, useMemo } from 'react';

// Constants
import { unitedStates, lookupRegExp } from '../../utils/Constants';

// Context
import { useLang } from '../../context/LangContext';

// Loggers
import { log } from '../../utils/Loggers';

// Packages
import SmartyStreetsSDK from 'smartystreets-javascript-sdk';
import { connect } from 'react-redux';

// Redux - Actions, Reducers, Sagas
import { setPropertyData } from '../../store/actions/Transactions';
import { getMlsData } from '../../store/actions/Property';

// Router
import { useHistory } from 'react-router-dom';
import * as routes from '../../router/config/routes';
import { withRouter } from 'react-router-dom';

const SmartyStreetsCore = SmartyStreetsSDK.core;
const Lookup = SmartyStreetsSDK.usAutocomplete.Lookup;
const Address = SmartyStreetsSDK.usStreet.Lookup;

const BuyerSelectForm = props => {
    const key = process.env.REACT_APP_SMARTY_WEBSITE_KEY;
    const credentials = new SmartyStreetsCore.SharedCredentials(key);
    const lookupClient = SmartyStreetsCore.buildClient.usAutocomplete(credentials);
    const addressClient = SmartyStreetsCore.buildClient.usStreet(credentials);
    const [addressInputField, setAddressInputField] = useState('');
    const [fullAddress, setFullAddress] = useState('');
    const [manualAddress, setManualAddress] = useState(false);
    const [lookupResults, setLookupResults] = useState(null);
    const [propertyObject, setPropertyObject] = useState(null);
    const [mlsNumber, setMlsNumber] = useState('');
    const [mlsNumberHold, setMlsNumberHold] = useState('');
    const [gettingPropertyData, setGettingPropertyData] = useState(false);
    const [propertyFound, setPropertyFound] = useState('');
    const history = useHistory();
    const {
        match,
        setPropertyData,
        getMlsData,
        loading,
        propertyData,
        setShouldConfirmDisplayed
    } = props;
    const {
        select_label,
        property_info_label,
        mls_option_label,
        address_option_label,
        property_address_label,
        city_label,
        unit_label,
        state_label,
        zip_label,
        mls_number_label,
        property_found_label,
        property_not_found_label,
        address_placeholder,
        unit_placeholder,
        city_placeholder,
        zip_placeholder,
        button_label,
        mls_number_placeholder,
        mls_button_label
    } = useLang()['NewTransaction']['BuyerSelectForm'];
    const [manualAddressFields, setManualAddressFields] = useState({
        unit: '',
        city: '',
        state: { select_label },
        zip: '',
        country: 'US'
    });

    useEffect(() => {
        if (!loading) {
            if (gettingPropertyData) {
                setGettingPropertyData(!gettingPropertyData);
                if (propertyData && Object.keys(propertyData).length) {
                    const {
                        unit,
                        city,
                        state_code,
                        postal_code,
                        street_number,
                        street_direction,
                        street_name,
                        street_post_direction,
                        street_suffix,
                        state,
                        line
                    } = propertyData.location.address;
                    const formatUnit = unit ? unit.split(' ') : null;
                    const address1 = `${street_number ? street_number + ' ' : ''}${
                        street_direction ? street_direction + ' ' : ''
                    }${street_name ? street_name + ' ' : ''}${
                        street_suffix ? street_suffix + ' ' : ''
                    }${street_post_direction ? street_post_direction + ' ' : ''}`;

                    setPropertyFound('true');
                    setPropertyObject({
                        ...propertyData,
                        location: {
                            ...propertyData.location,
                            address: {
                                ...propertyData.location.address,
                                address_1: address1,
                                unit_number: unit ? formatUnit[1] : null
                            }
                        }
                    });
                    setFullAddress(`${line} ${city}, ${state} ${postal_code}`);
                    setAddressInputField(address1);
                    setManualAddressFields({
                        unit,
                        city,
                        state: state_code,
                        zip: postal_code,
                        country: 'US'
                    });
                } else {
                    setPropertyFound('false');
                }
            }
        }
    }, [loading, propertyData, gettingPropertyData]);

    const getFullState = abrevState => {
        const unitedState = unitedStates.find(state => state.value === abrevState);
        return unitedState.name;
    };

    const propertyDataHandler = () => {
        setGettingPropertyData(!gettingPropertyData);
        getMlsData({ mlsNumber });
        setMlsNumberHold(mlsNumber);
    };

    const mlsNumberHandler = number => {
        setMlsNumber(number);
        if (propertyData && Object.keys(propertyData).length) {
            if (number !== mlsNumberHold) {
                setPropertyFound('');
            } else {
                setPropertyFound('true');
            }
        } else {
            if (mlsNumberHold.length) {
                if (number !== mlsNumberHold) {
                    setPropertyFound('');
                } else {
                    setPropertyFound('false');
                }
            }
        }
    };

    const addressInputHandler = address => {
        if (lookupRegExp.valid.test(address)) {
            const lookup = new Lookup(address);
            lookup.maxSuggestions = 6;
            lookupClient
                .send(lookup)
                .then(results => {
                    const { result } = results;
                    if (result.length) {
                        setLookupResults(result);
                    } else {
                        setLookupResults(result);
                        setPropertyObject(null);
                    }
                })
                .catch(error => {
                    log(
                        'Smarty Streets Error: error in address lookup results (BUYER_TRX)',
                        {
                            error,
                            address,
                            function: 'addressInputHandler'
                        }
                    );
                });
        } else {
            setLookupResults(null);
        }
        setAddressInputField(address);
    };

    const getAddressFromLookup = address => {
        const batch = new SmartyStreetsCore.Batch();
        const verifiedAddress = new Address();
        verifiedAddress.street = address.streetLine;
        verifiedAddress.urbanization = ''; // Only applies to Puerto Rico addresses
        verifiedAddress.city = address.city;
        verifiedAddress.state = address.state;
        verifiedAddress.maxCandidates = 3;
        verifiedAddress.match = 'invalid';
        batch.add(verifiedAddress);
        addressClient
            .send(batch)
            .then(result => {
                const { components, metadata } = result.lookups[0].result[0] || null;
                const address1 = `${components.primaryNumber}${
                    components.streetPredirection
                        ? ' ' + components.streetPredirection
                        : ''
                }${components.streetName ? ' ' + components.streetName : ''}${
                    components.streetSuffix ? ' ' + components.streetSuffix : ''
                }${
                    components.streetPostdirection
                        ? ' ' + components.streetPostdirection
                        : ''
                } `;
                const address2 = components.secondaryNumber
                    ? `${components.secondaryDesignator} ${components.secondaryNumber} `
                    : '';
                const cityStateZip = `${components.cityName}, ${components.state} ${components.zipCode}`;
                const fullAddress = `${address1}${address2}${cityStateZip}`;

                if (result.lookups[0].result[0]) {
                    const address = {
                        location: {
                            address: {
                                address_1: address1,
                                city: components.cityName,
                                coordinate: {
                                    lat: metadata.latitude,
                                    lon: metadata.longitude
                                },
                                country: 'US',
                                line: fullAddress,
                                postal_code: components.zipCode,
                                state: getFullState(components.state),
                                state_code: components.state,
                                street_direction: components.streetPredirection,
                                street_name: components.streetName,
                                street_number: components.primaryNumber,
                                street_post_direction: components.streetPostDirection,
                                street_suffix: components.streetSuffix,
                                unit: components.secondaryNumber
                                    ? `${components.secondaryDesignator} ${components.secondaryNumber}`
                                    : null,
                                unit_number: components.secondaryNumber
                                    ? components.secondaryNumber
                                    : null,
                                validation_code: ''
                            },
                            city: null,
                            county: {
                                name: metadata.countyName,
                                fips_code: metadata.countyFips
                            },
                            cross_street: null,
                            driving_directions: null,
                            neighborhoods: null,
                            parcel: { boundry: { coordinates: null }, type: null }
                        }
                    };
                    setFullAddress(fullAddress);
                    setPropertyObject({
                        ...address
                    });
                    setManualAddressFields({
                        unit: components.secondaryNumber
                            ? components.secondaryNumber
                            : '',
                        city: components.cityName,
                        state: components.state,
                        zip: components.zipCode,
                        country: 'US'
                    });

                    if (manualAddress) {
                        setAddressInputField(address1);
                    } else {
                        setAddressInputField(fullAddress);
                    }
                    setLookupResults(null);
                }
            })
            .catch(error => {
                log('Smarty Streets Error: error in address lookup details (BUYER_TRX)', {
                    error,
                    address,
                    function: 'getAddressFromLookup'
                });
            });
    };

    const isDisabled = useMemo(() => {
        const dataEmpty = propertyData ? Object.keys(propertyData).length === 0 : true;
        const address =
            addressInputField === '' ||
            manualAddressFields.city === '' ||
            manualAddressFields.state === '' ||
            manualAddressFields.state === select_label ||
            manualAddressFields.zip === '';
        const mlsMismatch = mlsNumberHold ? mlsNumberHold !== mlsNumber : true;
        const disabled = address && (mlsMismatch || dataEmpty);
        return disabled;
    }, [
        addressInputField,
        manualAddressFields,
        mlsNumber,
        mlsNumberHold,
        propertyData,
        select_label
    ]);

    useEffect(() => {
        if (!isDisabled) setShouldConfirmDisplayed(true);
    }, [isDisabled, setShouldConfirmDisplayed]);

    const setVerifiedAddress = () => {
        setPropertyData({
            ...propertyObject
        });
        history.push(`${match.url}${routes.BUYER}`);
    };

    const manualAddressHandler = type => {
        setManualAddress(!type);
        setLookupResults(null);
        if (propertyData && Object.keys(propertyData).length) {
            if (type) {
                setPropertyFound('true');
            } else {
                setPropertyFound('');
            }
        } else {
            setPropertyFound('');
            setMlsNumber('');
        }
    };

    return (
        <div
            style={{
                animation: 'fadeIn 1s'
            }}
        >
            <div className="mb-4">
                <div className="border-bottom pb-3 mb-5">
                    <h2 className="h6 text-secondary mb-0">{property_info_label}</h2>
                </div>
                {!manualAddress && (
                    <div
                        className="row"
                        style={{
                            animation: 'fadeIn .5s'
                        }}
                    >
                        <div className="col-md-12">
                            <div className="form-group">
                                <div className="js-focus-state">
                                    <label
                                        className="form-label"
                                        htmlFor="listingAddress"
                                    >
                                        {mls_number_label}
                                    </label>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="mls"
                                            id="mlsLookup"
                                            placeholder={mls_number_placeholder}
                                            aria-label={mls_number_placeholder}
                                            aria-describedby="mlsLookup"
                                            autoComplete="off"
                                            value={mlsNumber}
                                            onChange={e =>
                                                mlsNumberHandler(e.target.value)
                                            }
                                        />
                                        <div className="input-group-append">
                                            <button
                                                className="btn btn-primary"
                                                type="button"
                                                id="mlsLookupButton"
                                                onClick={propertyDataHandler}
                                                disabled={!mlsNumber.length}
                                            >
                                                {loading ? (
                                                    <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                ) : propertyFound === 'true' ? (
                                                    <span className="fas fa-check" />
                                                ) : (
                                                    `${mls_button_label}`
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {propertyFound === 'true' && (
                    <div
                        id="confirmProperty"
                        style={{
                            animation: 'fadeIn .5s'
                        }}
                    >
                        <div className="row mb-0">
                            <div className="col-md-12">
                                <span className="text-success">{`${property_found_label} - ${fullAddress}`}</span>
                            </div>
                        </div>
                    </div>
                )}
                {propertyFound === 'false' && (
                    <div
                        id="confirmProperty"
                        style={{
                            animation: 'fadeIn .5s'
                        }}
                    >
                        <div className="row mb-0">
                            <div className="col-md-12">
                                <span className="text-danger">
                                    {property_not_found_label}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-12 mb-3">
                        <div className="col-12 mb-0 text-center text-small">
                            <span
                                id="addressSearchBtn"
                                className="small text-muted pointer"
                                onClick={() => manualAddressHandler(manualAddress)}
                            >
                                <ins>
                                    {!manualAddress
                                        ? address_option_label
                                        : mls_option_label}
                                </ins>
                            </span>
                        </div>
                    </div>
                </div>
                {manualAddress && (
                    <div
                        id="addressSearch"
                        style={{
                            animation: 'fadeIn .5s'
                        }}
                    >
                        <div className="row">
                            <div className="col-md-8 mb-0">
                                {/* <!-- Input --> */}
                                <div className="form-group">
                                    <div className="js-focus-state">
                                        <label
                                            className="form-label"
                                            htmlFor="listingAddress"
                                        >
                                            {property_address_label}
                                        </label>
                                        <div
                                            className="input-group"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                flexWrap: 'nowrap',
                                                backgroundColor: '#fff!important'
                                            }}
                                        >
                                            <div
                                                className="input-group-prepend"
                                                style={{
                                                    maxHeight: 50,
                                                    backgroundColor: '#fff'
                                                }}
                                            >
                                                <span
                                                    className="input-group-text"
                                                    id="listingAddressLabel"
                                                    style={{ backgroundColor: '#fff' }}
                                                >
                                                    <span className="fas fa-map-marker-alt" />
                                                </span>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    flexGrow: 1,
                                                    flexWrap: 'nowrap',
                                                    backgroundColor: '#fff'
                                                }}
                                            >
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    style={{
                                                        borderTopLeftRadius: 0,
                                                        borderBottomLeftRadius: 0,
                                                        backgroundColor: '#fff'
                                                    }}
                                                    name="address"
                                                    id="listingAddress"
                                                    placeholder={address_placeholder}
                                                    value={addressInputField}
                                                    onChange={e =>
                                                        addressInputHandler(
                                                            e.target.value
                                                        )
                                                    }
                                                    aria-label={property_address_label}
                                                    aria-describedby="listingAddressLabel"
                                                    autoComplete="google-disable"
                                                />
                                                {lookupResults && lookupResults.length ? (
                                                    <ul className="auto-complete-container">
                                                        {lookupResults.map(result => (
                                                            <li
                                                                key={result.text}
                                                                className="auto-complete-item"
                                                                onClick={() => {
                                                                    getAddressFromLookup(
                                                                        result
                                                                    );
                                                                }}
                                                            >
                                                                {result.text}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-0">
                                <div className="form-group">
                                    <div className="js-focus-state">
                                        <label
                                            className="form-label"
                                            htmlFor="listingAddress2"
                                        >
                                            {unit_label}
                                        </label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span
                                                    className="input-group-text"
                                                    id="listingAddress2Label"
                                                >
                                                    <span className="fas fa-map-marker-alt" />
                                                </span>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="listingAddress2"
                                                id="listingAddress2"
                                                value={manualAddressFields.unit}
                                                onChange={e =>
                                                    setManualAddressFields({
                                                        ...manualAddressFields,
                                                        unit: e.target.value
                                                    })
                                                }
                                                placeholder={unit_placeholder}
                                                aria-label={unit_placeholder}
                                                aria-describedby="listingAddress2Label"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-0">
                                <div className="form-group">
                                    <div className="js-focus-state">
                                        <label
                                            className="form-label"
                                            htmlFor="listingCityProvince"
                                        >
                                            {city_label}
                                        </label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span
                                                    className="input-group-text"
                                                    id="listingCityProvinceLabel"
                                                >
                                                    <span className="fas fa-city" />
                                                </span>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="cityProvince"
                                                id="listingCityProvince"
                                                value={manualAddressFields.city}
                                                onChange={e =>
                                                    setManualAddressFields({
                                                        ...manualAddressFields,
                                                        city: e.target.value
                                                    })
                                                }
                                                placeholder={city_placeholder}
                                                aria-label={city_placeholder}
                                                aria-describedby="listingCityProvinceLabel"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mb-0">
                                <div className="form-group">
                                    <div className="js-focus-state">
                                        <label
                                            className="form-label"
                                            htmlFor="listingPostalCode"
                                        >
                                            {state_label}
                                        </label>
                                        <select
                                            className="form-control custom-select"
                                            onChange={e =>
                                                setManualAddressFields({
                                                    ...manualAddressFields,
                                                    state: e.target.value
                                                })
                                            }
                                            value={manualAddressFields.state}
                                        >
                                            <option>{select_label}</option>
                                            {unitedStates.map(option => (
                                                <option
                                                    key={option.name}
                                                    value={option.value}
                                                >
                                                    {option.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mb-0">
                                <div className="form-group">
                                    <div className="js-focus-state">
                                        <label
                                            className="form-label"
                                            htmlFor="listingPostalCode"
                                        >
                                            {zip_label}
                                        </label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span
                                                    className="input-group-text"
                                                    id="listingPostalCodeLabel"
                                                >
                                                    <span className="fas fa-envelope-open" />
                                                </span>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="ZipCode"
                                                id="listingPostalCode"
                                                value={
                                                    manualAddressFields?.zip
                                                        ? manualAddressFields?.zip
                                                        : ''
                                                }
                                                onChange={e =>
                                                    setManualAddressFields({
                                                        ...manualAddressFields,
                                                        zip: e.target.value
                                                    })
                                                }
                                                placeholder={zip_placeholder}
                                                aria-label={zip_placeholder}
                                                aria-describedby="listingZipCodeLabel"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="row mb-3">
                <div className="col-md-12">
                    <button
                        type="button"
                        className="btn btn-primary btn-block transition-3d-hover"
                        disabled={isDisabled}
                        onClick={setVerifiedAddress}
                    >
                        {button_label}
                        <span className="fas fa-chevron-right ml-2" />
                    </button>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = ({ property }) => {
    const { loading, propertyData } = property;
    return { loading, propertyData };
};

export default withRouter(
    connect(mapStateToProps, { setPropertyData, getMlsData })(BuyerSelectForm)
);
