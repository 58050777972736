// React
import React from 'react';

// Components
import { SideNavigation } from '../components';

// Packages
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';

// Pages
import { Landing, Login, ResetPassword, AuthenticationServices, Pitch } from '../pages';

//Router
import * as routes from '../router/config/routes';
import AuthenticatedRouter from '../router/AuthenticatedRouter';

// PrivateRoute Components makes sure a user who is not signed in/authenticated cannot visit the authenticated area like Dashboard Page...if they try and access Dashboard route they will be redirected to the Login Page
const PrivateRoute = ({ component: Component, authUser, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            authUser ? <Component {...props} /> : <Redirect to={routes.LOGIN} />
        }
    />
);

// PrevAuthedRoute Components makes sure a user who is signed in/authenticated cannot login again or sign up again...if they try and access the login route or sign up route they will be redirected to the Dashboard Page
const PrevAuthedRoute = ({ component: Component, authUser, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            !authUser ? <Component {...props} /> : <Redirect to={routes.AUTHENTICATED} />
        }
    />
);

const AppRouter = props => {
    const { match, history, authUser } = props;
    return (
        <Router>
            <Route
                exact
                path={routes.LANDING}
                match={match}
                history={history}
                component={() => <Landing authUser={authUser} />}
            />
            <Route
                exact
                path={routes.PITCH}
                match={match}
                history={history}
                component={() => <Pitch authUser={authUser} />}
            />
            <PrevAuthedRoute
                exact
                authUser={authUser}
                path={routes.LOGIN}
                component={props => <Login authUser={authUser} {...props} />}
            />
            <PrevAuthedRoute
                exact
                authUser={authUser}
                path={routes.RESET_PASSWORD}
                component={props => <ResetPassword authUser={authUser} {...props} />}
            />
            <PrevAuthedRoute
                exact
                authUser={authUser}
                path={routes.AUTH_SERVICES}
                component={props => (
                    <AuthenticationServices authUser={authUser} {...props} />
                )}
            />
            <>
                <SideNavigation />
                <PrivateRoute
                    authUser={authUser}
                    path={routes.AUTHENTICATED}
                    component={props => (
                        <AuthenticatedRouter authUser={authUser} {...props} />
                    )}
                />
            </>
        </Router>
    );
};

export default AppRouter;
