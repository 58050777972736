import React, { useState } from 'react';

// Packages
import { connect } from 'react-redux';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';

// Context
import { useLang } from '../../../context/LangContext';

// Components
import TextField from '../../../components/common/TextField';
import PasswordModal from './PasswordModal';
import ActionButtons from '../shared/ActionButtons';
import PhonesField from '../shared/PhonesField';
import { UncontrolledTooltip } from 'reactstrap';

// Utils
import { nameRegExp, phoneRegExp } from '../../../utils/Constants';

const PersonalInfo = ({ userData }) => {
    const {
        main_title,
        lic_number,
        mls_id,
        tooltip_mls_id,
        name_label,
        email_label,
        phone_label,
        about_label,
        placeholder_licence,
        placeholder_mls_id,
        placeholder_first_name,
        placeholder_last_name,
        placeholder_email,
        placeholder_editor,
        modal_title,
        modal_password_placeholder,
        modal_button_cancel,
        modal_button_save
    } = useLang()['Settings']['PersonalInfo'];

    const { save_label, cancel_label } = useLang()['Common']['ActionButtons'];
    const { errorMessage } = useLang()['Constants'];

    const [password, setPassword] = useState('');
    const [isValidPassword, setIsValidPassword] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    const modalToggle = () => setModalOpen(prevState => !prevState);

    const checkPassword = () => setIsValidPassword(true);
    const handleSetPassword = event => setPassword(event.target.value);
    const submitPassword = event => {
        event.preventDefault();
        // TODO: check valid password with API request
        console.log('password:', password);
        setPassword('');
        checkPassword();
        modalToggle();
    };

    const onEditorStateChange = editorState => setEditorState(editorState);

    const initialValues = {
        firstName: userData?.first_name || '',
        lastName: userData?.last_name || '',
        email: userData?.email || '',
        about: '',
        mlsId: userData?.mls_id || '',
        licNumber: userData?.agent_lic || '',
        phones: userData?.phone || []
    };
    const validationSchema = Yup.object().shape({
        licNumber: Yup.string().required(errorMessage.licNumber.required),
        mlsId: Yup.string().required(errorMessage.mlsId.required),
        firstName: Yup.string()
            .matches(nameRegExp.format, errorMessage.firstName.valid)
            .required(errorMessage.firstName.required),
        lastName: Yup.string()
            .matches(nameRegExp.format, errorMessage.lastName.valid)
            .required(errorMessage.lastName.required),
        email: Yup.string()
            .lowercase()
            .email(errorMessage.email.valid)
            .required(errorMessage.email.required),
        phones: Yup.array().of(
            Yup.object().shape({
                number: Yup.string()
                    .matches(phoneRegExp.format, errorMessage.phone.valid)
                    .required(errorMessage.phone.required)
            })
        ),
        about: Yup.string()
    });

    return (
        <div>
            <div className="settingsTabHeader">
                <p className="settingsTabHeader__content font-weight-bold mb-0">
                    {main_title}
                </p>
            </div>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnMount
                // TODO: rework submit with API request
                onSubmit={values => console.log('values', values)}
                enableReinitialize
            >
                {({
                    errors,
                    values,
                    dirty,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched
                }) => (
                    <Form className="personalInfo">
                        <div className="row mb-3 input-group">
                            <label className="col-4 fs-5">{lic_number}</label>
                            <TextField
                                className="w-100 borderColor rounded form-control position-relative"
                                myStyle={{ width: '520px' }}
                                required
                                name="licNumber"
                                type="text"
                                placeholder={placeholder_licence}
                                value={values.licNumber}
                                error={errors.licNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={!!errors.licNumber && dirty}
                                hasDefaultFill
                            />
                        </div>
                        <div className="row mb-3 input-group">
                            <label className="col-4 fs-5 d-flex align-items-center">
                                {mls_id}
                                <i
                                    style={{
                                        color: 'rgb(140, 152, 164)',
                                        marginLeft: '10px'
                                    }}
                                    className="far fa-info-circle"
                                    id="infoIcon"
                                />
                                <UncontrolledTooltip placement="right" target="infoIcon">
                                    {tooltip_mls_id}
                                </UncontrolledTooltip>
                            </label>
                            <TextField
                                className="w-100 borderColor rounded form-control position-relative"
                                myStyle={{ width: '520px' }}
                                required
                                name="mlsId"
                                type="text"
                                placeholder={placeholder_mls_id}
                                value={values.mlsId}
                                error={errors.mlsId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={!!errors.mlsId && dirty}
                                hasDefaultFill
                            />
                        </div>
                        <div className="row mb-3 input-group">
                            <label className="col-4 fs-5">{name_label}</label>
                            <TextField
                                className="col-8 borderColor rounded-left form-control"
                                myStyle={{ maxWidth: '260px', borderRadius: 'inherit' }}
                                required
                                name="firstName"
                                type="text"
                                placeholder={placeholder_first_name}
                                value={values.firstName}
                                error={errors.firstName}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                invalid={!!errors.firstName && dirty}
                                hasDefaultFill
                            />
                            <TextField
                                className="col-7 borderColor rounded-right form-control"
                                myStyle={{ maxWidth: '260px', borderRadius: 'inherit' }}
                                required
                                name="lastName"
                                type="text"
                                placeholder={placeholder_last_name}
                                value={values.lastName}
                                error={errors.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={!!errors.lastName && dirty}
                                hasDefaultFill
                            />
                        </div>
                        <div className="row mb-3 input-group">
                            <label className="col-4">{email_label}</label>
                            <TextField
                                className="w-100 borderColor rounded form-control position-relative"
                                myStyle={{
                                    width: '520px',
                                    color: isValidPassword ? '#505050' : '#77838f'
                                }}
                                required
                                name="email"
                                type="email"
                                placeholder={placeholder_email}
                                value={values.email}
                                error={errors.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={!!errors.email && dirty}
                                disabled={!isValidPassword}
                                hasDefaultFill
                            />
                            <i
                                className="fa-solid fa-pen-to-square"
                                style={{
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    top: '7px',
                                    right: '17px',
                                    color: 'rgb(140, 152, 164)'
                                }}
                                onClick={modalToggle}
                            />
                        </div>

                        {/* Modal */}
                        <PasswordModal
                            handleOpen={modalOpen}
                            handleToggle={modalToggle}
                            handleSubmit={submitPassword}
                            title={modal_title}
                            password={password}
                            handleChangePassword={handleSetPassword}
                            cancelTitle={modal_button_cancel}
                            saveTitle={modal_button_save}
                            placeholder={modal_password_placeholder}
                        />

                        <div className="row mb-3 input-group">
                            <label className="col-4 fs-5">{phone_label}</label>
                            <div style={{ padding: '0' }} className="col-8">
                                <FieldArray name="phones" component={PhonesField} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="col-4">{about_label}</label>
                            <Editor
                                editorState={editorState}
                                onEditorStateChange={onEditorStateChange}
                                placeholder={placeholder_editor}
                                toolbarClassName="m-0"
                                wrapperClassName="col-8 draftEditor"
                                editorClassName="borderColor editorSize"
                                onChange={() =>
                                    setFieldValue(
                                        'about',
                                        draftToHtml(
                                            convertToRaw(editorState.getCurrentContent())
                                        )
                                    )
                                }
                                onBlur={() => setFieldTouched('about', true)}
                                toolbar={{
                                    options: ['inline', 'list', 'link'],
                                    inline: {
                                        options: ['bold', 'italic', 'underline']
                                    },
                                    list: {
                                        options: ['unordered']
                                    },
                                    link: {
                                        options: ['link']
                                    }
                                }}
                            />
                        </div>
                        <ActionButtons
                            handleSubmit={handleSubmit}
                            cancelLabel={cancel_label}
                            saveLabel={save_label}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

const mapStateToProps = ({ user }) => {
    const { userData } = user;
    return { userData };
};

export default connect(mapStateToProps)(PersonalInfo);
