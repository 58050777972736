// React
import React from 'react';
import { useHistory } from 'react-router-dom';

// Context
import { useLang } from '../../context/LangContext';

const NewDocumentHeader = () => {
    const history = useHistory();
    const { back_button_label } = useLang()['NewTransaction']['NewTransactionHeader'];

    return (
        <header id="header" className="u-header u-header--sticky-top">
            <div className="bg-primary">
                <div className="container">
                    <div
                        id="lg-nav"
                        className="d-flex justify-content-between align-items-center"
                    >
                        <div className="u-header u-header-left-aligned-nav u-header--bg-transparent-lg u-header--white-nav-links z-index-4">
                            <div className="u-header__section bg-transparent">
                                <nav className="navbar navbar-expand-lg u-header__navbar u-header__navbar--no-space">
                                    <ul className="navbar-nav u-header__navbar-nav">
                                        <li className="nav-item u-header__nav-item">
                                            <button
                                                className="btn transition-3d-hover"
                                                style={{
                                                    color: 'white',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => history.goBack()}
                                            >
                                                <span className="fas fa-chevron-left mr-1" />
                                                {back_button_label}
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="btn btn-lg btn-icon btn-primary rounded-circle transition-3d-hover sweet-1 ml-auto"
                            onClick={() => history.goBack()}
                        >
                            <span className="fas fa-times" />
                        </button>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default NewDocumentHeader;
