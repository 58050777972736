import { MODAL_CANCEL, MODAL_CONFIRM, SET_MODAL_TYPE } from './types';

export const setConfirmModalType = type => {
    return {
        type: SET_MODAL_TYPE,
        payload: type
    };
};

export const confirmModal = payload => {
    return {
        type: MODAL_CONFIRM,
        payload
    };
};

export const cancelModal = () => {
    return {
        type: MODAL_CANCEL
    };
};
