// React
import React from 'react';

// Packages
import Flatpickr from 'react-flatpickr';

const DateTimePicker = ({
    required,
    type,
    className,
    name,
    id,
    placeholder,
    error,
    value,
    onBlur,
    onChange,
    myStyle,
    options,
    touched
}) => {
    return (
        <div style={myStyle}>
            <Flatpickr
                autoComplete="off"
                required={required}
                type={type}
                className={className}
                style={{
                    borderColor:
                        error && touched
                            ? '#de4437'
                            : value
                            ? 'rgba(0, 201, 167, 0.5)'
                            : ''
                }}
                name={name}
                id={id}
                placeholder={placeholder}
                error={error}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                options={options}
            />
        </div>
    );
};

export default DateTimePicker;
