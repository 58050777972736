// React
import React, { useState, useCallback, useEffect } from 'react';

// Components
import AddFile from '../assets/svg/AddFile.svg';
import { AppHeader, AvatarEditModal } from '../components';

// Hooks
import { useWindowSize } from '../hooks/useWindowSize';

// Packages
import SimpleBar from 'simplebar-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Avatar from 'react-avatar';
import { useDropzone } from 'react-dropzone';

// Redux - Actions, Reducers, Sagas
import { uploadUserAvatar } from '../store/actions/Profile';

const Profile = props => {
    const [scrollHeight, setScrollHeight] = useState(0);
    const { windowHeight, windowWidth } = useWindowSize();
    const [source, setSource] = useState(null);
    const [avatarModal, setAvatarModal] = useState(false);
    const [activeUpload, setActiveUpload] = useState(false);
    const [pristine, setPristine] = useState(false);
    const maxSize = 2097152;
    const { userData, uploadUserAvatar, loading, uploadError } = props;

    const measuredRef = useCallback(
        node => {
            if (node?.getBoundingClientRect()?.top && windowHeight) {
                setScrollHeight(windowHeight - node.getBoundingClientRect().top);
            }
        },
        [windowHeight]
    );

    const toggleModal = useCallback(({ reset, cancel }) => {
        if (reset || cancel) {
            setActiveUpload(false);
            setAvatarModal(upload => !upload);
        } else {
            setAvatarModal(upload => !upload);
        }
    }, []);

    useEffect(() => {
        if (activeUpload) {
            if (!loading && !uploadError && !pristine) {
                toggleModal({ reset: true, cancel: false });
            } else if (loading) {
                // Do nothing waiting for work to finish
            } else if (uploadError) {
                console.log('notify user of error');
            }
        } else {
            setPristine(true);
        }
    }, [loading, uploadError, activeUpload, pristine, toggleModal]);

    const onDrop = useCallback(
        acceptedFiles => {
            if (acceptedFiles.length) {
                const url = URL.createObjectURL(acceptedFiles[0]);
                setSource(url);
                setActiveUpload(true);
                toggleModal({ reset: false, cancel: false });
            }
        },
        [toggleModal, setActiveUpload]
    );

    const uploadAvatar = (canvas, crop) => {
        if (!crop || !canvas) {
            return;
        }
        canvas.toBlob(
            blob => {
                const url = URL.createObjectURL(blob);
                uploadUserAvatar({ img: url });
                setPristine(false);
            },
            'image/jpeg',
            0.25
        );
    };

    const { isDragActive, getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop,
        accept: 'image/png, image/jpeg',
        minSize: 0,
        maxSize
    });

    const isFileTooLarge =
        fileRejections?.length > 0 && fileRejections[0].file.size > maxSize;

    return (
        <div style={{ maxHeight: windowHeight, overflow: 'hidden' }}>
            <AvatarEditModal
                source={source}
                setSource={setSource}
                avatarModal={avatarModal}
                toggleModal={toggleModal}
                uploadAvatar={uploadAvatar}
                loading={loading}
            />
            <AppHeader {...props} />
            <main id="content" role="main" className="push-main-lg bg-light">
                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <div ref={measuredRef}>
                        <SimpleBar style={{ height: scrollHeight, width: windowWidth }}>
                            <div
                                className="container space-bottom-1"
                                style={{ minHeight: '100vh' }}
                            >
                                <div
                                    className="col-3 col-sm-4 justify-content-start mb-7 mt-5 mt-sm-7"
                                    style={{
                                        height: '60vh',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}
                                >
                                    <span
                                        className="d-block h2"
                                        style={{
                                            width: '100%',
                                            textAlign: 'center'
                                        }}
                                    >
                                        Avatar
                                    </span>
                                    {userData && (
                                        <div className="u-xl-avatar mb-3 mt-3 mb-sm-1 mt-sm-1">
                                            <>
                                                {userData.user_avatar ? (
                                                    <img
                                                        src={userData.user_avatar}
                                                        className="img-fluid rounded-circle"
                                                        alt={`${userData?.first_name} ${userData?.last_name}`}
                                                    />
                                                ) : (
                                                    <Avatar
                                                        className="btn btn-rel btn-icon btn-soft-primary rounded-circle"
                                                        name={`${userData.first_name} ${userData.last_name}`}
                                                        unstyled
                                                    />
                                                )}
                                            </>
                                        </div>
                                    )}

                                    <div {...getRootProps()}>
                                        <div
                                            className="u-dropzone mt-5 mb-3"
                                            style={{
                                                justifyContent: 'center',
                                                borderStyle: isDragActive
                                                    ? 'solid'
                                                    : 'dashed',
                                                borderColor: isDragActive
                                                    ? '#377dff'
                                                    : '#e7eaf3'
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            <div className="dz-message">
                                                <img
                                                    className="js-svg-injector max-width-10 mb-3"
                                                    src={AddFile}
                                                    alt="Add File"
                                                    data-parent="#uploadForm"
                                                />
                                                <span className="d-block mb-2">
                                                    Browse your device and upload a
                                                    profile image
                                                </span>
                                                <small className="d-block text-muted">
                                                    Maximum file size is 2MB
                                                </small>

                                                {isFileTooLarge && (
                                                    <div className="text-danger mt-2">
                                                        File is too large.
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SimpleBar>
                    </div>
                </div>
            </main>
        </div>
    );
};

const mapStateToProps = ({ user, profile }) => {
    const { userData } = user;
    const { loading, uploadError } = profile;
    return { userData, loading, uploadError };
};

export default withRouter(connect(mapStateToProps, { uploadUserAvatar })(Profile));
