import React from 'react';

const Preferences = () => {
    return (
        <>
            <div className="card-header">
                <h1 className="h5 font-weight-medium mb-0">Preferences</h1>
            </div>
            <div className="card-body">
                <p>“Technology is a useful servant but a dangerous master.”</p>
            </div>
        </>
    );
};

export default Preferences;
