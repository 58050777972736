// React
import React from 'react';

// Packages
import Select from 'react-select';

const DropdownIndicator = () => {
    return <span className="select-icon" />;
};

const IndicatorSeparator = () => {
    return null;
};

const SelectField = ({
    required,
    type,
    className,
    name,
    id,
    placeholder,
    onChange,
    options,
    classNamePrefix
}) => {
    return (
        <Select
            autoComplete="off"
            required={required}
            type={type}
            className={className}
            classNamePrefix={classNamePrefix}
            name={name}
            id={id}
            placeholder={placeholder}
            onChange={onChange}
            options={options}
            components={{
                DropdownIndicator,
                IndicatorSeparator
            }}
        />
    );
};

export default SelectField;
