import {
    GET_TRANSACTION_DETAILS,
    SET_TRANSACTION_ACTIVITY,
    CANCEL_DETAILS_LISTENER
} from '../actions/types';

const INITIAL_STATE = {
    detailsFlag: false,
    loading: false,
    transactionActivity: null
};

const transactionActivityReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_TRANSACTION_DETAILS:
            return {
                ...state,
                loading: true,
                detailsFlag: false,
                transactionActivity: INITIAL_STATE.transactionActivity
            };
        case SET_TRANSACTION_ACTIVITY:
            return {
                ...state,
                transactionActivity: action.payload.transactionActivity,
                loading: false,
                detailsFlag: action.payload.detailsFlag
            };

        case CANCEL_DETAILS_LISTENER:
            return {
                ...state,
                detailsFlag: false,
                transactionActivity: INITIAL_STATE.transactionActivity
            };
        default:
            return state;
    }
};

export default transactionActivityReducer;
