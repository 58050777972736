import {
    UPDATE_USER_AVATAR,
    UPDATE_USER_AVATAR_SUCCESS,
    UPDATE_USER_AVATAR_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    loading: null,
    uploadError: null
};

const profileReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_USER_AVATAR:
            return {
                ...state,
                loading: true,
                uploadError: null
            };
        case UPDATE_USER_AVATAR_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case UPDATE_USER_AVATAR_FAILURE:
            return {
                ...state,
                loading: false,
                uploadError: true
            };
        default:
            return state;
    }
};

export default profileReducer;
