// React
import React, { useState } from 'react';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

const AddSearch = ({
    onClick,
    dropState,
    setDropState,
    setSearchText,
    searchText,
    documentFilter,
    settingTrxDocumentsFilter,
    settingSearchSuggestions,
    allDocs,
    tabContent,
    lang
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const {
        all_label,
        buyer_label,
        seller_label,
        other_label,
        add_label,
        search_placeholder
    } = useLang()['TransactionDetails']['AddSearch'];

    const handleFocus = () => {
        if (!dropState) setDropState(!dropState);
    };

    const toggle = () => {
        setDropdownOpen(!dropdownOpen);
        if (!dropdownOpen) if (!dropState) setDropState(!dropState);
    };

    const types = [
        { name: all_label, value: 'all' },
        { name: buyer_label, value: 'buyer' },
        { name: seller_label, value: 'seller' },
        { name: other_label, value: 'other' }
    ];

    const filterDocuments = filter => {
        setSearchText('');
        settingTrxDocumentsFilter(filter);
        settingSearchSuggestions(null);
    };

    const countByFilter = filter => {
        if (filter.value !== 'all') {
            const filtered = allDocs?.filter(doc => doc.upload_type === filter.value);
            return filtered?.length ? filtered.length : 0;
        } else {
            return allDocs?.length;
        }
    };

    return (
        <div className="py-3 px-4 bg-white border">
            <div
                className={`d-flex ${
                    tabContent === 'Documents'
                        ? 'justify-content-between'
                        : 'justify-content-end'
                } align-items-center`}
            >
                {tabContent === 'Documents' && (
                    <>
                        <div className={`${lang === 'en' ? 'w-65 mr-3' : 'w-55 mr-1'} `}>
                            <input
                                className="form-control form-control-xs"
                                type="text"
                                placeholder={search_placeholder}
                                aria-label={search_placeholder}
                                value={searchText}
                                onFocus={handleFocus}
                                onChange={e => setSearchText(e.target.value)}
                            />
                        </div>

                        <ButtonDropdown
                            style={{
                                borderRadius: '0.3125rem'
                            }}
                            isOpen={dropdownOpen}
                            toggle={toggle}
                        >
                            <DropdownToggle caret color="soft-primary" size="xs">
                                {`${documentFilter.name} (${countByFilter(
                                    documentFilter
                                )})`}
                            </DropdownToggle>
                            <DropdownMenu>
                                {types.map(filter => (
                                    <DropdownItem
                                        key={filter.value}
                                        active={documentFilter.value === filter.value}
                                        onClick={() => filterDocuments(filter)}
                                    >
                                        {`${filter.name} (${countByFilter(filter)})`}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </ButtonDropdown>
                    </>
                )}
                <div>
                    <div
                        className="btn btn-xs btn-primary transition-3d-hover"
                        onClick={() => onClick && onClick(!dropState)}
                    >
                        <span className="fas fa-plus mr-1" />
                        {add_label}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddSearch;
