import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

const PhoneDropdown = ({ options, form: { values }, onChange, index }) => {
    const [dropdownTitle, setDropdownTitle] = useState(values.phones[index].type);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle className="settings-dropdown-toggle" caret>
                {dropdownTitle}
            </DropdownToggle>
            <DropdownMenu right>
                {Object.values(options).map(type => (
                    <DropdownItem
                        style={{
                            textTransform: 'capitalize'
                        }}
                        key={type}
                        onClick={() => {
                            onChange(type);
                            setDropdownTitle(type);
                        }}
                    >
                        {type}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
};

export default PhoneDropdown;
