// React
import React, { useState, useCallback, useEffect } from 'react';

// Assets
import JadaLogo from '../../assets/img/jada-j.png';
import PDF from '../../assets/svg/PDF.svg';

// Context
import { useLang } from '../../context/LangContext';

// Hooks
import { useWindowSize } from '../../hooks/useWindowSize';

// Packages
import Avatar from 'react-avatar';
import SimpleBar from 'simplebar-react';

// Utils
import { formatThreadTime, formatActivity } from '../../utils/Helpers';

const ActivityThread = ({ activity, avatars, lang, isTransactionArchived }) => {
    const { windowHeight } = useWindowSize();
    const [scrollHeight, setScrollHeight] = useState(0);
    const [avatarImgs, setAvatarImgs] = useState(null);
    const activityEvents = activity ? formatActivity(activity, lang) : null;
    const { no_messages_label } = useLang()['TransactionDetails']['ActivityThread'];

    const measuredRef = useCallback(
        node => {
            if (node?.getBoundingClientRect().top && windowHeight) {
                setScrollHeight(windowHeight - node.getBoundingClientRect().top);
            }
        },
        // eslint-disable-next-line
        [windowHeight, isTransactionArchived]
    );

    useEffect(() => {
        if (activity && avatars) {
            setAvatarImgs(avatars);
        }
    }, [avatars, activity]);

    if (!activityEvents) return <span className="text-muted">{no_messages_label}</span>;

    return (
        <div ref={measuredRef} style={{ height: '100%' }}>
            <SimpleBar style={{ height: scrollHeight }}>
                {activityEvents.length &&
                    activityEvents.map(activity => (
                        <div key={activity.day}>
                            <div className="message-date">
                                <small>{activity.day}</small>
                            </div>
                            <>
                                {activity.events.length &&
                                    activity.events.map(event => (
                                        <div
                                            className="message"
                                            key={`${event.created_at.seconds}_${
                                                event.message
                                            }_${event.automated?.name || 'null'}`}
                                        >
                                            <div className="message-avatar">
                                                <div className="u-avatar mr-3">
                                                    {avatarImgs ? (
                                                        <>
                                                            {avatarImgs.findIndex(
                                                                user =>
                                                                    user.id ===
                                                                    event.creator_id
                                                            ) >= 0 ||
                                                            event.creator_type ===
                                                                'system' ? (
                                                                <img
                                                                    className="img-fluid rounded-circle"
                                                                    src={
                                                                        event.creator_type !==
                                                                        'system'
                                                                            ? avatarImgs[
                                                                                  avatarImgs.findIndex(
                                                                                      user =>
                                                                                          user.id ===
                                                                                          event.creator_id
                                                                                  )
                                                                              ].url
                                                                            : JadaLogo
                                                                    }
                                                                    alt={`${
                                                                        event.first_name
                                                                    } ${
                                                                        event.last_name ||
                                                                        ''
                                                                    }`}
                                                                />
                                                            ) : (
                                                                <Avatar
                                                                    className="btn btn-icon btn-soft-primary rounded-circle mr-3"
                                                                    name={`${
                                                                        event.first_name
                                                                    } ${
                                                                        event.last_name ||
                                                                        ''
                                                                    }`}
                                                                    unstyled
                                                                />
                                                            )}
                                                        </>
                                                    ) : (
                                                        <Avatar
                                                            className="btn btn-icon btn-soft-primary rounded-circle mr-3"
                                                            name={`${event.first_name} ${
                                                                event.last_name || ''
                                                            }`}
                                                            unstyled
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <div className="message-body">
                                                <span className="d-block font-weight-medium text-dark">
                                                    {`${event.first_name} ${
                                                        event.last_name || ''
                                                    }`}
                                                    <small className="text-muted">
                                                        {formatThreadTime(
                                                            event.created_at.seconds
                                                        )}
                                                    </small>
                                                </span>
                                                {event.automated ? (
                                                    <span>
                                                        {event.message}
                                                        <span
                                                            style={{ color: '#377dff' }}
                                                        >
                                                            {event.automated.name}
                                                        </span>
                                                    </span>
                                                ) : (
                                                    <p style={{ whiteSpace: 'pre-line' }}>
                                                        {event.message}
                                                    </p>
                                                )}
                                                {event.attachments &&
                                                    event.attachments.map(file => (
                                                        <div
                                                            style={{ width: '300px' }}
                                                            key={file.id}
                                                            className="message-attachment message-attachment-file"
                                                        >
                                                            <img
                                                                className="img-fluid"
                                                                src={
                                                                    file.type ===
                                                                    'document'
                                                                        ? PDF
                                                                        : file.path
                                                                }
                                                                alt="File Description"
                                                            />
                                                            <span>{file.title}</span>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    ))}
                            </>
                        </div>
                    ))}
            </SimpleBar>
        </div>
    );
};

export default ActivityThread;
