import {
    REMOVING_PREFERENCE_TASK,
    ADDING_PREFERENCE_TASK,
    REMOVING_PREFERENCE_TASK_SUCCESS,
    REMOVING_PREFERENCE_TASK_FAILURE,
    ADDING_PREFERENCE_TASK_SUCCESS,
    ADDING_PREFERENCE_TASK_FAILURE
} from './types';

export const removePreferenceTask = task => {
    return {
        type: REMOVING_PREFERENCE_TASK,
        payload: task
    };
};

export const addPreferenceTask = task => {
    return {
        type: ADDING_PREFERENCE_TASK,
        payload: task
    };
};

export const taskRemovalSuccess = () => {
    return {
        type: REMOVING_PREFERENCE_TASK_SUCCESS
    };
};

export const taskAdditionSuccess = () => {
    return {
        type: ADDING_PREFERENCE_TASK_SUCCESS
    };
};

export const taskRemovalFailure = () => {
    return {
        type: REMOVING_PREFERENCE_TASK_FAILURE
    };
};

export const taskAdditionFailure = () => {
    return {
        type: ADDING_PREFERENCE_TASK_FAILURE
    };
};
