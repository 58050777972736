// React
import React, { useState } from 'react';

// Components
import {
    NewTransactionHeader,
    TransactionTypeSelect,
    BuyerTransactionFlow,
    ListingTransactionFlow
} from '../components';
import ConfirmationDialog from '../components/common/ConfirmationDialog';

// Router
import { Switch, Route, useHistory, withRouter } from 'react-router-dom';
import * as routes from '../router/config/routes';

// Context
import { useLang } from '../context/LangContext';

const NewTransaction = props => {
    const history = useHistory();
    const { title_question, content_cancel, stay_button_cancel, confirm_button_cancel } =
        useLang()['Common']['ConfirmationDialog'];
    const [shouldConfirmDisplayed, setShouldConfirmDisplayed] = useState(false);

    return (
        <>
            <ConfirmationDialog
                confirmHandler={() =>
                    history.push(`${routes.AUTHENTICATED}${routes.DASHBOARD}`)
                }
                titleText={title_question}
                contentText={content_cancel}
                confirmButtonText={confirm_button_cancel}
                cancelButtonText={stay_button_cancel}
            />
            <NewTransactionHeader shouldConfirmDisplayed={shouldConfirmDisplayed} />

            <Switch>
                <Route
                    exact
                    path={`${props.match.url}`}
                    render={props => (
                        <TransactionTypeSelect
                            setShouldConfirmDisplayed={setShouldConfirmDisplayed}
                            {...props}
                        />
                    )}
                />
                <Route
                    path={`${props.match.url}${routes.BUYER}`}
                    render={props => <BuyerTransactionFlow {...props} />}
                />
                <Route
                    path={`${props.match.url}${routes.LISTING}`}
                    render={props => <ListingTransactionFlow {...props} />}
                />
            </Switch>
        </>
    );
};

export default withRouter(NewTransaction);
