// React
import React, { useState } from 'react';

// Components
import { Loader, AddContactForm } from '../../components';

// Constants
import {
    phoneRegExp,
    nameRegExp,
    streetAddressRegExp,
    unitedStates
} from '../../utils/Constants';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';
import { FieldArray, Form, Formik } from 'formik';
import SimpleBar from 'simplebar-react';

const AddContactsModal = ({
    toggleControl,
    loading,
    contactModal,
    executeContactsAddition
}) => {
    const [idCount, setIdCount] = useState(1);
    const { new_contacts_label, create_button_label, cancel_button_label } =
        useLang()['Common']['AddContactsModal'];
    const { errorMessage } = useLang()['Constants'];

    const onCancel = () => {
        toggleControl({ reset: false, cancel: true });
    };

    const getUsState = unitedState => {
        const state = unitedStates.find(state => state.value === unitedState);
        return state.name;
    };

    const initialValues = {
        parties: [
            {
                id: 0,
                firstName: '',
                middleInitial: '',
                lastName: '',
                nickName: '',
                email: '',
                address: '',
                address2: '',
                phone: '',
                phoneExt: '',
                city: '',
                state: '',
                zip: '',
                notes: '',
                lat: '',
                lon: '',
                sameAddress: false
            }
        ]
    };

    const addedParty = {
        id: idCount,
        firstName: '',
        middleInitial: '',
        lastName: '',
        nickName: '',
        email: '',
        address: '',
        address2: '',
        phone: '',
        phoneExt: '',
        city: '',
        state: '',
        zip: '',
        notes: '',
        lat: '',
        lon: '',
        sameAddress: true
    };

    const validationSchema = Yup.object({
        parties: Yup.array().of(
            Yup.object().shape(
                {
                    firstName: Yup.string()
                        .matches(nameRegExp.format, errorMessage.firstName.valid)
                        .when('lastName', {
                            is: lastName => !lastName || lastName.length === 0,
                            then: Yup.string().required(
                                errorMessage.firstOrLastName.required
                            ),
                            otherwise: Yup.string()
                        }),
                    middleInitial: Yup.string()
                        .min(1, errorMessage.middleInitial.valid)
                        .max(1, errorMessage.middleInitial.valid),
                    lastName: Yup.string()
                        .matches(nameRegExp.format, errorMessage.lastName.valid)
                        .when('firstName', {
                            is: firstName => !firstName || firstName.length === 0,
                            then: Yup.string().required(
                                errorMessage.firstOrLastName.required
                            )
                        }),
                    otherwise: Yup.string(),
                    nickName: Yup.string(),
                    email: Yup.string()
                        .lowercase()
                        .email(errorMessage.email.valid)
                        .when('phone', {
                            is: phone => !phone || phone.length === 0,
                            then: Yup.string().required(
                                errorMessage.emailOrPhone.required
                            ),
                            otherwise: Yup.string()
                        }),
                    phone: Yup.string()
                        .matches(phoneRegExp.format, errorMessage.phone.valid)
                        .when('email', {
                            is: email => !email || email.length === 0,
                            then: Yup.string().required(
                                errorMessage.emailOrPhone.required
                            ),
                            otherwise: Yup.string()
                        }),

                    phoneExt: Yup.string(),
                    address: Yup.string().matches(
                        streetAddressRegExp,
                        errorMessage.address.valid
                    ),
                    address2: Yup.string(),
                    city: Yup.string().matches(
                        nameRegExp.format,
                        errorMessage.city.valid
                    ),
                    state: Yup.string(),
                    zip: Yup.string(),
                    notes: Yup.string(),
                    sameAddress: Yup.bool(),
                    lat: Yup.number(),
                    lon: Yup.number()
                },
                [
                    ['firstName', 'lastName'],
                    ['phone', 'email']
                ]
            )
        )
    });

    const checkError = (errors, index, type) => {
        if (index !== null) {
            if (
                errors &&
                errors.parties &&
                errors.parties[index] &&
                errors.parties[index][type]
            ) {
                return errors.parties[index][type];
            }
        } else {
            if (type === 'businessSrc') {
                if (errors && errors.trxMeta && errors.trxMeta[type]?.value) {
                    return errors.trxMeta[type]?.value;
                }
            } else {
                if (errors && errors.trxMeta && errors.trxMeta[type]) {
                    return errors.trxMeta[type];
                }
            }
            return null;
        }
    };

    const checkTouched = (touched, index, type) => {
        if (index !== null) {
            if (
                touched &&
                touched.parties &&
                touched.parties[index] &&
                touched.parties[index][type]
            ) {
                return true;
            } else {
                return null;
            }
        } else {
            if (type === 'businessSrc') {
                if (touched && touched.trxMeta && touched.trxMeta[type]) {
                    return true;
                } else {
                    return null;
                }
            } else {
                if (touched && touched.trxMeta && touched.trxMeta[type]) {
                    return true;
                } else {
                    return null;
                }
            }
        }
    };

    return (
        <div>
            <Modal
                isOpen={contactModal}
                toggle={toggleControl}
                contentClassName="contact-upload-modal"
                className="contact-upload-modal"
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize
                    onSubmit={(values, { setSubmitting }) => {
                        executeContactsAddition({ contacts: values.parties });
                        setSubmitting(false);
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldTouched,
                        setFieldValue,
                        touched,
                        values,
                        isSubmitting
                    }) => (
                        <>
                            <ModalHeader toggle={onCancel}>
                                {new_contacts_label}
                            </ModalHeader>

                            <ModalBody>
                                <SimpleBar
                                    style={{
                                        height: '75vh',
                                        width: '100%'
                                    }}
                                >
                                    <Form>
                                        <div
                                            style={{
                                                animation: 'fadeIn .5s'
                                            }}
                                        >
                                            <FieldArray
                                                name="parties"
                                                render={arrayHelpers => {
                                                    const { parties } = values;

                                                    return (
                                                        <AddContactForm
                                                            parties={parties}
                                                            checkError={checkError}
                                                            errors={errors}
                                                            values={values}
                                                            handleBlur={handleBlur}
                                                            handleChange={handleChange}
                                                            handleSubmit={handleSubmit}
                                                            setFieldTouched={
                                                                setFieldTouched
                                                            }
                                                            setFieldValue={setFieldValue}
                                                            checkTouched={checkTouched}
                                                            touched={touched}
                                                            isSubmitting={isSubmitting}
                                                            arrayHelpers={arrayHelpers}
                                                            addedParty={addedParty}
                                                            setIdCount={setIdCount}
                                                            idCount={idCount}
                                                            getUsState={getUsState}
                                                        />
                                                    );
                                                }}
                                            />
                                        </div>
                                    </Form>
                                </SimpleBar>
                            </ModalBody>
                            <ModalFooter>
                                {loading && <Loader />}
                                <button
                                    className="btn btn-link text-danger"
                                    onClick={onCancel}
                                >
                                    {cancel_button_label}
                                </button>
                                <Button
                                    color="primary"
                                    type="submit"
                                    data-next-step="#selectStepTwo"
                                    disabled={isSubmitting}
                                    onClick={handleSubmit}
                                >
                                    {create_button_label}
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </Formik>
            </Modal>
        </div>
    );
};

export default AddContactsModal;
