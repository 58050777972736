import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import configureStore from '../store';
import { LangProvider } from './LangContext';

const store = configureStore();

const AppProviders = ({ children }) => {
    return (
        <ReduxProvider store={store}>
            <LangProvider>{children}</LangProvider>
        </ReduxProvider>
    );
};

export default AppProviders;
