import { take, race, put } from 'redux-saga/effects';
import { setConfirmModalType } from '../actions/Modal';

import { MODAL_CONFIRM, MODAL_CANCEL } from '../actions/types';

export function* confirmSaga({ modalType }) {
    try {
        yield put(setConfirmModalType(modalType));
        const result = yield race({
            yes: take(MODAL_CONFIRM),
            no: take(MODAL_CANCEL)
        });

        return { isConfirm: Boolean(result.yes), dataFromModal: result.yes?.payload };
    } catch (e) {
        console.error(e);
    }
}
